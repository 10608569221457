import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import {
    IMPLEMENTATION_DOCUMENTS_QUERY, ImplementationDocumentsResponseT,
} from 'shared/graphql/query/implementationDocument/ImplementationDocumentQuery';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_IMPLEMENTATION_DOCUMENTS_RESULT } from 'types/ImplementationDocument';

import { ImplementationDocumentsDTO } from '../DTO/ImplementationDocumentsDTO';

import { ImplementationDocumentsDataT } from './ImplementationDocumentsData';

const useImplementationDocuments = (): ImplementationDocumentsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<ImplementationDocumentsResponseT, QueryVarsT>(
        IMPLEMENTATION_DOCUMENTS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedImplementationDocumentsData = decodeDTO(
        ImplementationDocumentsDTO,
        EMPTY_IMPLEMENTATION_DOCUMENTS_RESULT,
        (implementationDocuments) => implementationDocuments,
        {
            implementationDocuments: data?.implementationDocuments,
            implementationDocumentsCount: data?.implementationDocumentsCount,
        },
    );

    const fetchImplementationDocuments = async ({
        limit, offset, orderBy = {}, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: decodedImplementationDocumentsData.implementationDocuments,
        count: decodedImplementationDocumentsData.implementationDocumentsCount,
        fetchImplementationDocuments,
        loading,
    };
};

export default useImplementationDocuments;
