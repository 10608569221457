import {
    ERROR_TASK_STATUS_CREATE,
    SUCCESS_TASK_STATUS_CREATE,
} from 'constants/notificationMessage';

import { TaskStatusFormFields } from 'page/TaskStatus/components/formData';
import useTaskStatusCreate from 'shared/data-hook/taskStatus/create/useTaskStatusCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateTaskStatus = () => {
    const { error, loading, createTaskStatus: actionCreate } = useTaskStatusCreate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createTaskStatus = async (input: TaskStatusFormFields): Promise<boolean> => {
        await actionCreate(input);

        if (error) {
            showError(ERROR_TASK_STATUS_CREATE);

            return false;
        }

        showSuccess(SUCCESS_TASK_STATUS_CREATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        createTaskStatus,
        loading,
    };
};

export default useCreateTaskStatus;
