import {
    ERROR_IMPLEMENTATIONS_UPDATE,
    SUCCESS_IMPLEMENTATIONS_UPDATE,
} from 'constants/notificationMessage';

import { ImplementationForm } from 'page/Implementation/components/formData';
import useImplementationUpdate from 'shared/data-hook/implementation/update/useImplementationUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useUpdateImplementations = () => {
    const { error, implementationUpdate, loading } = useImplementationUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const updateImplementation = async (data: ImplementationForm[], implementationIds: string[]) => {
        const response = await implementationUpdate(data, implementationIds);

        if (error || !response) {
            showError(ERROR_IMPLEMENTATIONS_UPDATE);

            return false;
        }

        showSuccess(SUCCESS_IMPLEMENTATIONS_UPDATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        updateImplementation,
        loading,
    };
};

export default useUpdateImplementations;
