import { ERROR_PRT_DELETE, SUCCESS_PRT_DELETE } from 'constants/notificationMessage';

import usePrtDelete from 'shared/data-hook/prt/delete/usePrtDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { PrtT } from 'types/Prt';

const useDeletePrt = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deletePrt } = usePrtDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const prtDelete = async (prtId: PrtT['id']): Promise<boolean> => {
        const response = await deletePrt(prtId);

        if (error || !response?.deletePrt) {
            showError(ERROR_PRT_DELETE);

            return false;
        }

        showSuccess(SUCCESS_PRT_DELETE);
        toggleRefetchTrigger();

        return true;
    };

    return { prtDelete };
};

export default useDeletePrt;
