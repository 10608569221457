export type PrtT = {
    id: string;
    name: string;
    description?: string;
    order: number;
};

export type PrtsT = {
    prts: PrtT[];
    prtsCount: number;
};

export const EMPTY_PRTS_RESULT: PrtsT = {
    prts: [],
    prtsCount: 0,
};

export const EMPTY_PRT_RESULT: PrtT = {
    id: '',
    name: '',
    description: '',
    order: 0,
};
