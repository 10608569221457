import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { PrtT } from 'types/Prt';

const DTO = t.type({
    id: t.string,
    name: t.string,
    description: Nullable(t.string),
    order: t.number,
});

export type PrtDTOT = t.TypeOf<typeof DTO>;

export const PrtDTO = tPromise.extendDecoder<PrtDTOT, PrtT>(
    DTO,
    ({
        id, name, description, order,
    }) => ({
        id,
        name,
        description: description ?? undefined,
        order,
    }),
) as t.Type<PrtT, PrtDTOT>;
