import { ClientFullNameT } from './Client';
import { ProviderIncomeT } from './ProviderIncome';

export type ImplementationT = {
    id: string;
    client: ClientFullNameT | null;
    operation: string | null;
    service: string;
    datetime: Date;
    place: string | null;
    performer: string | null;
    comment: string | null;
    rate: number | null;
    title: string | null;
    total: number | null;
    document: string;
    documentId: string;
    payer: ClientFullNameT | null;
    duration: number;
    datetimeOperation: Date | null;
    discount: number | null;
    paymentInFiat: number | null;
    paymentInBonus: number | null;
    serviceCategory: string | null;
    priceType: string | null;
    user: string | null;
    clientId?: string;
    payerId?: string;
    isMadeByClub?: boolean;
    cancelId?: string;
    cashbackBonusPaymentDocumentId?: string;
    cashbackFiatPaymentDocumentId?: string;
    coachId?: string;
    courtId?: string;
    groupId?: string;
    priceSalesRoundId?: string;
    priceTypeId?: string;
    serviceCategoryId?: string;
    transferId?: string;
    userId?: string;
    discountPercent?: number;
    guestsAmount?: number;
    cashbackBonusAmount?: number;
    cashbackFiatAmount?: number;
    performerIncomeBonus?: number;
    performerIncomeFiat?: number;
    providerIncomes?: ProviderIncomeT[];
};

export type ImplementationsT = {
    implementations: ImplementationT[];
    implementationsCount: number;
};

export const EMPTY_IMPLEMENTATION_RESULT: ImplementationT = {
    id: '',
    client: null,
    payer: null,
    serviceCategory: null,
    priceType: null,
    user: null,
    operation: null,
    service: '',
    datetime: new Date(),
    place: null,
    performer: null,
    comment: null,
    rate: null,
    total: null,
    document: '',
    documentId: '',
    duration: 0,
    datetimeOperation: null,
    discount: null,
    title: null,
    paymentInFiat: null,
    paymentInBonus: null,
    isMadeByClub: false,
    cancelId: '',
    cashbackBonusPaymentDocumentId: '',
    cashbackFiatPaymentDocumentId: '',
    coachId: '',
    courtId: '',
    groupId: '',
    priceSalesRoundId: '',
    priceTypeId: '',
    serviceCategoryId: '',
    transferId: '',
    userId: '',
    discountPercent: 0,
    guestsAmount: 0,
    cashbackBonusAmount: 0,
    cashbackFiatAmount: 0,
    performerIncomeBonus: 0,
    performerIncomeFiat: 0,
    providerIncomes: [],
};

export const EMPTY_IMPLEMENTATIONS_RESULT: ImplementationsT = {
    implementations: [],
    implementationsCount: 0,
};

export const EMPTY_IMPLEMENTATIONS_BY_DOCUMENT_RESULT: ImplementationT[] = [];
