import { MODAL_TITLE_CREATE_MEMBER_CARD_TYPE } from 'constants/modalTitles';

import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import Modal from 'shared/component/Modal/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreateMemberCardType from 'shared/hook/memberCardType/create/useCreateMemberCardType';
import { useStore } from 'store';

import {
    CREATE_MEMBER_CARD_TYPE_DEFAULT_VALUES, MEMBER_CARD_TYPE_VALIDATION_SCHEMA,
    MemberCardTypeFormFields,
} from './formData';
import MemberCardTypeForm from './MemberCardTypeForm';

const CreateMemberCardTypeModal = observer(() => {
    const { createMemberCardType, loading: createMemberCardTypeLoading } = useCreateMemberCardType();
    const { isOpen, onClose } = useStore('createMemberCardTypeModal');

    const { control, handleSubmit, reset } = useForm<MemberCardTypeFormFields>({
        defaultValues: CREATE_MEMBER_CARD_TYPE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(MEMBER_CARD_TYPE_VALIDATION_SCHEMA),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreate = (data: MemberCardTypeFormFields) => {
        createMemberCardType(data).then(handleCloseModal);
    };

    return (
        <Modal open={ isOpen } maxWidth="lg">
            <ModalTitle onClose={ handleCloseModal }>{ MODAL_TITLE_CREATE_MEMBER_CARD_TYPE }</ModalTitle>
            <DialogContent dividers={ true }>
                <MemberCardTypeForm control={ control } />
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    onClick={ handleSubmit(handleCreate) }
                    loading={ createMemberCardTypeLoading }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateMemberCardTypeModal;
