import {
    MODAL_TITLE_UPDATE_IMPLEMENTATION,
} from 'constants/modalTitles';

import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useUpdateImplementations from 'shared/hook/implementation/update/useUpdateImplementations';
import useFetchImplementationsByDocument
    from 'shared/hook/implementationDocument/fetchImplementationsByDocument/useFetchImplementationsByDocument';
import { useStore } from 'store';

import {
    IMPLEMENTATION_VALIDATION_SCHEMA, ImplementationFormFields, IMPLEMENTATIONS_DEFAULT_VALUES,
} from './formData';
import ImplementationForm from './ImplementationForm';

const UpdateImplementationModal: FC = observer(() => {
    const {
        isOpen, onClose, implementationsByDocument, itemId: documentId,
    } = useStore('updateImplementationModal');
    const { loading: loadingDoc } = useFetchImplementationsByDocument(documentId);
    const {
        control, handleSubmit, reset, setValue, formState: { isValid, isDirty },
    } = useForm<ImplementationFormFields>({
        defaultValues: IMPLEMENTATIONS_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(IMPLEMENTATION_VALIDATION_SCHEMA),
    });
    const { updateImplementation, loading: loadingUpdate } = useUpdateImplementations();

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateImplementation = async (data: ImplementationFormFields) => {
        const ids = data.implementations.map((item) => item.id);

        await updateImplementation(data.implementations, ids);
        handleCloseModal();
    };

    return (
        <Modal open={ isOpen } maxWidth="lg">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_UPDATE_IMPLEMENTATION }
            </ModalTitle>
            <DialogContent dividers={ true }>
                { loadingDoc && <Spinner /> }
                <ImplementationForm control={ control } setValue={ setValue } implementationsByDocument={ implementationsByDocument } />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={ () => {
                        reset({
                            implementations: implementationsByDocument,
                        });
                    } }
                >
                    Сбросить
                </Button>
                <Button variant="outlined" onClick={ handleCloseModal }>
                    Отмена
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleUpdateImplementation) }
                    autoFocus
                    disabled={ !isValid || !isDirty }
                    loading={ loadingUpdate }
                >
                    Обновить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateImplementationModal;
