import { PRICE_SEASON } from 'constants/routes';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import usePriceSalesRounds from 'shared/data-hook/priceSalesRound/fetch/fetchAll/usePriceSalesRounds';
import usePricesByServiceCategories
    from 'shared/data-hook/pricesByServiceCategory/fetch/fetchAll/usePricesByServiceCategories';
import usePriceSeason from 'shared/data-hook/priceSeason/fetch/fetchOne/usePriceSeason';
import usePriceTypes from 'shared/data-hook/priceType/fetch/fetchAll/usePriceTypes';
import { useCurrentUser } from 'shared/data-hook/user';
import { COACH_CATEGORY_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/coachCategory/CoachCategoryQuery';
import { COURT_TYPE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/courtType/CourtTypeQuery';
import { GROUP_VIEW_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/groupView/GroupViewQuery';
import { SERVICE_CATEGORY_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceCategory/ServiceCategoryQuery';
import useFetchClientData from 'shared/hook/clientData/fetch/fetchOne/useFetchClientData';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataByPriceTypeIdAndPriceSeasonId
    from 'shared/hook/dataGrid/useFetchDataByPriceTypeIdAndPriceSeasonId';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import useFetchUser from 'shared/hook/user/fetch/fetchOne/useFetchUser';
import { useStore } from 'store';
import { PRICE_TYPES_IS_ACTIVE, PRICE_TYPES_POSITION_ASC } from 'types/PriceType';

import CreatePriceModal from './components/CreatePriceModal/CreatePriceModal';
import ExportPriceDataModal from './components/ExportPriceDataModal/ExportPriceDataModal';
import ServiceCategoryList from './components/ServiceCategoryList';
import UpdatePriceModal from './components/UpdatePriceModal/UpdatePriceModal';
import UpdatePriceRate from './components/UpdatePriceRate/UpdatePriceRate';
import { SERVICE_CATEGORY_WITH_PRICES_HEADER_DATA } from './domain/HeaderData';

const List = observer(() => {
    const [shouldUpdateGetParams, setShouldUpdateGetParams] = useState(true);
    const {
        pageSize,
        setPageSize,
        page,
        setPage,
        sortModel,
        setSortModel,
        filterModel,
        setFilterModel,
    } = useDataGrid();
    const { id: priceSeasonId, priceTypeId } = useParams();
    const navigate = useNavigate();
    const [tab, setTab] = useState(priceTypeId ?? '');
    const {
        setPriceTypes, setPriceSeason, priceSeason,
    } = useStore('price');
    const { setPricesByServiceCategories } = useStore('pricesByServiceCategory');
    const {
        user,
    } = useStore('user');
    const { setPriceSalesRounds } = useStore('priceSalesRound');
    const { setPageTitle } = useStore('pageTitleStore');
    const { refetchTrigger } = useStore('refetchTrigger');
    const {
        data: pricesByServiceCategoriesData,
        loading,
        count: rowCount,
        fetchPricesByServiceCategories,
    } = usePricesByServiceCategories();
    const {
        data: priceTypesData, loading: priceTypesLoading, fetchPriceTypes,
    } = usePriceTypes();
    const { user: currentUser } = useCurrentUser();
    const { loading: priceSalesRoundsLoading, data: priceSalesRoundsData, fetchSalesRounds } = usePriceSalesRounds();
    const { loading: courtTypesLoading } = useFetchFilterOptions(COURT_TYPE_FILTER_OPTIONS_QUERY, 'courtType');
    const { loading: serviceCategoriesLoading } = useFetchFilterOptions(SERVICE_CATEGORY_FILTER_OPTIONS_QUERY, 'serviceCategory');
    const { loading: coachCategoriesLoading } = useFetchFilterOptions(COACH_CATEGORY_FILTER_OPTIONS_QUERY, 'coachCategory');
    const { loading: groupViewsLoading } = useFetchFilterOptions(GROUP_VIEW_FILTER_OPTIONS_QUERY, 'groupView');

    useFetchUser(currentUser?.id || '');
    useFetchClientData(user.activeClientId || '');
    const isLoading = loading || priceSalesRoundsLoading || courtTypesLoading || serviceCategoriesLoading || coachCategoriesLoading || groupViewsLoading;
    const { fetchPriceSeason } = usePriceSeason();
    const getParams = window.location.search.substring(1);

    const handleTabChange = (newTab: string) => {
        setTab(newTab);
    };

    useEffect(() => {
        if (!priceSeasonId || !priceTypeId) {
            return;
        }

        useFetchDataByPriceTypeIdAndPriceSeasonId(priceTypeId, priceSeasonId, sortModel, filterModel, page, pageSize, SERVICE_CATEGORY_WITH_PRICES_HEADER_DATA, fetchPricesByServiceCategories);
    }, [sortModel, filterModel, page, pageSize, priceSeasonId, refetchTrigger, priceTypeId]);

    useEffect(() => {
        if (pricesByServiceCategoriesData) {
            setPricesByServiceCategories(pricesByServiceCategoriesData, rowCount);
        }
    }, [loading, pricesByServiceCategoriesData]);

    useEffect(() => {
        setPriceTypes(priceTypesData);
    }, [priceTypesLoading, priceTypesData]);

    useEffect(() => {
        fetchPriceTypes({ orderBy: PRICE_TYPES_POSITION_ASC, where: PRICE_TYPES_IS_ACTIVE }).then();
        fetchPriceSeason(priceSeasonId as string).then((priceSeasonData) => {
            setPriceSeason(priceSeasonData);
        });
    }, []);

    useEffect(() => {
        setPriceSalesRounds(priceSalesRoundsData);
    }, [priceSalesRoundsData, priceSalesRoundsLoading]);

    useEffect(() => {
        if (!priceSeason) {
            return;
        }
        setPageTitle(<>Прайс сезона &quot;{ priceSeason.title }&quot; <Typography variant="body1" display="inline" color="rgba(0, 0, 0, 0.6)">{ dayjs(priceSeason.startAt).format('DD MMMM YYYY') } - { dayjs(priceSeason.finishAt).format('DD MMMM YYYY') }</Typography></>);
    }, [priceSeason]);

    useEffect(() => {
        if (shouldUpdateGetParams) {
            navigate(`${PRICE_SEASON}/${priceSeasonId}/${tab}?${getParams}`);
            setShouldUpdateGetParams(false);

            return;
        }

        navigate(`${PRICE_SEASON}/${priceSeasonId}/${tab}`);
    }, [tab]);

    return (
        <>
            <ServiceCategoryList
                tab={ tab }
                handleTabChange={ handleTabChange }
                loading={ isLoading }
                pageSize={ pageSize }
                page={ page }
                sortModel={ sortModel }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                filterModel={ filterModel }
                setFilterModel={ setFilterModel }
                fetchSalesRounds={ fetchSalesRounds }
                priceSeasonId={ priceSeasonId ?? '' }
            />
            <UpdatePriceModal />
            <UpdatePriceRate priceType={ tab } />
            <CreatePriceModal priceTypeId={ tab } priceSeasonId={ priceSeasonId ?? '' } />
            <ExportPriceDataModal priceTypeId={ tab } priceSeasonId={ priceSeasonId ?? '' } />
        </>
    );
});

export default List;
