import { gql } from '@apollo/client';

export type DeleteMultiplePriceResponseT = {
    deleteMultiplePrice: DeleteMultiplePriceResponseDataT;
};

export type DeleteMultiplePriceResponseDataT = {
    affectedRows: number;
}

export type DeleteMultiplePriceVarT = {
    ids: string[];
};

export const DELETE_MULTIPLE_PRICE = gql`
    mutation DeleteMultiplePrice($ids: [String!] = []) {
        deleteMultiplePrice: delete_price(where: {id: {_in: $ids}}) {
            affected_rows
        }
    }
`;
