import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { TaskTypesT } from 'types/TaskType';

import { TaskTypeDTO } from './TaskTypeDTO';

const DTO = t.type({
    taskTypes: t.array(TaskTypeDTO),
    taskTypesCount: AggregateDTO,
});

type TaskTypeDTOT = t.TypeOf<typeof DTO>;

export const TaskTypesDTO = tPromise.extendDecoder<TaskTypeDTOT, TaskTypesT>(
    DTO,
    ({
        taskTypes,
        taskTypesCount,
    }) => ({
        taskTypes,
        taskTypesCount,
    }),
) as t.Type<TaskTypesT, TaskTypeDTOT>;
