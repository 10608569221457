import { GridColumns } from '@mui/x-data-grid-premium';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { FilterOptionT } from 'types/DataGrid';

export const preparePriceHeaderData = (data: GridColumns, courtTypes: FilterOptionT[], coachCategories: FilterOptionT[], groupViews: FilterOptionT[]): GridColumns =>
    mapFields(data, {
        courtType: courtTypes,
        coachCategory: coachCategories,
        groupView: groupViews,
    }, true);
