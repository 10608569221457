import { makeAutoObservable } from 'mobx';
import { AgeT, EMPTY_AGE_RESULT } from 'types/Age';

export default class AgeStore {
    public ages: AgeT[] = [];

    public age: AgeT = EMPTY_AGE_RESULT;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setAges(ages: AgeT[], count: number) {
        this.ages = ages;
        this.count = count;
    }

    public setAge(age: AgeT) {
        this.age = age;
    }
}
