import { useEffect } from 'react';
import usePaymentTypes from 'shared/data-hook/paymentType/fetch/fetchAll/usePaymentTypes';
import { useStore } from 'store';

const useFetchPaymentTypes = () => {
    const { fetchPaymentTypes: fetch, loading, data } = usePaymentTypes();
    const { setPaymentTypes } = useStore('paymentType');

    useEffect(() => {
        fetch().then();
    }, []);

    useEffect(() => {
        setPaymentTypes(data);
    }, [data]);

    return {
        loading,
    };
};

export default useFetchPaymentTypes;
