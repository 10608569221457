export type ClientT = {
    id: string;
    email: string | null;
    relationIds: string[];
} & ClientFullNameT;

export const EMPTY_CLIENTS_RESULT = {
    clients: [],
};

export const EMPTY_CLIENT_RESULT = {
    id: '',
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    relationIds: [],
};

export type ClientFullNameT = {
    id?: string;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
};
