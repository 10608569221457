import { action, makeObservable, observable } from 'mobx';
import { EMPTY_TASK_TYPE_RESULT, TaskTypeT } from 'types/TaskType';

import BaseModalStore from './BaseModalStore';

export default class UpdateTaskTypeModal extends BaseModalStore {
    public taskType: TaskTypeT = EMPTY_TASK_TYPE_RESULT;

    public setTaskType(taskType: TaskTypeT) {
        this.taskType = taskType;
    }

    constructor() {
        super();

        makeObservable(this, {
            taskType: observable,
            setTaskType: action.bound,
        });
    }
}
