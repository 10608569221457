import { PAGE_TITLE_AGE } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useAges from 'shared/data-hook/age/fetch/fetchAll/useAges';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import AgeListComponent from './components/AgeListComponent';
import CreateAgeModal from './components/CreateAgeModal';
import UpdateAgeModal from './components/UpdateAgeModal';
import { AGE_HEADER_DATA } from './domain/HeaderData';

const AgeList: FC = observer(() => {
    const {
        pageSize,
        setPageSize,
        page,
        setPage,
        sortModel,
        setSortModel,
        filterModel,
        setFilterModel,
    } = useDataGrid();
    const {
        data: agesData,
        loading: agesLoading,
        count: rowCount,
        fetchAges,
    } = useAges();

    const { refetchTrigger } = useStore('refetchTrigger');
    const { onOpen } = useStore('createAgeModal');
    const { setAges } = useStore('age');

    useFetchDataForGrid(
        sortModel,
        filterModel,
        page,
        pageSize,
        fetchAges,
        refetchTrigger,
        AGE_HEADER_DATA,
    );
    useEffect(() => {
        setAges(agesData, rowCount);
    }, [agesData, agesLoading]);

    usePageTitle(PAGE_TITLE_AGE);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => onOpen() }
                    startIcon={ <AddIcon /> }
                >
                    добавить
                </Button>
            </ControlPanel>
            <AgeListComponent
                loading={ agesLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateAgeModal />
            <UpdateAgeModal />
        </StyledPageWrap>
    );
});

export default AgeList;
