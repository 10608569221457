import { SearchT } from 'types/DataGrid';

export const getFullNameClientsFilter = ({ name }: SearchT) => ({
    ...(name && {
        client: {
            full_name: {
                _iregex: name.replaceAll(' ', '.*'),
            },
        },
    }),
});

export const getFullNameMemberCardFilter = (relationIds: string[]) => ({
    client: { id: { _in: relationIds } },
});
