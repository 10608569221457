import { ERROR_PRT_UPDATE, SUCCESS_PRT_UPDATE } from 'constants/notificationMessage';

import { PrtFormFields } from 'page/Prt/components/formData';
import usePrtUpdate from 'shared/data-hook/prt/update/usePrtUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useUpdatePrt = () => {
    const { error, prtUpdate, loading } = usePrtUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const updatePrt = async (data: PrtFormFields, id: string) => {
        const response = await prtUpdate(data, id);

        if (error || !response) {
            showError(ERROR_PRT_UPDATE);

            return false;
        }

        showSuccess(SUCCESS_PRT_UPDATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        updatePrt,
        loading,
    };
};

export default useUpdatePrt;
