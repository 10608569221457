import { useEffect } from 'react';
import useUser from 'shared/data-hook/user/fetch/fetchOne/useUser';
import { useStore } from 'store';

const useFetchUser = (id: string) => {
    const { fetchUser, loading } = useUser();
    const { setUser } = useStore('user');

    useEffect(() => {
        fetchUser(id).then((fetchedUser) => {
            setUser(fetchedUser);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchUser;
