import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { TASK_TYPE_QUERY, TaskTypeResponseT } from 'shared/graphql/query/taskType/TaskTypeQuery';
import { EMPTY_TASK_TYPE_RESULT, TaskTypeT } from 'types/TaskType';

import { TaskTypeDTO } from '../DTO/TaskTypeDTO';

import { TaskTypeDataT } from './TaskTypeData';

const useTaskType = (): TaskTypeDataT => {
    const [fetch, { loading, error }] = useLazyQuery<TaskTypeResponseT>(
        TASK_TYPE_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchTaskType = async (id: string): Promise<TaskTypeT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                TaskTypeDTO,
                EMPTY_TASK_TYPE_RESULT,
                (taskType) => taskType,
                data?.taskType,
            );
        } catch {
            return EMPTY_TASK_TYPE_RESULT;
        }
    };

    return {
        fetchTaskType,
        loading,
        error,
    };
};

export default useTaskType;
