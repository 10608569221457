import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ProviderIncomeDTO } from 'shared/data-hook/providerIncome/DTO/ProviderIncomeDTO';
import { ClientDataDTO } from 'shared/data-hook/utils/DTO/Client';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { ImplementationT } from 'types/Implementation';

const DTO = t.type({
    client_id: Nullable(t.string),
    payer_id: Nullable(t.string),
    is_made_by_club: t.boolean,
    cancel_id: Nullable(t.string),
    cashback_bonus_payment_document_id: Nullable(t.string),
    cashback_fiat_payment_document_id: Nullable(t.string),
    coach_id: Nullable(t.string),
    court_id: Nullable(t.string),
    group_id: Nullable(t.string),
    operation: Nullable(t.string),
    performer: Nullable(t.string),
    place: Nullable(t.string),
    price_sales_round_id: Nullable(t.string),
    price_type_id: Nullable(t.string),
    service: t.string,
    service_category_id: Nullable(t.string),
    transfer_id: Nullable(t.string),
    user_id: Nullable(t.string),
    discount_percent: Nullable(t.number),
    duration: t.number,
    guests_amount: t.number,
    cashback_bonus_amount: t.number,
    cashback_fiat_amount: t.number,
    discount: Nullable(t.number),
    payment_in_bonus: Nullable(t.number),
    payment_in_fiat: Nullable(t.number),
    performer_income_bonus: t.number,
    performer_income_fiat: t.number,
    rate: Nullable(t.number),
    total: Nullable(t.number),
    comment: Nullable(t.string),
    document: t.string,
    title: Nullable(t.string),
    datetime: t.string,
    datetime_operation: Nullable(t.string),
    id: t.string,
    client_datum: ClientDataDTO,
    document_id: t.string,
    clientDatumByPayerId: ClientDataDTO,
    service_category: Nullable(t.type({
        name: t.string,
    })),
    price_type: Nullable(t.type({
        title: t.string,
    })),
    user: Nullable(t.type({
        full_name: Nullable(t.string),
    })),
    provider_incomes: t.array(ProviderIncomeDTO),
});

export type ImplementationByDocumentDTOT = t.TypeOf<typeof DTO>;

export const ImplementationByDocumentDTO = tPromise.extendDecoder<ImplementationByDocumentDTOT, ImplementationT>(
    DTO,
    // eslint-disable-next-line complexity
    ({
        id,
        client_id,
        payer_id,
        client_datum,
        service_category,
        is_made_by_club,
        cancel_id,
        cashback_bonus_payment_document_id,
        cashback_fiat_payment_document_id,
        coach_id,
        court_id,
        group_id,
        document_id,
        operation,
        performer,
        place,
        price_sales_round_id,
        price_type_id,
        service,
        service_category_id,
        transfer_id,
        user_id,
        discount_percent,
        duration,
        guests_amount,
        cashback_bonus_amount,
        cashback_fiat_amount,
        discount,
        payment_in_bonus,
        payment_in_fiat,
        performer_income_bonus,
        performer_income_fiat,
        rate,
        total,
        comment,
        document,
        title,
        datetime,
        datetime_operation,
        provider_incomes,
    }) => ({
        id,
        clientId: client_id ?? '',
        payerId: payer_id ?? '',
        client: client_datum?.client ?? null,
        documentId: document_id ?? '',
        payer: null,
        service_category: service_category ?? null,
        priceType: null,
        user: null,
        serviceCategory: service_category_id ?? '',
        isMadeByClub: is_made_by_club,
        cancelId: cancel_id ?? '',
        cashbackBonusPaymentDocumentId: cashback_bonus_payment_document_id ?? '',
        cashbackFiatPaymentDocumentId: cashback_fiat_payment_document_id ?? '',
        coachId: coach_id ?? '',
        courtId: court_id ?? '',
        groupId: group_id ?? '',
        operation: operation ?? '',
        performer,
        place,
        priceSalesRoundId: price_sales_round_id ?? '',
        priceTypeId: price_type_id ?? '',
        service,
        serviceCategoryId: service_category_id ?? '',
        transferId: transfer_id ?? '',
        userId: user_id ?? '',
        discountPercent: discount_percent ?? undefined,
        duration,
        guestsAmount: guests_amount,
        cashbackBonusAmount: cashback_bonus_amount,
        cashbackFiatAmount: cashback_fiat_amount,
        discount,
        paymentInBonus: payment_in_bonus,
        paymentInFiat: payment_in_fiat,
        performerIncomeBonus: performer_income_bonus,
        performerIncomeFiat: performer_income_fiat,
        rate,
        total,
        comment: comment ?? '',
        document: document ?? '',
        title: title ?? '',
        datetime: new Date(datetime),
        datetimeOperation: datetime_operation ? new Date(datetime_operation) : null,
        providerIncomes: provider_incomes,
    }),
) as t.Type<ImplementationT, ImplementationByDocumentDTOT>;
