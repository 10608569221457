import { ClientFullNameT } from 'types/Client';
import { ProviderIncomeT } from 'types/ProviderIncome';
import {
    array, bool, date, number, object, string,
} from 'yup';

export type ImplementationFormFields = {
    implementations: ImplementationForm[];
}

export type ImplementationForm = {
    id: string;
    clientId?: string;
    payerId?: string;
    isMadeByClub?: boolean;
    cancelId?: string;
    client: ClientFullNameT | null;
    cashbackBonusPaymentDocumentId?: string;
    cashbackFiatPaymentDocumentId?: string;
    coachId?: string;
    courtId?: string;
    groupId?: string;
    operation: string | null;
    performer: string | null;
    place: string | null;
    priceSalesRoundId?: string;
    priceTypeId?: string;
    service: string;
    serviceCategoryId?: string;
    transferId?: string;
    userId?: string;
    discountPercent?: number;
    duration: number;
    guestsAmount?: number;
    cashbackBonusAmount?: number;
    cashbackFiatAmount?: number;
    discount: number | null;
    paymentInBonus: number | null;
    paymentInFiat: number | null;
    performerIncomeBonus?: number;
    performerIncomeFiat?: number;
    rate: number | null;
    total: number | null;
    comment: string | null;
    document: string;
    documentId: string;
    title: string | null;
    datetime: Date;
    datetimeOperation: Date | null;
    providerIncomes?: ProviderIncomeT[];
};

export const IMPLEMENTATIONS_DEFAULT_VALUES: ImplementationFormFields = {
    implementations: [],
};

export const IMPLEMENTATION_VALIDATION_SCHEMA = object().shape({
    implementations: array().of(
        object().shape({
            isMadeByClub: bool(),
            cancelId: string(),
            cashbackBonusPaymentDocumentId: string(),
            cashbackFiatPaymentDocumentId: string(),
            coachId: string(),
            courtId: string(),
            groupId: string(),
            operation: string(),
            performer: string(),
            place: string(),
            priceSalesRoundId: string(),
            priceTypeId: string(),
            service: string().required(),
            serviceCategoryId: string(),
            transferId: string(),
            userId: string(),
            discountPercent: number().min(0).max(100),
            duration: number().required(),
            guestsAmount: number().required(),
            cashbackBonusAmount: number().required(),
            cashbackFiatAmount: number().required(),
            discount: number(),
            paymentInBonus: number(),
            paymentInFiat: number(),
            performerIncomeBonus: number().required(),
            performerIncomeFiat: number().required(),
            rate: number(),
            total: number(),
            comment: string(),
            document: string().required(),
            documentId: string().required(),
            title: string(),
            datetimeOperation: date(),
            datetime: date().required(),
            providerIncomes: array().of(
                object().shape({
                    coachId: string(),
                    operation: string(),
                    organizationId: string(),
                    place: string(),
                    priceTypeId: string(),
                    service: string(),
                    incomeInBonus: number(),
                    incomeInFiat: number(),
                    datetimeOperation: date().required(),
                }),
            ),
        }),
    ),
});
