import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import Form from './Form';

const ImportPrice: FC = () => {
    return (
        <Routes>
            <Route path="/" element={ <Form /> } />
        </Routes>
    );
};

export default ImportPrice;
