import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ClientDataDTO } from 'shared/data-hook/utils/DTO/Client';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { ImplementationDocumentT } from 'types/ImplementationDocument';

const DTO = t.type({
    id: t.string,
    number: t.string,
    datetime: t.string,
    type: Nullable(t.string),
    service_category: t.type({
        name: t.string,
    }),
    group: Nullable(t.type({
        name: t.string,
    })),
    client_datum: ClientDataDTO,
    comment: Nullable(t.string),
    groupMainCoach: Nullable(t.type({
        employee: t.type({
            full_name: t.string,
        }),
    })),
});

export type ImplementationDocumentDTOT = t.TypeOf<typeof DTO>;

export const ImplementationDocumentDTO = tPromise.extendDecoder<ImplementationDocumentDTOT, ImplementationDocumentT>(
    DTO,
    ({
        id,
        number,
        datetime,
        type,
        service_category: { name: serviceCategory },
        group,
        client_datum,
        comment,
        groupMainCoach,
    }) => ({
        id,
        number,
        datetime: new Date(datetime),
        type,
        serviceCategory,
        group: group?.name ?? null,
        client: client_datum?.client ?? null,
        comment,
        groupMainCoach: groupMainCoach ? groupMainCoach.employee.full_name : null,
    }),
) as t.Type<ImplementationDocumentT, ImplementationDocumentDTOT>;
