import { CoachT } from './Coach';

export type CoachCategoryT = {
    id: string;
    name: string;
    basicRate: number;
    coaches: CoachT[];
};

export type CoachCategoriesT = {
    coachCategories: CoachCategoryT[];
    coachCategoriesCount: number;
};

export const EMPTY_COACH_CATEGORY_RESULT: CoachCategoryT = {
    id: '',
    name: '',
    basicRate: 0,
    coaches: [],
};

export const EMPTY_COACH_CATEGORIES_RESULT: CoachCategoriesT = {
    coachCategories: [],
    coachCategoriesCount: 0,
};
