import { MODAL_TITLE_CREATE_TASK_TYPE } from 'constants/modalTitles';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreateTaskType from 'shared/hook/taskType/create/useCreateTaskType';
import { useStore } from 'store';

import { getTaskTypeValidationSchema, TASK_TYPE_DEFAULT_VALUES, TaskTypeFormFields } from './formData';
import TaskTypeForm from './TaskTypeForm';

const CreateTaskTypeModal: FC = observer(() => {
    const {
        isOpen, onClose,
    } = useStore('createTaskTypeModal');
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const { createTaskType, loading: loadingSave } = useCreateTaskType();

    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<TaskTypeFormFields>({
        defaultValues: TASK_TYPE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getTaskTypeValidationSchema()),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreateTaskType = (data: TaskTypeFormFields) => {
        createTaskType(data).then(() => {
            handleCloseModal();
            toggleRefetchTrigger();
        });
    };

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_CREATE_TASK_TYPE }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <TaskTypeForm
                    control={ control }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                <Button variant="outlined" onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleCreateTaskType) }
                    autoFocus
                    disabled={ !isDirty || !isValid }
                    loading={ loadingSave }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateTaskTypeModal;
