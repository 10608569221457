import { GridColDef } from '@mui/x-data-grid-premium';
import { CustomHeaderData, FilterOptionT } from 'types/DataGrid';

export const mapFields = (headerData: CustomHeaderData[], fieldMappings: Record<string, FilterOptionT[]>, isClientSideFilter?: boolean) => {
    return headerData.map((item): GridColDef => ({
        ...item,
        valueOptions: (
            isClientSideFilter
                ? fieldMappings[item.field]?.map(({ label }) => label)
                : fieldMappings[item.field]
        ) || item.valueOptions,
    }));
};
