import { gql } from '@apollo/client';
import { TaskStatusT } from 'types/TaskStatus';

export type TaskStatusDeleteResponseDataT = {
    deleteTaskStatus: {
        id: TaskStatusT['id'];
    };
} | null | undefined;

export type TaskStatusDeleteVarT = {
    id: TaskStatusT['id'];
};

export const TASK_STATUS_DELETE = gql`
    mutation DeleteTaskStatus($id: String = "") {
        deleteTaskStatus: delete_task_status_by_pk(id: $id) {
            id
        }
    }
`;
