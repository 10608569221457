import { MODAL_TITLE_UPDATE_MEMBER_CARD_TYPE } from 'constants/modalTitles';

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Skeleton,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useFetchMemberCardType from 'shared/hook/memberCardType/fetch/fetchOne/useFetchMemberCardType';
import useUpdateMemberCardType from 'shared/hook/memberCardType/update/useUpdateMemberCardType';
import { useStore } from 'store';

import { MEMBER_CARD_TYPE_DEFAULT_VALUES, MEMBER_CARD_TYPE_VALIDATION_SCHEMA, MemberCardTypeFormFields } from './formData';
import MemberCardTypeForm from './MemberCardTypeForm';

const UpdateMemberCardTypeModal = observer(() => {
    const {
        isOpen, onClose, memberCardType, itemId,
    } = useStore('updateMemberCardTypeModal');

    const { loading: loadingFetch } = useFetchMemberCardType(itemId);

    const { updateMemberCardType, loading: loadingUpdate } = useUpdateMemberCardType();

    const {
        control, handleSubmit, setValue, reset, formState: { isValid, isDirty },
    } = useForm<MemberCardTypeFormFields>({
        defaultValues: MEMBER_CARD_TYPE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(MEMBER_CARD_TYPE_VALIDATION_SCHEMA),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleUpdate = (data: MemberCardTypeFormFields) => {
        updateMemberCardType(data, itemId).then(handleCloseModal);
    };

    useEffect(() => {
        if (memberCardType) {
            Object.keys(memberCardType).forEach((key) => {
                setValue(key as keyof MemberCardTypeFormFields, memberCardType[key as keyof MemberCardTypeFormFields as never]);
            });
        }
    }, [memberCardType]);

    return (
        <Modal open={ isOpen } maxWidth="lg">
            <ModalTitle onClose={ onClose }>
                { loadingFetch ?
                    <Skeleton height={ 32 } /> : `${MODAL_TITLE_UPDATE_MEMBER_CARD_TYPE} ${memberCardType?.title}` }
            </ModalTitle>
            <DialogContent>
                { loadingFetch ? <Skeleton /> : <MemberCardTypeForm control={ control } /> }
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    onClick={ handleSubmit(handleUpdate) }
                    loading={ loadingUpdate }
                    disabled={ !isValid || !isDirty }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateMemberCardTypeModal;
