import { ERROR_PAYMENT_DOCUMENT_UPDATE, SUCCESS_PAYMENT_DOCUMENT_UPDATE } from 'constants/notificationMessage';

import usePaymentDocumentArchive from 'shared/data-hook/paymentDocument/archive/usePaymentDocumentArchive';
import { PaymentDocumentArchivePayloadT } from 'shared/graphql/mutation/paymentDocument/PaymentDocumentArchive';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useArchivePaymentDocument = () => {
    const { error, paymentDocumentArchive } = usePaymentDocumentArchive();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const archivePaymentDocument = async (payload: PaymentDocumentArchivePayloadT) => {
        const response = await paymentDocumentArchive(payload);

        if (error || !response || response.baseResponse[0].status === 'error') {
            showError(ERROR_PAYMENT_DOCUMENT_UPDATE);
        } else {
            showSuccess(SUCCESS_PAYMENT_DOCUMENT_UPDATE);
        }
        toggleRefetchTrigger();
    };

    return {
        archivePaymentDocument,
    };
};

export default useArchivePaymentDocument;
