import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { PRTS_QUERY, PrtsResponseT } from 'shared/graphql/query/prt/prtQuery';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_PRTS_RESULT } from 'types/Prt';

import { PrtsDTO } from '../DTO/PrtsDTO';

import { PrtsDataT } from './PrtsData';

const usePrts = (): PrtsDataT => {
    const [fetch, { data, loading, error }] = useLazyQuery<PrtsResponseT, QueryVarsT>(
        PRTS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedPrtsData = decodeDTO(
        PrtsDTO,
        EMPTY_PRTS_RESULT,
        (prts) => prts,
        {
            prts: data?.prts,
            prtsCount: data?.prtsCount,
        },
    );

    const fetchPrts = async ({
        limit,
        offset,
        orderBy = {},
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        data: decodedPrtsData.prts,
        count: decodedPrtsData.prtsCount,
        fetchPrts,
        loading,
        error,
    };
};

export default usePrts;
