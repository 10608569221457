import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { AgesT } from 'types/Age';

import { AgeDTO } from './AgeDTO';

const DTO = t.type({
    ages: t.array(AgeDTO),
    agesCount: AggregateDTO,
});

type AgeDTOT = t.TypeOf<typeof DTO>;

export const AgesDTO = tPromise.extendDecoder<AgeDTOT, AgesT>(
    DTO,
    ({
        ages,
        agesCount,
    }) => ({
        ages,
        agesCount,
    }),
) as t.Type<AgesT, AgeDTOT>;
