import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { PriceMemberCardT } from 'types/PriceMemberCard';

const DTO = t.type({
    id: t.string,
    title: t.string,
    member_card_type_discount_rates: t.array(t.type({
        id: t.string,
        price_sales_round: t.type({
            position: t.number,
            title: t.string,
        }),
        price_sales_round_id: t.string,
        discount_percent_fiat: t.number,
        discount_percent_bonus: t.number,
        multiplying_factor: t.number,
        discount_percent_bonus_per_free_slot: t.number,
        cashback_percent_bonus: t.number,
        cashback_percent_fiat: t.number,
    })),
    member_card_type_price_season_prices: t.array(t.type({
        price: Nullable(t.number),
    })),
    member_card_type_discount_full_returns: t.array(t.type({
        id: t.string,
        enabled_at_months: t.array(t.string),
        full_return_quantity_limit: t.number,
        member_card_type_discount_full_return_price_sales_rounds: t.array(t.type({
            price_sales_round: t.type({
                id: t.string,
                title: t.string,
            }),
        })),
        is_performer_income_recalculating: t.boolean,
    })),
});

export type PriceMemberCardDTOT = t.TypeOf<typeof DTO>;

export const PriceMemberCardDTO = tPromise.extendDecoder<PriceMemberCardDTOT, PriceMemberCardT>(
    DTO,
    ({
        id,
        title,
        member_card_type_price_season_prices,
        member_card_type_discount_rates,
        member_card_type_discount_full_returns,
    }) => {
        const memberCardTypeDiscountRates = (member_card_type_discount_rates || []).map((item) => ({
            id: item.id,
            priceSalesRound: {
                position: item.price_sales_round.position,
                title: item.price_sales_round.title,
            },
            priceSalesRoundId: item.price_sales_round_id,
            discountPercentFiat: item.discount_percent_fiat,
            discountPercentBonus: item.discount_percent_bonus,
            multiplyingFactor: item.multiplying_factor,
            discountPercentBonusPerFreeSlot: item.discount_percent_bonus_per_free_slot,
            cashbackPercentBonus: item.cashback_percent_bonus,
            cashbackPercentFiat: item.cashback_percent_fiat,
        }));

        const memberCardTypeDiscountFullReturns = member_card_type_discount_full_returns.map((item) => ({
            id: item.id,
            fullReturnQuantityLimit: item.full_return_quantity_limit,
            enabledAtMonths: item.enabled_at_months,
            memberCardTypeDiscountFullReturnPriceSalesRounds: item.member_card_type_discount_full_return_price_sales_rounds.map((round) => ({
                priceSalesRound: {
                    id: round.price_sales_round.id,
                    title: round.price_sales_round.title,
                },
            })),
            isPerformerIncomeRecalculating: item.is_performer_income_recalculating,
        }));

        return {
            id,
            title,
            price: (member_card_type_price_season_prices[0] && member_card_type_price_season_prices[0].price) || 0,
            memberCardTypeDiscountRates,
            memberCardTypeDiscountFullReturns,
        };
    },
) as t.Type<PriceMemberCardT, PriceMemberCardDTOT>;
