export type AcquiringSettingT = {
    id: string;
    type: string;
    apiToken: string;
    apiUrl: string;
    apiLogin: string;
    apiPassword: string;
    serviceApp: {
        id: string;
        label: string;
    };
    paymentMethod?: {
        id: string;
        title: string;
    };
    paymentType?: {
        id: string;
        title: string;
    };
};

export type AcquiringSettingsT = {
    acquiringSettings: AcquiringSettingT[];
    acquiringSettingsCount: number;
};

export const EMPTY_ACQUIRING_SETTINGS_RESULT: AcquiringSettingsT = {
    acquiringSettings: [],
    acquiringSettingsCount: 0,
};

export const EMPTY_ACQUIRING_SETTING_RESULT: AcquiringSettingT = {
    id: '',
    type: '',
    apiToken: '',
    apiUrl: '',
    apiLogin: '',
    apiPassword: '',
    serviceApp: {
        id: '',
        label: '',
    },
    paymentMethod: {
        id: '',
        title: '',
    },
    paymentType: {
        id: '',
        title: '',
    },
};
