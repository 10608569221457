import { action, makeObservable, observable } from 'mobx';
import { DisplayColumnsModalSupportedTable } from 'types/DataGrid';

import BaseModalStore from './BaseModalStore';

export default class DisplayColumnsModal extends BaseModalStore {
    public selectedColumns: Record<DisplayColumnsModalSupportedTable, string[]> = {
        price: [],
        'price-member-cards': [],
    };

    public setSelectedColumns = (
        columns: Record<DisplayColumnsModalSupportedTable, string[]>,
    ): void => {
        this.selectedColumns = columns;
    };

    constructor() {
        super();
        makeObservable(this, {
            selectedColumns: observable,
            setSelectedColumns: action.bound,
        });
    }
}
