import {
    ERROR_AGE_DELETE, SUCCESS_AGE_DELETE,
} from 'constants/notificationMessage';

import useAgeDelete from 'shared/data-hook/age/delete/useAgeDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { AgeT } from 'types/Age';

const useDeleteAge = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteAge } = useAgeDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const ageDelete = async (ageId: AgeT['id']): Promise<boolean> => {
        const response = await deleteAge(ageId);

        if (error || !response?.deleteAge) {
            showError(ERROR_AGE_DELETE);

            return false;
        }

        showSuccess(SUCCESS_AGE_DELETE);
        toggleRefetchTrigger();

        return true;
    };

    return { ageDelete };
};

export default useDeleteAge;
