import { gql } from '@apollo/client';

export type TaskStatusFields = {
    category: string;
    name: string;
    position: number;
};

export type TaskStatusUpdateVar = {
    id: string;
    set: TaskStatusFields;
};

export type TaskStatusUpdateResponseDataT = {
    updateTaskStatus: {
        id: string;
    };
} | null | undefined;

export const TASK_STATUS_UPDATE = gql`
    mutation updateTaskStatus(
        $set: task_status_set_input = {},
        $id: String = ""
    ) {
        updateTaskStatus: update_task_status_by_pk(
            pk_columns: { id: $id },
            _set: $set
        ) {
            id
        }
    }
`;
