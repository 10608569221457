import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { AGE_HEADER_DATA } from '../domain/HeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const AgeList: FC<PropsT> = observer((props) => {
    const { ages, count } = useStore('age');

    return (
        <DataGrid
            rows={ ages }
            rowCount={ count }
            columns={ AGE_HEADER_DATA }
            { ...props }
        />
    );
});

export default AgeList;
