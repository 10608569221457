import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { PricesByServiceCategoriesT } from 'types/PricesByServiceCategory';

import { PricesByServiceCategoryDTO } from './PricesByServiceCategoryDTO';

const DTO = (currency: string) => t.type({
    pricesByServiceCategories: t.array(PricesByServiceCategoryDTO(currency)),
    pricesByServiceCategoriesCount: AggregateDTO,
});

export type PricesByServiceCategoriesDTOT = t.TypeOf<ReturnType<typeof DTO>>;

export const PricesByServiceCategoriesDTO = (currency: string) => tPromise.extendDecoder<PricesByServiceCategoriesDTOT, PricesByServiceCategoriesT>(
    DTO(currency),
    ({
        pricesByServiceCategories,
        pricesByServiceCategoriesCount,
    }) => ({
        pricesByServiceCategories,
        pricesByServiceCategoriesCount,
    }),
) as t.Type<PricesByServiceCategoriesT, PricesByServiceCategoriesDTOT>;
