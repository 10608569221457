import { useEffect } from 'react';
import useClient from 'shared/data-hook/client/fetch/fetchOne/useClient';
import { useStore } from 'store';

const useFetchClientData = (id: string) => {
    const { fetchClient, loading } = useClient();
    const { setClient } = useStore('exportPriceDataModal');

    useEffect(() => {
        if (id) {
            fetchClient(id).then((fetchedClient) => {
                setClient(fetchedClient);
            });
        }
    }, [id]);

    return {
        loading,
    };
};

export default useFetchClientData;
