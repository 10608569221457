import { MODAL_TITLE_CREATE_PRT } from 'constants/modalTitles';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreatePrt from 'shared/hook/prt/create/useCreatePrt';
import { useStore } from 'store';

import {
    getPrtValidationSchema,
    PRT_DEFAULT_VALUES,
    PrtFormFields,
} from './formData';
import PrtForm from './PrtForm';

const CreatePrtModal: FC = observer(() => {
    const { isOpen, onClose } = useStore('createPrtModal');
    const { createPrt, loading: loadingSave } = useCreatePrt();

    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<PrtFormFields>({
        defaultValues: PRT_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getPrtValidationSchema()),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreatePrt = (data: PrtFormFields) => {
        createPrt(data).then(() => {
            handleCloseModal();
        });
    };

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_CREATE_PRT }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <PrtForm
                    control={ control }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                <Button variant="outlined" onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleCreatePrt) }
                    autoFocus
                    disabled={ !isDirty || !isValid }
                    loading={ loadingSave }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreatePrtModal;
