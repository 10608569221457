import { useMutation } from '@apollo/client';
import { TaskStatusFormFields } from 'page/TaskStatus/components/formData';
import {
    CREATE_TASK_STATUS, CreateTaskStatusResponseT,
    CreateTaskStatusT,
    CreateTaskStatusVarT, EMPTY_CREATE_TASK_STATUS_RESPONSE,
} from 'shared/graphql/mutation/taskStatus/TaskStatusCreate';

import { CreateTaskStatusDataT } from './CreateTaskStatusData';

const useTaskStatusCreate = (): CreateTaskStatusDataT => {
    const [create, { loading, error }] = useMutation<CreateTaskStatusT, CreateTaskStatusVarT>(
        CREATE_TASK_STATUS,
    );

    const createTaskStatus = async (input: TaskStatusFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        category: input.category,
                        name: input.name,
                        position: input.position,
                    },
                },
            });

            return data?.adminTaskStatusCreate as CreateTaskStatusResponseT;
        } catch {
            return EMPTY_CREATE_TASK_STATUS_RESPONSE;
        }
    };

    return {
        createTaskStatus,
        loading,
        error,
    };
};

export default useTaskStatusCreate;
