import { useEffect } from 'react';
import usePaymentDocument from 'shared/data-hook/paymentDocument/fetch/fetchOne/usePaymentDocument';
import { useStore } from 'store';

const useFetchPaymentDocument = (id: string) => {
    const { fetchPaymentDocument, loading } = usePaymentDocument();
    const { setPaymentDocument } = useStore('updatePaymentDocumentModal');

    useEffect(() => {
        if (id) {
            fetchPaymentDocument(id).then((paymentDocument) => {
                setPaymentDocument(paymentDocument);
            });
        }
    }, [id]);

    return { loading };
};

export default useFetchPaymentDocument;
