import { useEffect } from 'react';
import useTaskStatus from 'shared/data-hook/taskStatus/fetch/fetchOne/useTaskStatus';
import { useStore } from 'store';

const useFetchTaskStatus = (id: string) => {
    const { fetchTaskStatus, loading } = useTaskStatus();
    const { setTaskStatus } = useStore('updateTaskStatusModal');

    useEffect(() => {
        fetchTaskStatus(id).then((fetchedTaskStatus) => {
            setTaskStatus(fetchedTaskStatus);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchTaskStatus;
