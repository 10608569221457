import { action, makeObservable, observable } from 'mobx';
import { EMPTY_IMPLEMENTATION_RESULT, ImplementationT } from 'types/Implementation';

import BaseModalStore from './BaseModalStore';

export default class UpdateImplementationModal extends BaseModalStore {
    public implementation: ImplementationT = EMPTY_IMPLEMENTATION_RESULT;

    public implementationsByDocument: ImplementationT[] = [];

    public setImplementation(implementation: ImplementationT) {
        this.implementation = implementation;
    }

    public setImplementationsByDocument(implementationsByDocument: ImplementationT[]) {
        this.implementationsByDocument = implementationsByDocument;
    }

    constructor() {
        super();

        makeObservable(this, {
            implementation: observable,
            setImplementation: action.bound,
            implementationsByDocument: observable,
            setImplementationsByDocument: action.bound,
        });
    }
}
