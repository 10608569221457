import { gql } from '@apollo/client';

export type ProviderIncomeFields = {
    coach_id: string | null;
    operation: string | null;
    organization_id: string | null;
    place: string | null;
    price_type_id: string | null;
    service: string | null;
    income_in_bonus: number | null;
    income_in_fiat: number | null;
    covered_at: Date | null;
    datetime: Date | null;
    datetime_operation: Date;
    payed_at: Date | null;
}

export type ImplementationFields = {
    is_made_by_club: boolean;
    cancel_id: string | null;
    cashback_bonus_payment_document_id: string | null;
    cashback_fiat_payment_document_id: string | null;
    coach_id: string | null;
    court_id: string | null;
    group_id: string | null;
    operation: string | null;
    performer: string | null;
    place: string | null;
    price_sales_round_id: string | null;
    price_type_id: string | null;
    service: string;
    service_category_id: string | null;
    transfer_id: string | null;
    user_id: string | null;
    discount_percent: number | null;
    duration: number;
    guests_amount: number | null;
    cashback_bonus_amount: number;
    cashback_fiat_amount: number;
    discount: number | null;
    payment_in_bonus: number | null;
    payment_in_fiat: number | null;
    performer_income_bonus: number;
    performer_income_fiat: number;
    rate: number | null;
    total: number | null;
    comment: string | null;
    document: string;
    title: string | null;
    datetime: Date;
    datetime_operation: Date | null;
};

export type MultipleImplementationsUpdateVar = {
    updates: Array<{
        where: { id: { _eq: string } };
        _set: ImplementationFields;
    }>;
    providerIncomeUpdates: Array<{
        where: { id: { _eq: string } };
        _set: ProviderIncomeFields;
    }>;
};

export type MultipleImplementationsUpdateResponseDataT = {
    update_implementation_many: {
        affected_rows: number;
    };
    update_provider_income_many: {
        affected_rows: number;
    };
} | null | undefined;

export const IMPLEMENTATIONS_MULTIPLE_UPDATE = gql`
   mutation updateMultipleImplementations(
    $updates: [implementation_updates!]!
    $providerIncomeUpdates: [provider_income_updates!]!
   ) {
    update_implementation_many(updates: $updates) {
        affected_rows
    }
    update_provider_income_many(updates: $providerIncomeUpdates) {
        affected_rows
    }
}
`;
