import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { TASK_TYPE_HEADER_DATA } from '../domain/HeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const TaskTypeList: FC<PropsT> = observer((props) => {
    const {
        sortModel,
        filterModel,
        page,
        pageSize,
        loading,
        setPageSize,
        setPage,
        setSortModel,
        setFilterModel,
    } = props;
    const { taskTypes, count } = useStore('taskType');

    return (
        <DataGrid
            rows={ taskTypes }
            rowCount={ count }
            columns={ TASK_TYPE_HEADER_DATA }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
            rowAutoHeight={ true }
        />
    );
});

export default TaskTypeList;
