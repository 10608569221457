import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    IMPLEMENTATIONS_BY_DOCUMENT_QUERY,
    ImplementationsByDocumentResponseT,
} from 'shared/graphql/query/implementationsByDocument/ImplementationsByDocumentQuery';
import {
    EMPTY_IMPLEMENTATIONS_BY_DOCUMENT_RESULT,
    ImplementationT,
} from 'types/Implementation';

import { ImplementationsByDocumentDTO } from '../DTO/ImplementationsByDocumentDTO';

import { ImplementationsByDocumentDataT } from './ImplementationsData';

const useImplementationsByDocument = (): ImplementationsByDocumentDataT => {
    const [fetch, { loading, error }] = useLazyQuery<ImplementationsByDocumentResponseT>(
        IMPLEMENTATIONS_BY_DOCUMENT_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchImplementationsByDocument = async (id: string): Promise<ImplementationT[]> => {
        try {
            const { data } = await fetch({
                variables: { id },
            });

            return decodeDTO(
                ImplementationsByDocumentDTO,
                EMPTY_IMPLEMENTATIONS_BY_DOCUMENT_RESULT,
                (implementations) => implementations,
                data?.implementation_document,
            );
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error((err as Error).message); // TODO: use Sentry
            throw err;
        }
    };

    return {
        fetchImplementationsByDocument,
        loading,
        error,
    };
};

export default useImplementationsByDocument;
