import {
    ERROR_AGE_UPDATE, SUCCESS_AGE_UPDATE,
} from 'constants/notificationMessage';

import { AgeFormFields } from 'page/Age/components/formData';
import useAgeUpdate from 'shared/data-hook/age/update/useAgeUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useUpdateAge = () => {
    const { error, ageUpdate, loading } = useAgeUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const updateAge = async (data: AgeFormFields, id: string) => {
        const response = await ageUpdate(data, id);

        if (error || !response) {
            showError(ERROR_AGE_UPDATE);

            return false;
        }

        showSuccess(SUCCESS_AGE_UPDATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        updateAge,
        loading,
    };
};

export default useUpdateAge;
