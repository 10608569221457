import { makeAutoObservable } from 'mobx';
import { PricesByServiceCategoryT } from 'types/PricesByServiceCategory';

export default class PricesByServiceCategoryStore {
    public pricesByServiceCategoryList: PricesByServiceCategoryT[] = [];

    public pricesByServiceCategory: PricesByServiceCategoryT | undefined = undefined;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get serviceCategories(): PricesByServiceCategoryT[] {
        return this.pricesByServiceCategoryList;
    }

    public setPricesByServiceCategories(pricesByServiceCategories: PricesByServiceCategoryT[], count?: number) {
        this.pricesByServiceCategoryList = pricesByServiceCategories;
        this.count = count ?? 0;
    }
}
