import React, { FC, ReactNode } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import BaseCheckbox from 'shared/component/Form/Checkbox';
import BaseDatePicker from 'shared/component/Form/DatePicker';
import BaseDateTimePicker from 'shared/component/Form/DateTimePicker';
import BaseSelect from 'shared/component/Form/Select';
import BaseSelectWithSearch from 'shared/component/Form/SelectWithSearch';
import BaseTextField from 'shared/component/Form/TextField';
import { OptionT } from 'types/Option';

import { ImplementationFormFields } from './formData';

type TextFieldPropsT = {
    name: string;
    label: string;
    type?: string;
    multiline?: boolean;
    control: Control<ImplementationFormFields>;
};

type CheckboxPropsT = {
    name: string;
    label: string;
    control: Control<ImplementationFormFields>;
    loading?: boolean;
};

type DatePickerPropsT = {
    name: string;
    label: string;
    date: Date | null;
    control: Control<ImplementationFormFields>;
    setValue: UseFormSetValue<ImplementationFormFields>;
    readOnly?: boolean;
};

type SelectPropsT = {
    label: string;
    name: string;
    loading?: boolean;
    options: OptionT[];
    control: Control<ImplementationFormFields>;
    empty?: boolean;
    value?: string;
};

type SelectWithSearchPropsT = {
    label: string;
    name: string;
    options: OptionT[];
    control: Control<ImplementationFormFields>;
    value?: string;
    empty?: boolean;
};

type FieldWrapPropsT = {
    label: string;
    children: ReactNode;
};

export const FieldWrap: FC<FieldWrapPropsT> = ({ label, children }) => {
    return (
        <Grid2
            sx={ {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2,
            } }
        >
            <Grid2 xs={ 4 }>
                <Typography variant="body1">{ label }</Typography>
            </Grid2>
            <Grid2 sx={ { width: 250 } }>
                { children }
            </Grid2>
        </Grid2>
    );
};

export const TextField: FC<TextFieldPropsT> = ({
    name, label, type = 'text', control, multiline = false,
}) => {
    return (
        <FieldWrap label={ label }>
            <BaseTextField fullWidth size="small" name={ name } type={ type } control={ control } multiline={ multiline } />
        </FieldWrap>
    );
};

export const Checkbox: FC<CheckboxPropsT> = ({
    name, label, control, loading,
}) => {
    return (
        <Grid2
            xs={ 6 }
            sx={ {
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'flex-end',
            } }
        >
            <BaseCheckbox
                control={ control }
                name={ name }
                label={ label }
                loading={ loading }
            />
        </Grid2>
    );
};

export const DatePicker: FC<DatePickerPropsT> = ({
    name, label, control, setValue, date, readOnly = false,
}) => {
    return (
        <FieldWrap label={ label }>
            <BaseDatePicker
                fullWidth
                setValue={ setValue }
                value={ dayjs(date) }
                control={ control }
                name={ name }
                readOnly={ readOnly }
            />
        </FieldWrap>
    );
};

export const DateTimePicker: FC<DatePickerPropsT> = ({
    name, label, control, setValue, date, readOnly = false,
}) => {
    return (
        <FieldWrap label={ label }>
            <BaseDateTimePicker
                fullWidth
                setValue={ setValue }
                value={ dayjs(date) }
                control={ control }
                name={ name }
                readOnly={ readOnly }
            />
        </FieldWrap>
    );
};

export const Select: FC<SelectPropsT> = ({
    label, options, control, name, loading, empty = true, value,
}) => {
    return (
        <FieldWrap label={ label }>
            <BaseSelect
                name={ name }
                options={ options }
                control={ control }
                loading={ loading }
                empty={ empty }
                value={ value }
            />
        </FieldWrap>
    );
};

export const SelectWithSearch: FC<SelectWithSearchPropsT> = ({
    label, options, control, name, value, empty = true,
}) => {
    return (
        <FieldWrap label={ label }>
            <BaseSelectWithSearch
                control={ control }
                options={ options }
                name={ name }
                label={ label }
                size="small"
                selectedValue={ value ?? '' }
                empty={ empty }
            />
        </FieldWrap>
    );
};
