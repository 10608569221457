import { PROPERTY_BASIC_SALE_RATE, PROPERTY_TITLE } from 'constants/propertyNames';

import { number, object, string } from 'yup';

export type CoachCategoryFormFields = {
    id: string;
    name: string;
    basicRate: number;
}

export enum COACH_CATEGORY_FIELD_KEYS {
    name = 'name',
    basicRate = 'basicRate',
}

export const COACH_CATEGORY_DEFAULT_VALUES = {
    name: '',
    basicRate: 0,
};

export const COACH_CATEGORY_LABEL_DATA = {
    name: PROPERTY_TITLE,
    basicRate: PROPERTY_BASIC_SALE_RATE,
};

export const COACH_CATEGORY_VALIDATION_SCHEMA = object().shape({
    name: string().required(),
    basicRate: number().required(),
});
