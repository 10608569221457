import { OptionT } from '../types/Option';

export const DEFAULT_LOCALE = 'ru';

export const LOCALE_SELECT_OPTIONS: OptionT[] = [
    {
        value: 'ru',
        label: 'RU',
    },
    {
        value: 'en',
        label: 'EN',
    },
];
