export type TaskStatusT = {
    id: string;
    category: string;
    name: string;
    position: number;
};

export type TaskStatusesT = {
    taskStatuses: TaskStatusT[];
    taskStatusesCount: number;
};

export const EMPTY_TASK_STATUSES_RESULT: TaskStatusesT = {
    taskStatuses: [],
    taskStatusesCount: 0,
};

export const EMPTY_TASK_STATUS_RESULT: TaskStatusT = {
    id: '',
    category: '',
    name: '',
    position: 0,
};
