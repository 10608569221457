import { Controller, FieldValues } from 'react-hook-form';
import {
    Checkbox as BaseCheckbox, FormControlLabel, Skeleton,
} from '@mui/material';

type PropsT = {
    label?: string;
    loading?: boolean;
};

const Checkbox = <TFieldValues extends FieldValues>(props: PropsT & TFieldValues) => {
    const {
        control, name, label, loading,
    } = props;

    return (
        <Controller
            name={ name }
            control={ control }
            render={ ({ field }) => (
                loading ? <Skeleton variant="rounded" width={ 150 } height={ 42 } />
                    : (
                        <FormControlLabel
                            label={ label }
                            control={ <BaseCheckbox checked={ field.value } { ...field } /> }
                        />
                    )
            ) }
        />
    );
};

export default Checkbox;
