import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { GROUP_VIEWS_QUERY, GroupViewsResponseT } from 'shared/graphql/query/groupView/GroupViewQuery';
import { FetchDataParams, SearchT } from 'types/DataGrid';
import { EMPTY_GROUP_VIEWS } from 'types/GroupView';

import { GroupViewsDTO } from '../DTO/GroupViewsDTO';
import { getFullNameFilter } from '../filter/getFullNameFilter';

import { GroupViewsDataT } from './GroupViewsData';

const useGroupViews = (): GroupViewsDataT => {
    const [fetch, { data: groupViewsData, loading }] = useLazyQuery<GroupViewsResponseT>(
        GROUP_VIEWS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedGroupViewsData = decodeDTO(
        GroupViewsDTO,
        EMPTY_GROUP_VIEWS,
        (groupViews) => groupViews,
        {
            groupViews: groupViewsData?.groupViews,
            groupViewsCount: groupViewsData?.groupViewsCount,
        },
    );

    const fetchGroupViews = async ({
        limit,
        offset,
        orderBy = { name: 'asc' },
        search = { name: '' },
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams<SearchT> = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: {
                        ...filterData(where),
                        ...getFullNameFilter(search),
                    },
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchGroupViews,
        loading,
        data: decodedGroupViewsData?.groupViews || [],
        count: decodedGroupViewsData?.groupViewsCount,
    };
};

export default useGroupViews;
