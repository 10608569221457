import { gql } from '@apollo/client';
import { TaskTypeT } from 'types/TaskType';

export type TaskTypeDeleteResponseDataT = {
    deleteTaskType: {
        id: TaskTypeT['id'];
    };
} | null | undefined;

export type TaskTypeDeleteVarT = {
    id: TaskTypeT['id'];
};

export const TASK_TYPE_DELETE = gql`
    mutation DeleteTaskType($id: String = "") {
        deleteTaskType: delete_task_type_by_pk(id: $id) {
            id
        }
    }
`;
