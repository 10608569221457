import { useEffect } from 'react';
import useAge from 'shared/data-hook/age/fetch/fetchOne/useAge';
import { useStore } from 'store';

const useFetchAge = (id: string) => {
    const { fetchAge, loading } = useAge();
    const { setAge } = useStore('updateAgeModal');

    useEffect(() => {
        fetchAge(id).then((fetchedAge) => {
            setAge(fetchedAge);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchAge;
