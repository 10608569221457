import { gql } from '@apollo/client';
import { CoachCategoryT } from 'types/CoachCategory';

export type CoachCategoriesResponseT = {
    coachCategories: CoachCategoryT[];
    coachCategoriesCount: {
        count: number;
    };
};

export type CoachCategoryResponseT = {
    coachCategory: CoachCategoryT;
}

export const COACH_CATEGORY_FILTER_OPTIONS_QUERY = gql`
    query CoachCategoryFilterOptions {
        filterOptions: coach_category(
            order_by: { name: asc }
        ) {
            value: id,
            label: name
        }
    }
`;

export const COACH_CATEGORIES_QUERY = gql`
    query CoachCategories(
        $limit: Int,
        $offset: Int,
        $orderBy: coach_category_order_by! = {},
        $where: coach_category_bool_exp! = {}
    ) {
        coachCategories: coach_category(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            name
            coach_basic_sale_rate
            coaches (order_by: { employee: { full_name: asc } }) {
                id
                employee { full_name }
                color
                active
                work_days
                coach_category: category_id
                service_app: service_app_id
            }
        }
        coachCategoriesCount: coach_category_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const COACH_CATEGORY_QUERY = gql`
    query CoachCategory($id: String!) {
        coachCategory: coach_category_by_pk(id: $id) {
            id
            name
            coach_basic_sale_rate
        }
    }
`;
