import { EMPTY_CELL } from 'constants/dataGrid';
import {
    PROPERTY_ACTIONS,
    PROPERTY_CAMERA_ID, PROPERTY_ORDER, PROPERTY_ORGANIZATION_LOCATION, PROPERTY_PROMO_CATEGORY, PROPERTY_SCHEDULE,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Chip, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import { FlexWrapBox } from 'shared/component/DataGrid/domain/Styled';
import useDeleteCourt from 'shared/hook/court/delete/useDeleteCourt';
import { filterOperators } from 'shared/utils/headerData/filterOperators';
import { getDayByNumber } from 'shared/utils/headerData/getDayByNumber';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { useStore } from 'store';
import { CourtScheduleItem } from 'types/Court';
import { CustomHeaderData } from 'types/DataGrid';

const renderSchedule: FC<GridRenderCellParams> = (props) => {
    const { row } = props;

    if (row.schedule.length === 0) {
        return <span>{ EMPTY_CELL }</span>;
    }

    return (
        <FlexWrapBox>
            {
                row.schedule.map((period: CourtScheduleItem) => (
                    <Chip label={ `${getDayByNumber(period.day)} | ${period.start} - ${period.end}` } variant="outlined" color="primary" size="small" key={ `${row.id}-${period.day}` } />
                ))
            }
        </FlexWrapBox>
    );
};

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, title } } = props;
    const { courtDelete } = useDeleteCourt();
    const { onOpen } = useStore('updateCourtModal');

    return (
        <>
            <IconButton onClick={ () => onOpen(id) }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ id } title={ title } actionDelete={ courtDelete } />
        </>
    );
};

export const COURT_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_ORDER,
        field: 'order',
        align: 'center',
        headerAlign: 'center',
        flex: 0.3,
        type: 'number',
    },
    {
        headerName: PROPERTY_PROMO_CATEGORY,
        field: 'promoCategory',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_CAMERA_ID,
        field: 'cameraId',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_SCHEDULE,
        field: 'schedule',
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['schedule', '_cast', 'String'],
        filterOperators,
        renderCell: renderSchedule,
    },
    {
        headerName: PROPERTY_ORGANIZATION_LOCATION,
        field: 'organizationLocation',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        renderCell: (params) => renderStringCell(params.row?.organizationLocation.name),
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
    },
];
