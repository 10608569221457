import { useMutation } from '@apollo/client';
import { CourtFormFields } from 'page/Court/components/CourtSetting/components/formData';
import { CreateCourtDataT } from 'shared/data-hook/court/create/CreateCourtData';
import { COURT_CREATE, CourtCreateResponseDataT, CourtCreateVarT } from 'shared/graphql/mutation/court/CourtCreate';

const useCourtCreate = (): CreateCourtDataT => {
    const [create, { loading, error }] = useMutation<CourtCreateResponseDataT, CourtCreateVarT>(
        COURT_CREATE,
    );

    const createAction = async (input: CourtFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        name: input.name,
                        order: input.order,
                        schedule: input.schedule,
                        organization_location_id: input.organizationLocation,
                        bonus_classes: input.bonusClasses,
                        organization_id: input.organization,
                        type_id: input.courtType,
                        camera_id: input.cameraId || null,
                        enabled_at_months: input.enabledAtMonths,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error((errorCreate as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        loading,
        error,
        courtCreate: createAction,
    };
};

export default useCourtCreate;
