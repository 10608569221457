import { OptionsType } from 'shared/component/Form/MultipleSelect';

export const MONTHS_LIST: OptionsType[] = [
    {
        value: '01',
        label: 'Январь',
    },
    {
        value: '02',
        label: 'Февраль',
    },
    {
        value: '03',
        label: 'Март',
    },
    {
        value: '04',
        label: 'Апрель',
    },
    {
        value: '05',
        label: 'Май',
    },
    {
        value: '06',
        label: 'Июнь',
    },
    {
        value: '07',
        label: 'Июль',
    },
    {
        value: '08',
        label: 'Август',
    },
    {
        value: '09',
        label: 'Сентябрь',
    },
    {
        value: '10',
        label: 'Октябрь',
    },
    {
        value: '11',
        label: 'Ноябрь',
    },
    {
        value: '12',
        label: 'Декабрь',
    },
];
