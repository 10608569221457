import { useMutation } from '@apollo/client';
import { CourtFormFields } from 'page/Court/components/CourtSetting/components/formData';
import { CourtUpdateDataT } from 'shared/data-hook/court/update/CourtUpdateData';
import {
    COURT_UPDATE,
    CourtUpdateResponseDataT,
    CourtUpdateVarT,
} from 'shared/graphql/mutation/court/CourtUpdate';
import { CourtT } from 'types/Court';

const useCourtUpdate = (): CourtUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        CourtUpdateResponseDataT,
        CourtUpdateVarT
    >(COURT_UPDATE);

    const updateAction = async (input: CourtFormFields, courtId: CourtT['id']) => {
        if (!courtId) {
            // TODO: use Sentry
            throw new Error('CourtSetting is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: courtId,
                    set: {
                        name: input.name,
                        order: input.order,
                        schedule: input.schedule,
                        organization_location_id: input.organizationLocation,
                        bonus_classes: input.bonusClasses,
                        organization_id: input.organization,
                        type_id: input.courtType,
                        camera_id: input.cameraId || null,
                        enabled_at_months: input.enabledAtMonths,
                        promo_category: input.promoCategory || null,
                    },
                },
            });

            return data;
            // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        courtUpdate: updateAction,
        loading,
        error,
    };
};

export default useCourtUpdate;
