import { useEffect } from 'react';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';
import { addParamsToFilterModel } from 'shared/utils/addParamsToFilterModel';
import { addParamsToSortModel } from 'shared/utils/addParamsToSortModel';
import { CustomHeaderData } from 'types/DataGrid';

const useFetchDataForGrid = (
    sortModel: GridSortModel,
    filterModel: GridFilterModel,
    page: number,
    pageSize: number,
    fetch: Function,
    trigger: boolean,
    columns: CustomHeaderData[],
    search?: Record<string, any>,
) => {
    useEffect(() => {
        fetch({
            limit: pageSize,
            offset: page === 0 ? 0 : page * pageSize,
            orderBy: addParamsToSortModel(sortModel, columns),
            where: addParamsToFilterModel(filterModel, columns),
            search,
        }).then();
    }, [sortModel, filterModel, page, pageSize, trigger, search]);
};

export default useFetchDataForGrid;
