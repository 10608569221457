import { gql } from '@apollo/client';
import { AgeT } from 'types/Age';
import { AggregateT } from 'types/Graphql';

export type AgesResponseT = {
    ages: AgeT[];
    agesCount: AggregateT;
};

export type AgeResponseT = {
    age: AgeT;
};

export const AGE_QUERY = gql`
    query Age($id: String!) {
        age: age_by_pk(id: $id) {
            id
            name
            value
        }
    }
`;

export const AGES_QUERY = gql`
    query Age(
        $limit: Int
        $offset: Int
        $orderBy: age_order_by! = {}
        $where: age_bool_exp! = {}
    ) {
        ages: age(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            name
            value
        }
        agesCount: age_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
