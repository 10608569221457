import { action, makeObservable, observable } from 'mobx';
import { EMPTY_PRT_RESULT, PrtT } from 'types/Prt';

import BaseModalStore from './BaseModalStore';

export default class UpdatePrtModal extends BaseModalStore {
    public prt: PrtT = EMPTY_PRT_RESULT;

    public setPrt(prt: PrtT) {
        this.prt = prt;
    }

    constructor() {
        super();

        makeObservable(this, {
            prt: observable,
            setPrt: action.bound,
        });
    }
}
