import { gql } from '@apollo/client';
import { AcquiringSettingT } from 'types/AcquiringSetting';

export type AcquiringSettingDeleteResponseDataT = {
    deleteAcquiringSetting: {
        id: AcquiringSettingT['id'];
    };
} | null | undefined;

export type AcquiringSettingDeleteVarT = {
    id: AcquiringSettingT['id'];
};

export const ACQUIRING_SETTING_DELETE = gql`
    mutation DeleteAcquiringSetting($id: String = "") {
        deleteAcquiringSetting: delete_service_app_acquiring_setting_by_pk(id: $id) {
            id
        }
    }
`;
