import { OPERATIONS_OPTIONS } from 'constants/operations';
import {
    PROPERTY_COACH, PROPERTY_COVERED_AT_DATE, PROPERTY_DATETIME, PROPERTY_DATETIME_OPERATION,
    PROPERTY_INCOME_IN_BONUS, PROPERTY_INCOME_IN_FIAT,
    PROPERTY_OPERATION, PROPERTY_ORGANIZATION, PROPERTY_PAYED_AT_DATE, PROPERTY_PERFORMER_REWARD,
    PROPERTY_SERVICE,
} from 'constants/propertyNames';
import { SERVICES_OPTIONS } from 'constants/services';

import React, { FC } from 'react';
import { Control, useFieldArray, UseFormSetValue } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import { useStore } from 'store';
import { ProviderIncomeT } from 'types/ProviderIncome';

import { ImplementationFormFields } from './formData';
import { DatePicker, Select, TextField } from './inputs';

type PropsT = {
    control: Control<ImplementationFormFields>;
    setValue: UseFormSetValue<ImplementationFormFields>;
    index: number;
};

const ProviderIncomes: FC<PropsT> = observer((props) => {
    const { control, setValue, index } = props;
    const { getFilterOption } = useStore('filterOptions');
    const organizations = getFilterOption('organization');
    const coaches = getFilterOption('employee');

    const { fields } = useFieldArray<ImplementationFormFields>({
        name: `implementations.${index}.providerIncomes`,
        control,
    });

    return (
        <Grid2 container direction="column" spacing={ 1 }>
            <Grid2 rowSpacing={ 4 }>
                { fields.map((field, providerIndex) => (
                    <Grid2 container direction="column" rowSpacing={ 1 } key={ field.id }>
                        <Grid2>
                            <Typography variant="subtitle2">{ PROPERTY_PERFORMER_REWARD }</Typography>
                        </Grid2>
                        <Grid2 container direction="row" rowSpacing={ 1 }>
                            <Grid2 xs={ 6 }>
                                <Select
                                    label={ PROPERTY_COACH }
                                    name={ `implementations.${index}.providerIncomes.${providerIndex}.coachId` }
                                    options={ coaches }
                                    control={ control }
                                />
                                <Select
                                    label={ PROPERTY_OPERATION }
                                    name={ `implementations.${index}.providerIncomes.${providerIndex}.operation` }
                                    options={ OPERATIONS_OPTIONS }
                                    control={ control }
                                />
                                <Select
                                    label={ PROPERTY_ORGANIZATION }
                                    name={ `implementations.${index}.providerIncomes.${providerIndex}.organizationId` }
                                    options={ organizations }
                                    control={ control }
                                />
                                <Select
                                    label={ PROPERTY_SERVICE }
                                    name={ `implementations.${index}.providerIncomes.${providerIndex}.service` }
                                    options={ SERVICES_OPTIONS }
                                    control={ control }
                                />
                                <TextField
                                    name={ `implementations.${index}.providerIncomes.${providerIndex}.incomeInBonus` }
                                    label={ PROPERTY_INCOME_IN_BONUS }
                                    type="number"
                                    control={ control }
                                />
                            </Grid2>
                            <Grid2 xs={ 6 }>
                                <TextField
                                    name={ `implementations.${index}.providerIncomes.${providerIndex}.incomeInFiat` }
                                    label={ PROPERTY_INCOME_IN_FIAT }
                                    type="number"
                                    control={ control }
                                />
                                <DatePicker
                                    name={ `implementations.${index}.providerIncomes.${providerIndex}.coveredAt` }
                                    label={ PROPERTY_COVERED_AT_DATE }
                                    control={ control }
                                    setValue={ setValue }
                                    date={ (field as ProviderIncomeT).coveredAt }
                                />
                                <DatePicker
                                    name={ `implementations.${index}.providerIncomes.${providerIndex}.datetime` }
                                    label={ PROPERTY_DATETIME }
                                    control={ control }
                                    setValue={ setValue }
                                    date={ (field as ProviderIncomeT).datetime }
                                />
                                <DatePicker
                                    name={ `implementations.${index}.providerIncomes.${providerIndex}.datetimeOperation` }
                                    label={ PROPERTY_DATETIME_OPERATION }
                                    control={ control }
                                    setValue={ setValue }
                                    date={ (field as ProviderIncomeT).datetimeOperation }
                                    readOnly={ true }
                                />
                                <DatePicker
                                    name={ `implementations.${index}.providerIncomes.${providerIndex}.payedAt` }
                                    label={ PROPERTY_PAYED_AT_DATE }
                                    control={ control }
                                    setValue={ setValue }
                                    date={ (field as ProviderIncomeT).payedAt }
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                )) }
            </Grid2>
        </Grid2>
    );
});

export default ProviderIncomes;
