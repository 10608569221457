import { TIME_24_HOURS_15_STEP } from 'constants/hours';
import { MODAL_TITLE_UPDATE_COACH } from 'constants/modalTitles';
import { PROPERTY_END, PROPERTY_START } from 'constants/propertyNames';
import { WEEK_DAYS_VALUE_OPTIONS } from 'constants/weekdays';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Skeleton, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import { COACH_CATEGORY_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/coachCategory/CoachCategoryQuery';
import useFetchCoach from 'shared/hook/coach/fetch/fetchOne/useFetchCoach';
import useUpdateCoach from 'shared/hook/coach/update/useUpdateCoach';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import { useStore } from 'store';

import {
    COACH_DEFAULT_VALUES,
    COACH_FIELD_KEYS,
    COACH_LABEL_DATA,
    COACH_VALIDATION_SCHEMA,
    CoachFormFields,
} from './formData';

const UpdateCoachModal: FC = observer(() => {
    const {
        isOpen, onClose, itemId: coachId, coach,
    } = useStore('updateCoachModal');

    const { coachCategoryList } = useStore('coachCategory');
    const { loading: loadingFetch } = useFetchCoach(coachId);
    const { getFilterOption } = useStore('filterOptions');
    const { updateCoach, loading: loadingUpdating } = useUpdateCoach();
    const coachCategory = getFilterOption('coachCategory');
    const { loading: loadingCoachCategory, refetch } = useFetchFilterOptions(COACH_CATEGORY_FILTER_OPTIONS_QUERY, 'coachCategory');

    const {
        control, handleSubmit, reset, formState: { isValid, isDirty }, setValue,
    } = useForm<CoachFormFields>({
        defaultValues: COACH_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(COACH_VALIDATION_SCHEMA),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateCoach = (data: CoachFormFields) => {
        updateCoach(data, coachId).then(handleCloseModal);
    };

    useEffect(() => {
        if (!coach) return;

        Object.keys(coach).forEach((key) => {
            setValue(key as keyof CoachFormFields, coach[key as keyof CoachFormFields as never]);
        });
    }, [coach]);

    useEffect(() => {
        refetch?.();
    }, [coachCategoryList]);

    return (
        <Modal open={ isOpen } fullWidth={ true } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { loadingFetch ? <Skeleton height={ 32 } /> : `${MODAL_TITLE_UPDATE_COACH} ${coach?.name}` }
            </ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch ? <Spinner /> : (
                    <Grid2 spacing={ 2 } container padding={ 2 } alignItems="center">
                        <Grid2 xs={ 12 }>
                            <Select
                                control={ control }
                                name={ COACH_FIELD_KEYS.categoryId }
                                label={ COACH_LABEL_DATA.coachCategoryId }
                                loading={ loadingCoachCategory }
                                options={ coachCategory }
                            />
                        </Grid2>
                        <Grid2>
                            <TextField
                                size="small"
                                control={ control }
                                name={ COACH_FIELD_KEYS.color }
                                label={ COACH_LABEL_DATA.color }
                                type="text"
                            />
                        </Grid2>
                        <Grid2>
                            <Switch
                                label={ COACH_LABEL_DATA.active }
                                name={ COACH_FIELD_KEYS.active }
                                control={ control }
                                type="boolean"
                            />
                        </Grid2>
                        { WEEK_DAYS_VALUE_OPTIONS.map((day, index) => (
                            <Grid2 xs={ 12 } key={ day.value } container>
                                <Grid2 xs={ 2 } display="flex" alignItems="center" pt="30px">
                                    <Typography>{ day.label }</Typography>
                                </Grid2>
                                <Grid2 xs={ 5 }>
                                    <Select
                                        label={ PROPERTY_START }
                                        name={ `workDays.${index}.start` }
                                        options={ TIME_24_HOURS_15_STEP }
                                        control={ control }
                                        empty={ true }
                                    />
                                </Grid2>
                                <Grid2 xs={ 5 }>
                                    <Select
                                        label={ PROPERTY_END }
                                        name={ `workDays.${index}.end` }
                                        options={ TIME_24_HOURS_15_STEP }
                                        control={ control }
                                        empty={ true }
                                    />
                                </Grid2>
                            </Grid2>
                        )) }
                    </Grid2>
                ) }
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ loadingUpdating }
                    onClick={ handleSubmit(handleUpdateCoach) }
                    disabled={ !isValid || !isDirty }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateCoachModal;
