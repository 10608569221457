import {
    PROPERTY_ACTIONS,
    PROPERTY_COMMENT,
    PROPERTY_DESCRIPTION,
    PROPERTY_DURATION,
    PROPERTY_IS_ACTIVE,
    PROPERTY_POSITION,
    PROPERTY_TITLE, PROPERTY_TITLE_BY_DEFAULT,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import useDeleteTaskType from 'shared/hook/taskType/delete/useDeleteTaskType';
import RenderLongStringCell from 'shared/utils/headerData/renderLongStringCell';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { CustomHeaderData } from 'types/DataGrid';

import TaskTypeEditButton from '../components/TaskTypeEditButton';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const {
        row: { id, title },
    } = props;
    const { taskTypeDelete } = useDeleteTaskType();

    return (
        <>
            <TaskTypeEditButton id={ id } />
            <DeleteButton id={ id } title={ title } actionDelete={ taskTypeDelete } />
        </>
    );
};

export const TASK_TYPE_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_POSITION,
        field: 'position',
        minWidth: 70,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        headerName: PROPERTY_IS_ACTIVE,
        field: 'isActive',
        minWidth: 70,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
        renderCell: isActiveIcon,
    },
    {
        headerName: PROPERTY_COMMENT,
        field: 'defaultShouldCreateFinishComment',
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
        renderCell: isActiveIcon,
    },
    {
        headerName: PROPERTY_DURATION,
        field: 'defaultDurationHours',
        minWidth: 180,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        renderCell: (params) => renderStringCell(params.row.defaultDurationHours),
    },
    {
        headerName: PROPERTY_TITLE_BY_DEFAULT,
        field: 'defaultTitle',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params) => renderStringCell(params.row.defaultTitle),
    },
    {
        headerName: PROPERTY_DESCRIPTION,
        field: 'defaultDescription',
        minWidth: 400,
        flex: 1,
        align: 'left',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params) => RenderLongStringCell(params.row.defaultDescription),
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
