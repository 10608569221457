import { MODAL_TITLE_CREATE_TASK_STATUS } from 'constants/modalTitles';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreateTaskStatus from 'shared/hook/taskStatus/create/useCreateTaskStatus';
import { useStore } from 'store';

import {
    getTaskStatusValidationSchema, TASK_STATUS_DEFAULT_VALUES,
    TaskStatusFormFields,
} from './formData';
import TaskStatusForm from './TaskStatusForm';

const CreateTaskStatusModal: FC = observer(() => {
    const {
        isOpen, onClose,
    } = useStore('createTaskStatusModal');
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const { createTaskStatus, loading: loadingSave } = useCreateTaskStatus();

    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<TaskStatusFormFields>({
        defaultValues: TASK_STATUS_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getTaskStatusValidationSchema()),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreateTaskStatus = (data: TaskStatusFormFields) => {
        createTaskStatus(data).then(() => {
            handleCloseModal();
            toggleRefetchTrigger();
        });
    };

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_CREATE_TASK_STATUS }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <TaskStatusForm
                    control={ control }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                <Button variant="outlined" onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleCreateTaskStatus) }
                    autoFocus
                    disabled={ !isDirty || !isValid }
                    loading={ loadingSave }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateTaskStatusModal;
