import { ERROR_TASK_TYPE_DELETE, SUCCESS_TASK_TYPE_DELETE } from 'constants/notificationMessage';

import useTaskTypeDelete from 'shared/data-hook/taskType/delete/single/useTaskTypeDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { TaskTypeT } from 'types/TaskType';

const useDeleteTaskType = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteTaskType } = useTaskTypeDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const taskTypeDelete = async (taskTypeId: TaskTypeT['id']): Promise<boolean> => {
        const response = await deleteTaskType(taskTypeId);

        if (error || !response || !response.deleteTaskType) {
            showError(ERROR_TASK_TYPE_DELETE);

            return false;
        }

        showSuccess(SUCCESS_TASK_TYPE_DELETE);
        toggleRefetchTrigger();

        return true;
    };

    return { taskTypeDelete };
};

export default useDeleteTaskType;
