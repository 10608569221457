import { action, makeObservable, observable } from 'mobx';
import { AgeT, EMPTY_AGE_RESULT } from 'types/Age';

import BaseModalStore from './BaseModalStore';

export default class UpdateAgeModal extends BaseModalStore {
    public age: AgeT = EMPTY_AGE_RESULT;

    public setAge(age: AgeT) {
        this.age = age;
    }

    constructor() {
        super();

        makeObservable(this, {
            age: observable,
            setAge: action.bound,
        });
    }
}
