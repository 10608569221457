import React, { FC, useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import TextField from 'shared/component/Form/TextField';
import { AgeT } from 'types/Age';

import { AGE_FIELD_KEYS, AGE_LABEL_DATA, AgeFormFields } from './formData';

type PropsT = {
    control: Control<AgeFormFields>;
    setValue?: UseFormSetValue<AgeFormFields>;
    age?: AgeT;
    isUpdate?: boolean;
};

const AgeForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, age, isUpdate,
    } = props;

    useEffect(() => {
        if (isUpdate && age && setValue) {
            Object.keys(age).forEach((key) => {
                setValue(key as keyof AgeFormFields, age[key as keyof AgeFormFields as never]);
            });
        }
    }, [age, isUpdate]);

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ AGE_FIELD_KEYS.name }
                    label={ AGE_LABEL_DATA.name }
                    type="text"
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ AGE_FIELD_KEYS.value }
                    label={ AGE_LABEL_DATA.value }
                    type="number"
                />
            </Grid2>
        </Grid2>
    );
});

export default AgeForm;
