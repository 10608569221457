import { PROPERTY_PATTERN_PRICE_SALE_ROUND, PROPERTY_ROLE_PERMISSIONS, PROPERTY_TITLE_CONDITIONS_SALES } from 'constants/propertyNames';

import React, { FC, useEffect } from 'react';
import { Control, useController } from 'react-hook-form';
import { TabContext } from '@mui/lab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Grid2 from '@mui/material/Unstable_Grid2';
import dayjs, { Dayjs } from 'dayjs';
import DateTimePicker from 'shared/component/DateTimePicker';
import TextField from 'shared/component/Form/TextField';
import { COURT_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/court/CourtQuery';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import { useStore } from 'store';

import { dateModification } from '../domain/dateModification';

import {
    PRICE_SALES_ROUND_DEFAULT_VALUES, PRICE_SALES_ROUND_DESCRIPTION_KEYS, PRICE_SALES_ROUND_END_KEYS, PRICE_SALES_ROUND_HEAD_KEYS, PRICE_SALES_ROUND_LABEL_DATA, PRICE_SALES_ROUND_START_KEYS, PriceSalesRoundFormFields,
} from './formData';
import PriceSaleRoundRolePermissionForm from './PriceSaleRoundRolePermissionForm';
import PriceSalesRoundSettingsLine from './PriceSalesRoundSettingsLine';

type PropsT = {
    control: Control<PriceSalesRoundFormFields>;
    loading?: boolean;
    setDateError: Function;
};

type ModificationParamsT = [Dayjs | null, number, boolean, number, boolean, number, boolean, number, boolean]

const PriceSalesRoundForm: FC<PropsT> = (props) => {
    const { control, loading, setDateError } = props;
    const { getFilterOption } = useStore('filterOptions');
    const { loading: loadingCourt } = useFetchFilterOptions(COURT_FILTER_OPTIONS_QUERY, 'court');
    const courtFilterOptions = getFilterOption('court');

    const { field: startMonth } = useController({ control, name: 'startMonth' });
    const { field: startMonthFromStart } = useController({ control, name: 'startMonthFromStart' });
    const { field: startWeek } = useController({ control, name: 'startWeek' });
    const { field: startWeekFromStart } = useController({ control, name: 'startWeekFromStart' });
    const { field: startDay } = useController({ control, name: 'startDay' });
    const { field: startDayFromStart } = useController({ control, name: 'startDayFromStart' });
    const { field: startHour } = useController({ control, name: 'startHour' });
    const { field: startHourFromStart } = useController({ control, name: 'startHourFromStart' });
    const { field: endMonth } = useController({ control, name: 'endMonth' });
    const { field: endMonthFromStart } = useController({ control, name: 'endMonthFromStart' });
    const { field: endWeek } = useController({ control, name: 'endWeek' });
    const { field: endWeekFromStart } = useController({ control, name: 'endWeekFromStart' });
    const { field: endDay } = useController({ control, name: 'endDay' });
    const { field: endDayFromStart } = useController({ control, name: 'endDayFromStart' });
    const { field: endHour } = useController({ control, name: 'endHour' });
    const { field: endHourFromStart } = useController({ control, name: 'endHourFromStart' });

    dayjs.locale('ru');

    const [startSalesRound, setStartSalesRound] = React.useState<Dayjs | null>(
        dayjs(),
    );

    const startParams: ModificationParamsT = [
        startSalesRound,
        startMonth.value,
        startMonthFromStart.value,
        startWeek.value,
        startWeekFromStart.value,
        startDay.value,
        startDayFromStart.value,
        startHour.value,
        startHourFromStart.value,
    ];

    const finishParams: ModificationParamsT = [
        startSalesRound,
        endMonth.value,
        endMonthFromStart.value,
        endWeek.value,
        endWeekFromStart.value,
        endDay.value,
        endDayFromStart.value,
        endHour.value,
        endHourFromStart.value,
    ];

    const [tab, setTab] = React.useState('1');

    const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
        setTab(newTab);
    };

    useEffect(() => {
        if (dateModification(...startParams).isAfter(dateModification(...finishParams))
            || dateModification(...startParams).isSame(dateModification(...finishParams))
        ) {
            setDateError(true);
        } else setDateError(false);
    }, [...startParams, ...finishParams]);

    return (
        <TabContext value={ tab }>
            <Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
                <TabList onChange={ handleChangeTab } aria-label="lab API tabs example">
                    <Tab label={ PROPERTY_PATTERN_PRICE_SALE_ROUND } value="1" />
                    <Tab label={ PROPERTY_TITLE_CONDITIONS_SALES } value="2" />
                    <Tab label={ PROPERTY_ROLE_PERMISSIONS } value="3" />
                </TabList>
            </Box>
            <TabPanel value="1">
                <Grid2 container columnSpacing={ 2 } direction="row" sx={ { marginBottom: '24px' } }>
                    { PRICE_SALES_ROUND_HEAD_KEYS.map((fieldKey) => {
                        return (
                            <Grid2 xs={ 6 } key={ fieldKey }>
                                <TextField
                                    size="small"
                                    loading={ loading }
                                    key={ fieldKey }
                                    name={ fieldKey as keyof PriceSalesRoundFormFields }
                                    type={ typeof PRICE_SALES_ROUND_DEFAULT_VALUES[fieldKey as keyof PriceSalesRoundFormFields] }
                                    control={ control }
                                    label={ PRICE_SALES_ROUND_LABEL_DATA[fieldKey as keyof PriceSalesRoundFormFields] }
                                />
                            </Grid2>
                        );
                    }) }
                </Grid2>
                <Grid2 container columnSpacing={ 2 } sx={ { marginBottom: '12px' } }>
                    <Grid2 container xs={ 6 } direction="column">
                        <Grid2 sx={ { marginBottom: '12px' } }>
                            <Typography variant="h6">
                                Начало раунда продаж
                            </Typography>
                        </Grid2>
                        <Box>
                            { PRICE_SALES_ROUND_START_KEYS.map((item) => (
                                <PriceSalesRoundSettingsLine
                                    loading={ loading }
                                    control={ control }
                                    item={ item }
                                    key={ item.switcher }
                                />
                            )) }
                        </Box>
                    </Grid2>
                    <Grid2 container xs={ 6 } direction="column">
                        <Grid2 sx={ { marginBottom: '12px' } }>
                            <Typography variant="h6">
                                Окончание раунда продаж
                            </Typography>
                        </Grid2>
                        <Box>
                            { PRICE_SALES_ROUND_END_KEYS.map((item) => (
                                <PriceSalesRoundSettingsLine
                                    loading={ loading }
                                    control={ control }
                                    item={ item }
                                    key={ item.switcher }
                                />
                            )) }
                        </Box>
                    </Grid2>
                </Grid2>
                <Grid2 container rowSpacing={ 2 } xs={ 6 }>
                    <Grid2 xs={ 12 }>
                        <Typography variant="h6">
                            Пример применения шаблона раунда продаж:
                        </Typography>
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <Box sx={ { display: 'flex', alignItems: 'center' } }>
                            <Typography fontSize={ 18 } variant="h6">
                                Дата начала занятия:&nbsp;
                            </Typography>
                            <DateTimePicker
                                value={ startSalesRound }
                                setValue={ setStartSalesRound }
                            />
                        </Box>
                    </Grid2>
                    <Grid2 xs={ 5 }>
                        <Typography fontSize={ 18 } variant="h6">
                            Начало раунда продаж:
                        </Typography>
                        <Typography fontSize={ 18 } variant="h6" fontWeight={ 400 } textTransform="uppercase">
                            { dateModification(...startParams).format('ddd, DD.MM.YYYY HH:mm') }
                        </Typography>
                    </Grid2>
                    <Grid2 xs={ 5 }>
                        <Typography fontSize={ 18 } variant="h6">
                            Окончание раунда продаж:
                        </Typography>
                        <Typography fontSize={ 18 } variant="h6" fontWeight={ 400 } textTransform="uppercase">
                            { dateModification(...finishParams).format('ddd, DD.MM.YYYY HH:mm') }
                        </Typography>
                    </Grid2>
                </Grid2>
            </TabPanel>
            <TabPanel value="2">
                <Grid2 container rowSpacing={ 2 } direction="row">
                    { PRICE_SALES_ROUND_DESCRIPTION_KEYS.map((fieldKey) => (
                        <Grid2 xs={ 12 } key={ fieldKey }>
                            <TextField
                                size="small"
                                loading={ loading }
                                key={ fieldKey }
                                name={ fieldKey as keyof PriceSalesRoundFormFields }
                                type={ typeof PRICE_SALES_ROUND_DEFAULT_VALUES[fieldKey as keyof PriceSalesRoundFormFields] }
                                control={ control }
                                label={ PRICE_SALES_ROUND_LABEL_DATA[fieldKey as keyof PriceSalesRoundFormFields] }
                            />
                        </Grid2>
                    )) }
                </Grid2>
            </TabPanel>
            <TabPanel value="3">
                <PriceSaleRoundRolePermissionForm control={ control } loadingCourt={ loadingCourt || false } courtFilterOptions={ courtFilterOptions } />
            </TabPanel>
        </TabContext>
    );
};

export default PriceSalesRoundForm;
