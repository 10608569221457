import { SearchT } from 'types/DataGrid';

export const getFullNameFilter = ({ name }: SearchT) => ({
    ...(name && {
        client_datum: {
            client: {
                full_name: {
                    _iregex: name.replaceAll(' ', '.*'),
                },
            },
        },
    }),
});
