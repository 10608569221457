import {
    ERROR_ACQUIRING_SETTING_CREATE,
    SUCCESS_ACQUIRING_SETTING_CREATE,
} from 'constants/notificationMessage';

import { AcquiringSettingFormFields } from 'page/InternetAcquiring/components/formData';
import useAcquiringSettingCreate from 'shared/data-hook/acquiringSetting/create/useAcquiringSettingCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateAcquiringSetting = () => {
    const { error, loading, createAcquiringSetting: actionCreate } = useAcquiringSettingCreate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createAcquiringSetting = async (input: AcquiringSettingFormFields): Promise<boolean> => {
        await actionCreate(input);

        if (error) {
            showError(ERROR_ACQUIRING_SETTING_CREATE);

            return false;
        }

        showSuccess(SUCCESS_ACQUIRING_SETTING_CREATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        createAcquiringSetting,
        loading,
    };
};

export default useCreateAcquiringSetting;
