import { ERROR_PAYMENT_DOCUMENT_UPDATE, SUCCESS_PAYMENT_DOCUMENT_UPDATE } from 'constants/notificationMessage';

import { PaymentDocumentFormFields } from 'page/PaymentDocument/components/formData';
import usePaymentDocumentUpdate from 'shared/data-hook/paymentDocument/update/usePaymentDocumentUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { PaymentDocumentT } from 'types/PaymentDocument';

const useUpdatePaymentDocument = () => {
    const { error, paymentDocumentUpdate } = usePaymentDocumentUpdate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updatePaymentDocument = async (input: PaymentDocumentFormFields, paymentDocumentId: PaymentDocumentT['id']) => {
        const response = await paymentDocumentUpdate(input, paymentDocumentId);

        if (error || !response || !response.updatePaymentDocument) {
            showError(ERROR_PAYMENT_DOCUMENT_UPDATE);
        } else {
            showSuccess(SUCCESS_PAYMENT_DOCUMENT_UPDATE);
        }
        toggleRefetchTrigger();
    };

    return {
        updatePaymentDocument,
    };
};

export default useUpdatePaymentDocument;
