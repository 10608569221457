import {
    ERROR_PRT_CREATE, SUCCESS_PRT_CREATE,
} from 'constants/notificationMessage';

import { PrtFormFields } from 'page/Prt/components/formData';
import usePrtCreate from 'shared/data-hook/prt/create/usePrtCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreatePrt = () => {
    const { error, loading, createPrt: actionCreate } = usePrtCreate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createPrt = async (input: PrtFormFields): Promise<boolean> => {
        await actionCreate(input);

        if (error) {
            showError(ERROR_PRT_CREATE);

            return false;
        }

        showSuccess(SUCCESS_PRT_CREATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        createPrt,
        loading,
    };
};

export default useCreatePrt;
