import { MODAL_TITLE_CREATE_ACQUIRING_SETTING } from 'constants/modalTitles';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreateAcquiringSetting from 'shared/hook/acquiringSettings/create/useCreateAcquiringSetting';
import { useStore } from 'store';

import AcquiringSettingForm from './AcquiringSettingForm';
import { ACQUIRING_SETTING_DEFAULT_VALUES, AcquiringSettingFormFields, getAcquiringSettingValidationSchema } from './formData';

const CreateAcquiringSettingModal: FC = observer(() => {
    const {
        isOpen, onClose,
    } = useStore('createAcquiringSettingModal');
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { createAcquiringSetting, loading: loadingSave } = useCreateAcquiringSetting();
    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<AcquiringSettingFormFields>({
        defaultValues: ACQUIRING_SETTING_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getAcquiringSettingValidationSchema()),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreateAcquiringSetting = (data: AcquiringSettingFormFields) => {
        createAcquiringSetting(data).then(() => {
            handleCloseModal();
            toggleRefetchTrigger();
        });
    };

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_CREATE_ACQUIRING_SETTING }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <AcquiringSettingForm
                    control={ control }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                <Button variant="outlined" onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleCreateAcquiringSetting) }
                    autoFocus
                    disabled={ !isDirty || !isValid }
                    loading={ loadingSave }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateAcquiringSettingModal;
