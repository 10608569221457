import { useEffect } from 'react';
import useTaskType from 'shared/data-hook/taskType/fetch/fetchOne/useTaskType';
import { useStore } from 'store';

const useFetchTaskType = (id: string) => {
    const { fetchTaskType, loading } = useTaskType();
    const { setTaskType } = useStore('updateTaskTypeModal');

    useEffect(() => {
        fetchTaskType(id).then((fetchedTaskType) => {
            setTaskType(fetchedTaskType);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchTaskType;
