import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { TaskStatusesT } from 'types/TaskStatus';

import { TaskStatusDTO } from './TaskStatusDTO';

const DTO = t.type({
    taskStatuses: t.array(TaskStatusDTO),
    taskStatusesCount: AggregateDTO,
});

type TaskStatusDTOT = t.TypeOf<typeof DTO>;

export const TaskStatusesDTO = tPromise.extendDecoder<TaskStatusDTOT, TaskStatusesT>(
    DTO,
    ({
        taskStatuses,
        taskStatusesCount,
    }) => ({
        taskStatuses,
        taskStatusesCount,
    }),
) as t.Type<TaskStatusesT, TaskStatusDTOT>;
