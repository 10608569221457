import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { USER_QUERY, UserResponseT, UserT } from 'shared/graphql/query/user/UserQuery';
import { EMPTY_USER_RESULT } from 'types/UserTest';

import { UserDTO } from '../DTO/UserDTO';

import { UserDataT } from './UserData';

const useUser = (): UserDataT => {
    const [fetch, { loading, error }] = useLazyQuery<UserResponseT>(
        USER_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchUser = async (id: string): Promise<UserT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                UserDTO,
                EMPTY_USER_RESULT,
                (user) => user,
                data?.user,
            );
        } catch {
            return EMPTY_USER_RESULT;
        }
    };

    return {
        fetchUser,
        loading,
        error,
    };
};

export default useUser;
