import { makeAutoObservable } from 'mobx';
import { AcquiringSettingT, EMPTY_ACQUIRING_SETTING_RESULT } from 'types/AcquiringSetting';

export default class AcquiringSettingsStore {
    public acquiringSettings: AcquiringSettingT[] = [];

    public acquiringSetting: AcquiringSettingT = EMPTY_ACQUIRING_SETTING_RESULT;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setAcquiringSettings(settings: AcquiringSettingT[], count: number) {
        this.acquiringSettings = settings;
        this.count = count;
    }

    public setAcquiringSetting(acquiringSetting: AcquiringSettingT) {
        this.acquiringSetting = acquiringSetting;
    }
}
