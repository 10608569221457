import { gql } from '@apollo/client';

export type CreatePrtPayloadT = {
    name: string;
    description: string | null;
    order: number;
}

export type CreatePrtResponseT = {
    type: string;
    reason: null | string;
}

export type CreatePrtVarT = {
    object: CreatePrtPayloadT;
};

export type CreatePrtT = {
    adminPrtCreate: CreatePrtResponseT;
}

export const EMPTY_CREATE_PRT_RESPONSE: CreatePrtResponseT = {
    type: 'error',
    reason: '',
};

export const CREATE_PRT = gql`
    mutation PrtCreate($object: prt_insert_input = {}) {
        createPrt: insert_prt_one(
            object: $object
        ) {
            id
        }
    }
`;
