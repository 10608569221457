import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ClientFullNameT } from 'types/Client';

import Nullable from './Nullable';

const FullNameDTO = t.intersection([
    t.partial({
        id: t.string,
    }),
    t.type({
        first_name: Nullable(t.string),
        middle_name: Nullable(t.string),
        last_name: Nullable(t.string),
    }),
]);

type ClientFullNameDTOType = t.TypeOf<typeof FullNameDTO>;

export const ClientFullNameDTO = tPromise.extendDecoder<ClientFullNameDTOType, ClientFullNameT>(
    FullNameDTO,
    ({
        id,
        first_name,
        last_name,
        middle_name,
    }) => ({
        id,
        firstName: first_name,
        lastName: last_name,
        middleName: middle_name,
    }),
) as t.Type<ClientFullNameT, ClientFullNameDTOType>;

export const ClientDataDTO = Nullable(t.type({
    client: Nullable(ClientFullNameDTO),
}));
