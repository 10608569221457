import {
    ERROR_TASK_STATUS_UPDATE,
    SUCCESS_TASK_STATUS_UPDATE,
} from 'constants/notificationMessage';

import { TaskStatusFormFields } from 'page/TaskStatus/components/formData';
import useTaskStatusUpdate from 'shared/data-hook/taskStatus/update/useTaskStatusUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useUpdateTaskStatus = () => {
    const { error, taskStatusUpdate, loading } = useTaskStatusUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const updateTaskStatus = async (data: TaskStatusFormFields, id: string) => {
        const response = await taskStatusUpdate(data, id);

        if (error || !response) {
            showError(ERROR_TASK_STATUS_UPDATE);

            return false;
        }

        showSuccess(SUCCESS_TASK_STATUS_UPDATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        updateTaskStatus,
        loading,
    };
};

export default useUpdateTaskStatus;
