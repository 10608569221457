import { useMutation } from '@apollo/client';
import { ImplementationForm } from 'page/Implementation/components/formData';
import {
    IMPLEMENTATIONS_MULTIPLE_UPDATE,
    MultipleImplementationsUpdateResponseDataT,
    MultipleImplementationsUpdateVar,
} from 'shared/graphql/mutation/implementation/ImplementationUpdate';
import { ImplementationT } from 'types/Implementation';

import { MultipleImplementationsUpdateDataT } from './ImplementationUpdateData';

const useImplementationUpdate = (): MultipleImplementationsUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        MultipleImplementationsUpdateResponseDataT,
        MultipleImplementationsUpdateVar
    >(IMPLEMENTATIONS_MULTIPLE_UPDATE);

    const updateAction = async (
        inputs: ImplementationForm[],
        implementationIds: Array<ImplementationT['id']>,
    ) => {
        if (implementationIds.length === 0) {
            // TODO: use Sentry
            throw new Error('Implementation is not found');
        }
        try {
            const { data } = await update({
                variables: {
                    // eslint-disable-next-line complexity
                    updates: implementationIds.map((id, index) => ({
                        where: { id: { _eq: id } },
                        _set: {
                            client_id: inputs[index].clientId || null,
                            payer_id: inputs[index].payerId || null,
                            is_made_by_club: inputs[index].isMadeByClub || false,
                            cancel_id: inputs[index].cancelId || null,
                            cashback_bonus_payment_document_id: inputs[index].cashbackBonusPaymentDocumentId || null,
                            cashback_fiat_payment_document_id: inputs[index].cashbackFiatPaymentDocumentId || null,
                            coach_id: inputs[index].coachId || null,
                            court_id: inputs[index].courtId || null,
                            group_id: inputs[index].groupId || null,
                            operation: inputs[index].operation || null,
                            performer: inputs[index].performer || null,
                            place: inputs[index].place || null,
                            price_sales_round_id: inputs[index].priceSalesRoundId || null,
                            price_type_id: inputs[index].priceTypeId || null,
                            service: inputs[index].service,
                            service_category_id: inputs[index].serviceCategoryId || null,
                            transfer_id: inputs[index].transferId || null,
                            user_id: inputs[index].userId || null,
                            discount_percent: inputs[index].discountPercent || 0,
                            duration: inputs[index].duration,
                            guests_amount: inputs[index].guestsAmount || 0,
                            cashback_bonus_amount: inputs[index].cashbackBonusAmount || 0,
                            cashback_fiat_amount: inputs[index].cashbackFiatAmount || 0,
                            discount: inputs[index].discount || 0,
                            payment_in_fiat: inputs[index].paymentInFiat || 0,
                            payment_in_bonus: inputs[index].paymentInBonus || 0,
                            performer_income_bonus: inputs[index].performerIncomeBonus || 0,
                            performer_income_fiat: inputs[index].performerIncomeFiat || 0,
                            rate: inputs[index].rate || 0,
                            total: inputs[index].total || 0,
                            comment: inputs[index].comment || null,
                            document: inputs[index].document,
                            document_id: inputs[index].documentId,
                            title: inputs[index].title || null,
                            datetime: inputs[index].datetime,
                            datetime_operation: inputs[index].datetimeOperation || null,
                        },
                    })),
                    providerIncomeUpdates: inputs.flatMap((impl) =>
                        impl.providerIncomes?.map((providerIncome) => ({
                            where: { id: { _eq: providerIncome.id } },
                            _set: {
                                coach_id: providerIncome.coachId || null,
                                operation: providerIncome.operation || null,
                                organization_id: providerIncome.organizationId || null,
                                place: providerIncome.place || null,
                                price_type_id: providerIncome.priceTypeId || null,
                                service: providerIncome.service || null,
                                income_in_bonus: providerIncome.incomeInBonus || 0,
                                income_in_fiat: providerIncome.incomeInFiat || 0,
                                covered_at: providerIncome.coveredAt || null,
                                datetime: providerIncome.datetime || null,
                                datetime_operation: providerIncome.datetimeOperation || null,
                                payed_at: providerIncome.payedAt || null,
                            },
                        })) || []),
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        implementationUpdate: updateAction,
        loading,
        error,
    };
};

export default useImplementationUpdate;
