import { PAGE_TITLE_IMPORT_PRICE } from 'constants/pageTitles';

import { ChangeEventHandler, FC, MouseEventHandler } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import useImportPrice from 'shared/hook/import/useImportPrice';
import usePageTitle from 'shared/hook/usePageTitle';

const Form: FC = () => {
    const {
        file,
        setFile,
        fileName,
        setFileName,
        isLoading,
        send,
    } = useImportPrice();

    usePageTitle(PAGE_TITLE_IMPORT_PRICE);

    const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const { files } = event.target;
        const uploadFile = files?.[0];

        if (!uploadFile) {
            return;
        }

        setFile(uploadFile);
        setFileName(uploadFile.name);
    };

    const handleSubmitButton: MouseEventHandler<HTMLButtonElement> = async () => {
        await send();
    };

    return (
        <Box my={ 2 } mx={ 3 }>
            { fileName && <Typography>{ fileName }</Typography> }
            <Box
                display="flex"
                flexDirection="column"
                gap={ 4 }
                alignItems="flex-start"
            >
                <Button
                    component="label"
                    variant="outlined"
                    startIcon={ <CloudUploadIcon /> }
                    sx={ {
                        mt: 2,
                    } }
                >
                    Выбрать файл
                    <input type="file" accept=".csv" onChange={ handleFileChange } hidden />
                </Button>
                { file && (
                    <LoadingButton
                        color="success"
                        variant="contained"
                        loading={ isLoading }
                        onClick={ handleSubmitButton }
                    >
                        Загрузить
                    </LoadingButton>
                ) }
            </Box>
        </Box>
    );
};

export default Form;
