import React, { FC, useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { TaskTypeT } from 'types/TaskType';

import { TASK_TYPE_FIELD_KEYS, TASK_TYPE_LABEL_DATA, TaskTypeFormFields } from './formData';

type PropsT = {
    control: Control<TaskTypeFormFields>;
    setValue?: UseFormSetValue<TaskTypeFormFields>;
    taskType?: TaskTypeT;
    isUpdate?: boolean;
};

const TaskTypeForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, taskType, isUpdate,
    } = props;

    useEffect(() => {
        if (isUpdate && taskType && setValue) {
            Object.keys(taskType).forEach((key) => {
                setValue(key as keyof TaskTypeFormFields, taskType[key as keyof TaskTypeFormFields as never]);
            });
        }
    }, [taskType, isUpdate]);

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ TASK_TYPE_FIELD_KEYS.name }
                    label={ TASK_TYPE_LABEL_DATA.name }
                    type="text"
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ TASK_TYPE_FIELD_KEYS.position }
                    label={ TASK_TYPE_LABEL_DATA.position }
                    type="number"
                />
            </Grid2>
            <Grid2>
                <Switch
                    label={ TASK_TYPE_LABEL_DATA.isActive }
                    name={ TASK_TYPE_FIELD_KEYS.isActive }
                    control={ control }
                    type="boolean"
                />
            </Grid2>
            <Grid2>
                <Switch
                    label={ TASK_TYPE_LABEL_DATA.defaultShouldCreateFinishComment }
                    name={ TASK_TYPE_FIELD_KEYS.defaultShouldCreateFinishComment }
                    control={ control }
                    type="boolean"
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ TASK_TYPE_FIELD_KEYS.defaultDurationHours }
                    label={ TASK_TYPE_LABEL_DATA.defaultDurationHours }
                    type="number"
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ TASK_TYPE_FIELD_KEYS.defaultTitle }
                    label={ TASK_TYPE_LABEL_DATA.defaultTitle }
                    type="text"
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ TASK_TYPE_FIELD_KEYS.defaultDescription }
                    label={ TASK_TYPE_LABEL_DATA.defaultDescription }
                    type="text"
                    multiline={ true }
                />
            </Grid2>
        </Grid2>
    );
});

export default TaskTypeForm;
