import { RUB_SYMBOL } from 'constants/currency';
import { MONTH_SHORT } from 'constants/date';

import React, { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import AdornmentColor from 'shared/component/Adorment';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';

import { MEMBER_CARD_TYPE_FIELDS_KEYS, MEMBER_CARD_TYPE_LABEL_DATA, MemberCardTypeFormFields } from './formData';

type PropsT = {
    control: Control<MemberCardTypeFormFields>;
};

const MemberCardTypeForm: FC<PropsT> = (props) => {
    const { control } = props;
    const { field: { value: color } } = useController({ control, name: 'color' });

    return (
        <Grid2 container xs={ 12 } alignItems="flex-end" columnSpacing={ 2 }>
            <Grid2 xs={ 6 }>
                <TextField
                    name={ MEMBER_CARD_TYPE_FIELDS_KEYS.title }
                    control={ control }
                    label={ MEMBER_CARD_TYPE_LABEL_DATA.title }
                />
            </Grid2>
            <Grid2 xs={ 6 }>
                <TextField
                    name={ MEMBER_CARD_TYPE_FIELDS_KEYS.expirationPeriod }
                    control={ control }
                    label={ MEMBER_CARD_TYPE_LABEL_DATA.expirationPeriod }
                    inputAdornment={ MONTH_SHORT }
                />
            </Grid2>
            <Grid2 xs={ 12 } mt={ 2 } mb={ 1 }>
                <Typography variant="h5">Настройки параметров кэшбэков</Typography>
            </Grid2>
            <Grid2 xs={ 4 }>
                <TextField
                    labelWrap={ true }
                    name={ MEMBER_CARD_TYPE_FIELDS_KEYS.cashbackFiatOperationsLimit }
                    control={ control }
                    label={ MEMBER_CARD_TYPE_LABEL_DATA.cashbackFiatOperationsLimit }
                />
            </Grid2>
            <Grid2 xs={ 4 }>
                <TextField
                    name={ MEMBER_CARD_TYPE_FIELDS_KEYS.cashbackFiatSumLimit }
                    control={ control }
                    label={ MEMBER_CARD_TYPE_LABEL_DATA.cashbackFiatSumLimit }
                    inputAdornment={ RUB_SYMBOL }
                />
            </Grid2>
            <Grid2 xs={ 4 }>
                <TextField
                    name={ MEMBER_CARD_TYPE_FIELDS_KEYS.color }
                    control={ control }
                    label={ MEMBER_CARD_TYPE_LABEL_DATA.color }
                    inputAdornment={ <AdornmentColor color={ color } /> }
                />
            </Grid2>
            <Grid2 xs={ 3 }>
                <Switch
                    name={ MEMBER_CARD_TYPE_FIELDS_KEYS.isActive }
                    control={ control }
                    label={ MEMBER_CARD_TYPE_LABEL_DATA.isActive }
                />
            </Grid2>
        </Grid2>
    );
};

export default MemberCardTypeForm;
