import { ERROR_DELETE } from 'constants/notificationMessage';

import usePriceMultipleDelete from 'shared/data-hook/price/delete/multiple/usePriceMultipleDelete';
import { useStore } from 'store';

import { useNotification } from '../../useNotification';

const useDeleteMultiplePrice = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteMultiplePrice: actionDelete } = usePriceMultipleDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const deleteMultiplePrice = async (priceIds: string[]) => {
        const { affectedRows } = await actionDelete(priceIds);

        if (error || !affectedRows) {
            showError(ERROR_DELETE);

            return;
        }

        toggleRefetchTrigger();
        showSuccess(`Строк удалено: ${affectedRows}`);
    };

    return {
        deleteMultiplePrice,
    };
};

export default useDeleteMultiplePrice;
