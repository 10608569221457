import { useMutation } from '@apollo/client';
import {
    TASK_TYPE_DELETE,
    TaskTypeDeleteResponseDataT,
    TaskTypeDeleteVarT,
} from 'shared/graphql/mutation/taskType/TaskTypeDelete';
import { TaskTypeT } from 'types/TaskType';

import { TaskTypeDeleteDataT } from './TaskTypeDeleteData';

const useTaskTypeDelete = (): TaskTypeDeleteDataT => {
    const [taskTypeDelete, { loading, error }] = useMutation<
        TaskTypeDeleteResponseDataT,
        TaskTypeDeleteVarT
    >(TASK_TYPE_DELETE);

    const deleteAction = async (taskTypeId: TaskTypeT['id']) => {
        try {
            const { data } = await taskTypeDelete({
                variables: {
                    id: taskTypeId,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteTaskType: deleteAction,
        loading,
        error,
    };
};

export default useTaskTypeDelete;
