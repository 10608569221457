import { useMutation } from '@apollo/client';
import { PRT_DELETE, PrtDeleteResponseDataT, PrtDeleteVarT } from 'shared/graphql/mutation/prt/PrtDelete';
import { PrtT } from 'types/Prt';

import { PrtDeleteDataT } from './PrtDeleteData';

const usePrtDelete = (): PrtDeleteDataT => {
    const [prtDelete, { loading, error }] = useMutation<
        PrtDeleteResponseDataT,
        PrtDeleteVarT
    >(PRT_DELETE);

    const deleteAction = async (prtId: PrtT['id']) => {
        try {
            const { data } = await prtDelete({
                variables: {
                    id: prtId,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deletePrt: deleteAction,
        loading,
        error,
    };
};

export default usePrtDelete;
