import React, { FC } from 'react';

const DisplayColumns: FC = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.85623 0.0303507C2.59702 0.126054 2.47485 0.437772 2.61489 0.645585C2.68342 0.749491 5.08492 2.81121 5.20112 2.86863C5.29945 2.91512 5.53185 2.91785 5.62124 2.8741C5.72254 2.82488 8.13894 0.744022 8.20151 0.651053C8.33857 0.454179 8.23726 0.150663 7.9989 0.0522256C7.8827 0.00300694 7.7516 0.000272512 5.40373 0.00300694C3.83352 0.00300694 2.90391 0.0139444 2.85623 0.0303507Z"
                fill="#1976D2"
            />
            <path
                d="M0.890049 2.975C0.672543 3.04063 0.517608 3.13359 0.344795 3.30586C0.171982 3.47539 0.0706785 3.66953 0.0259855 3.91016C0.00214931 4.03594 -0.00380975 5.41133 0.00214931 8.57227L0.0110879 13.0566L0.0766375 13.2152C0.204757 13.5297 0.490792 13.8004 0.82152 13.9207C0.991353 13.9836 1.01817 13.9863 1.90309 13.9863C2.70756 13.9863 2.82972 13.9809 2.96678 13.9371C3.19918 13.8633 3.46436 13.6691 3.59844 13.4832C3.82786 13.1551 3.81297 13.5762 3.80403 8.40547L3.79509 3.78438L3.68485 3.5793C3.54779 3.325 3.35114 3.14727 3.07106 3.02695L2.87144 2.93945L1.96268 2.93398C1.15821 2.92852 1.03605 2.93398 0.890049 2.975ZM2.55263 5.98008L2.55859 7.875H1.91799H1.27739V5.97461V4.07422L1.91203 4.07969L2.54369 4.08789L2.55263 5.98008ZM2.55859 10.9375V12.8242H1.91799H1.27739V10.9375V9.05078H1.91799H2.55859V10.9375Z"
                fill="#1976D2"
            />
            <path
                d="M7.86217 2.98047C7.50761 3.10078 7.22157 3.34961 7.07855 3.66953L7.013 3.81445L7.00406 8.36719C6.99811 11.7824 7.00406 12.9582 7.03088 13.0703C7.13218 13.5078 7.53442 13.8797 8.00519 13.9699C8.11245 13.9891 8.90798 14 10.508 14C12.5222 14 12.8797 13.9945 13.0317 13.959C13.5203 13.8414 13.8749 13.4887 13.9672 13.032C13.9911 12.9172 14 11.5336 14 8.45195C14 3.63945 14.006 3.81719 13.8302 3.52461C13.7169 3.33047 13.419 3.08164 13.2045 3.00235L13.0317 2.93945L10.5288 2.93399C8.10053 2.92852 8.02008 2.92852 7.86217 2.98047ZM9.85845 5.97461V7.875H9.06888H8.2793V5.97461V4.07422H9.06888H9.85845V5.97461ZM12.7188 5.97461V7.875H11.9292H11.1397V5.97461V4.07422H11.9292H12.7188V5.97461ZM9.85845 10.9375V12.8242H9.06888H8.2793V10.9375V9.05078H9.06888H9.85845V10.9375ZM12.7188 10.9375V12.8242H11.9292H11.1397V10.9375V9.05078H11.9292H12.7188V10.9375Z"
                fill="#1976D2"
            />
        </svg>
    );
};

export default DisplayColumns;
