import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import {
    IMPLEMENTATIONS_QUERY, ImplementationsResponseT,
} from 'shared/graphql/query/implementation/ImplementationQuery';
import { FetchCoachHoursDataParams } from 'types/CoachHours';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_IMPLEMENTATIONS_RESULT } from 'types/Implementation';

import { ImplementationsDTO } from '../DTO/ImplementationsDTO';

import { ImplementationsDataT } from './ImplementationsData';

const useImplementations = (): ImplementationsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<ImplementationsResponseT, QueryVarsT>(
        IMPLEMENTATIONS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedImplementationsData = decodeDTO(
        ImplementationsDTO,
        EMPTY_IMPLEMENTATIONS_RESULT,
        (implementations) => implementations,
        {
            implementations: data?.implementations,
            implementationsCount: data?.implementationsCount,
        },
    );

    const fetchImplementations = async ({
        limit, offset, orderBy = {}, where = { items: [], linkOperator: 'and' }, coach = {}, dateRange = {},
    }: FetchDataParams & FetchCoachHoursDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: { ...coach, ...dateRange, ...filterData(where) },
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: decodedImplementationsData.implementations,
        count: decodedImplementationsData.implementationsCount,
        fetchImplementations,
        loading,
    };
};

export default useImplementations;
