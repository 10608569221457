import capitalizeFirstLetter from './capitalizeFirstLetter';

type ParamsT = {
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
};

const getShortenedName = ({ firstName, middleName, lastName }: ParamsT): string => {
    const capitalizedLast = capitalizeFirstLetter(lastName ?? '');
    const capitalizedMiddle = capitalizeFirstLetter(middleName ?? '').charAt(0);
    const capitalizedFirst = capitalizeFirstLetter(firstName ?? '').charAt(0);

    return `${capitalizedLast}${firstName ? ` ${capitalizedFirst}.` : ''}${
        middleName ? ` ${capitalizedMiddle}.` : ''
    }`;
};

export default getShortenedName;
