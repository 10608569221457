import { ERROR_MEMBER_CARD_TYPE_UPDATE, SUCCESS_MEMBER_CARD_TYPE_UPDATE } from 'constants/notificationMessage';
import { MEMBER_CARD_TYPE } from 'constants/routes';

import { useNavigate } from 'react-router-dom';
import { MemberCardTypeFormFields } from 'page/MemberCardType/components/formData';
import useMemberCardTypeUpdate from 'shared/data-hook/memberCardType/update/useMemberCardTypeUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { MemberCardTypeT } from 'types/MemberCardType';

const useUpdateMemberCardType = () => {
    const { error, loading, memberCardTypeUpdate } = useMemberCardTypeUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();
    const navigate = useNavigate();

    const updateMemberCardType = async (
        input: MemberCardTypeFormFields,
        memberCardTypeId: MemberCardTypeT['id'],
    ) => {
        const response = await memberCardTypeUpdate(input, memberCardTypeId);

        if (error || !response || !response.updateMemberCardType) {
            showError(ERROR_MEMBER_CARD_TYPE_UPDATE);
        } else {
            showSuccess(SUCCESS_MEMBER_CARD_TYPE_UPDATE);
        }

        toggleRefetchTrigger();

        return navigate(MEMBER_CARD_TYPE);
    };

    return {
        updateMemberCardType,
        loading,
    };
};

export default useUpdateMemberCardType;
