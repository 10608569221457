import { gql } from '@apollo/client';

export type MemberCardUpdateFields = {
    datetime_start: string;
    datetime_end: string;
    is_active: boolean;
    member_card_type_id: string;
    cashback_fiat_total_accrued: number;
    cashback_fiat_total_operations: number;
};

export type MemberCardUpdateVar = {
    id: string;
    set: MemberCardUpdateFields;
};

export type MemberCardUpdateResponseDataT = {
    updateMemberCard: {
        id: string;
    };
} | null | undefined;

export const MEMBER_CARD_UPDATE = gql`
    mutation updateMemberCard(
        $set: member_card_set_input = {},
        $id: String = ""
    ) {
        updateMemberCard: update_member_card_by_pk(
            pk_columns: { id: $id },
            _set: $set
        ) {
            id
        }
    }
`;
