import { makeAutoObservable } from 'mobx';
import { UserT } from 'shared/graphql/query/user/UserQuery';
import { EMPTY_USER_RESULT } from 'types/UserTest';

export default class UserStore {
    public user: UserT = EMPTY_USER_RESULT;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setUser(user: UserT) {
        this.user = user;
    }
}
