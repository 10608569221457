import React, { FC, MouseEventHandler, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useExportPrice from 'shared/hook/export/useExportPrice';
import { useStore } from 'store';

type PropsT = {
    priceTypeId: string;
    priceSeasonId: string;
};

const ExportPriceDataModal: FC<PropsT> = observer((props) => {
    const { priceTypeId, priceSeasonId } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const {
        isOpen, onClose, client,
    } = useStore('exportPriceDataModal');
    const { priceSeason, activePriceType } = useStore('price');
    const {
        send,
    } = useExportPrice();

    const handleCloseModal = () => {
        onClose();
    };

    const handleSubmitButton: MouseEventHandler<HTMLButtonElement> = async () => {
        setLoading(true);
        await send({
            priceSeasonId,
            priceTypeId,
        });
        setLoading(false);
        handleCloseModal();
    };

    return (
        <Modal open={ isOpen } fullWidth={ true } maxWidth="md">
            <ModalTitle onClose={ onClose }>
                Экспорт данных прайса сезона “{ priceSeason?.title }” для услуги “{ activePriceType?.title }”
            </ModalTitle>
            <DialogContent dividers={ true }>
                <Typography>
                    { client?.email
                        ? `Данные будут отправлены в виде CSV-файла на вашу электронную почту ${client?.email}`
                        : 'Невозможно экспортировать данные — не указана электронная почта.' }
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal } variant="outlined">
                    Отмена
                </Button>
                <LoadingButton
                    color="primary"
                    variant="contained"
                    autoFocus
                    onClick={ handleSubmitButton }
                    disabled={ !client?.email }
                    loading={ loading }
                >
                    Подтвердить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default ExportPriceDataModal;
