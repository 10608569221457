import { gql } from '@apollo/client';

export type CreateTaskTypePayloadT = {
    name: string;
    position: number;
    is_active: boolean;
    default_duration_hours: number | null;
    default_title: string| null;
    default_description: string | null;
    default_should_create_finish_comment: boolean;
}

export type CreateTaskTypeResponseT = {
    type: string;
    reason: null | string;
}

export type CreateTaskTypeVarT = {
    object: CreateTaskTypePayloadT;
};

export type CreateTaskTypeT = {
    adminTaskTypeCreate: CreateTaskTypeResponseT;
}

export const EMPTY_CREATE_TASK_TYPE_RESPONSE: CreateTaskTypeResponseT = {
    type: 'error',
    reason: '',
};

export const CREATE_TASK_TYPE = gql`
    mutation TaskTypeCreate($object: task_type_insert_input = {}) {
        createTaskType: insert_task_type_one(
            object: $object
        ) {
            id
        }
    }
`;
