export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const PRICE_TYPE = '/price-type';
export const PRICE_SEASON = '/price-season';
export const SERVICE_CATEGORY = '/service-category';
export const PRICE_MEMBER_CARDS = '/price-member-cards';

export const MEMBER_CARD = '/member-card';
export const MEMBER_CARD_TYPE = '/member-card-type';

export const SCHEDULE_SLOT_RESERVE = '/schedule-slot-reserve';
export const IMPLEMENTATION = '/implementation';
export const IMPLEMENTATION_DOCUMENTS = '/implementation-documents';
export const CALCULATION = '/calculation';
export const WAITING_LIST = '/waiting-list';

export const PAYMENT = '/payment';
export const PAYMENT_DOCUMENTS = '/payment-document';
export const PAYMENT_SETTINGS = '/payment-setting';
export const INTERNET_ACQUIRING = '/internet-acquiring';

export const BONUS = '/bonus';
export const BONUS_DOCUMENT = '/bonus-document';

export const CLIENT = '/client';
export const CLIENT_TRANSACTION = '/client-transaction';
export const CLIENT_BONUS_TRANSACTION = '/client-bonus-transaction';
export const CLIENT_DELAY_OF_PAYMENT = '/client-delay-of-payment';

export const COACH = '/coach';
export const COACH_SETTINGS = '/coach-settings';
export const COACH_CATEGORY = '/coach-category';
export const COACH_SALARY = '/coach-salary';
export const COACHES_IN_GROUPS = '/coaches-in-groups';
export const COACH_HOURS = '/coach-hours';

export const GROUP = '/group';

export const COURT = '/court';
export const COURT_CATEGORY = '/court-category';

export const ORGANIZATION = '/organization';
export const ORGANIZATION_LOCATION = '/organization-location';

export const SERVICE_APP = '/service-app';
export const USER = '/user';
export const EMPLOYEE = '/employee';

export const AGE_GROUPS = '/age';
export const PRT = '/prt';
export const BULK_OPERATIONS = '/bulk-operations';

export const COURT_VIDEO_ANALYTICS = '/court-video-analytics';

export const DASHBOARD = '/';

export const TASK_TYPE = '/task-type';
export const TASK_STATUS = '/task-status';

export const IMPORT_PRICE = '/import-price';
