import { gql } from '@apollo/client';

export type CreateAcquiringSettingPayloadT = {
    type: string;
    api_login: string;
    api_password: string;
    api_token: string;
    api_url: string;
    payment_method_id?: string;
    payment_type_id?: string;
    service_app_id: string;
}

export type CreateAcquiringSettingResponseT = {
    status: string;
    reason: null | string;
}

export type CreateAcquiringSettingVarT = {
    object: CreateAcquiringSettingPayloadT;
};

export type CreateAcquiringSettingT = {
    adminAcquiringSettingCreate: CreateAcquiringSettingResponseT;
}

export const EMPTY_CREATE_ACQUIRING_SETTING_RESPONSE: CreateAcquiringSettingResponseT = {
    status: 'error',
    reason: '',
};

export const CREATE_ACQUIRING_SETTING = gql`
    mutation AcquiringSettingCreate($object: service_app_acquiring_setting_insert_input = {}) {
        createAcquiringSetting: insert_service_app_acquiring_setting_one(
            object: $object
        ) {
            id
        }
    }
`;
