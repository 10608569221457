import { PAGE_TITLE_CRM_SETTINGS_STATUS } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useTaskStatuses from 'shared/data-hook/taskStatus/fetch/fetchAll/useTaskStatuses';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CreateTaskStatusModal from './components/CreateTaskStatusModal';
import TaskStatusListComponent from './components/TaskStatusListComponent';
import UpdateTaskStatusModal from './components/UpdateTaskStatusModal';
import { TASK_STATUS_HEADER_DATA } from './domain/HeaderData';

const TaskStatusList: FC = observer(() => {
    const {
        pageSize,
        setPageSize,
        page,
        setPage,
        sortModel,
        setSortModel,
        filterModel,
        setFilterModel,
    } = useDataGrid();
    const {
        data: taskStatusesData,
        loading: taskStatusesLoading,
        count: rowCount,
        fetchTaskStatuses,
    } = useTaskStatuses();

    const { refetchTrigger } = useStore('refetchTrigger');
    const { onOpen } = useStore('createTaskStatusModal');
    const { setTaskStatuses } = useStore('taskStatus');

    useFetchDataForGrid(
        sortModel,
        filterModel,
        page,
        pageSize,
        fetchTaskStatuses,
        refetchTrigger,
        TASK_STATUS_HEADER_DATA,
    );
    useEffect(() => {
        setTaskStatuses(taskStatusesData, rowCount);
    }, [taskStatusesData, taskStatusesLoading]);

    usePageTitle(PAGE_TITLE_CRM_SETTINGS_STATUS);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => onOpen() }
                    startIcon={ <AddIcon /> }
                >
                    добавить
                </Button>
            </ControlPanel>
            <TaskStatusListComponent
                loading={ taskStatusesLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateTaskStatusModal />
            <UpdateTaskStatusModal />
        </StyledPageWrap>
    );
});

export default TaskStatusList;
