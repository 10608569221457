import { gql } from '@apollo/client';

export type UpdatePriceMemberCardTypePayloadT = {
    price_season_id: string;
    member_card_type_id: string;
    card_price: number;
    discount_rates: Array<{
        price_sales_round_id: string;
        discount_percent_fiat: number;
        discount_percent_bonus: number;
        multiplying_factor: number;
        discount_percent_bonus_per_free_slot: number;
        cashback_percent_bonus: number;
        cashback_percent_fiat: number;
    }>;
    discount_full_returns: Array<{
        enabled_at_months: string[];
        full_return_quantity_limit: number;
        price_sales_round_ids: string[];
        is_performer_income_recalculating: boolean;
    }>;
}

export type UpdatePriceMemberCardTypeResponseT = {
    status: string;
    reason: null | string;
}

export type UpdatePriceMemberCardTypeVarT = {
    appId: string;
    payload: UpdatePriceMemberCardTypePayloadT;
};

export type UpdatePriceMemberCardTypeT = {
    adminPriceUpdateMemberCardTypePrices: UpdatePriceMemberCardTypeResponseT;
}

export const EMPTY_UPDATE_PRICE_MEMBER_CARD_TYPE_RESPONSE: UpdatePriceMemberCardTypeResponseT = {
    status: 'error',
    reason: '',
};

export const UPDATE_PRICE_MEMBER_CARD_TYPE = gql`
    mutation UpdatePriceMemberCardType($appId: String!, $payload: json!) {
        adminPriceUpdateMemberCardTypePrices(app_id: $appId, payload: $payload) {
            status
            reason
        }
    }
`;
