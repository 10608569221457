import { DATE_FORMAT_FOR_DATA_GRID } from 'constants/date';
import {
    PROPERTY_ACTIONS,
    PROPERTY_CARD_TYPE, PROPERTY_CASHBACK_FIAT_TOTAL_ACCRUED, PROPERTY_CASHBACK_FIAT_TOTAL_OPERATIONS,
    PROPERTY_CLIENT_FIRST_NAME, PROPERTY_CLIENT_LAST_NAME, PROPERTY_CLIENT_MIDDLE_NAME,
    PROPERTY_END, PROPERTY_RELATED_CLIENTS, PROPERTY_START, PROPERTY_STATUS,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { Chip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import SwitchIsActiveButton from 'shared/component/Button/SwitchIsActiveButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import { EmptyCell } from 'shared/component/DataGrid/Cell/EmptyCell';
import { FlexWrapBox } from 'shared/component/DataGrid/domain/Styled';
import getShortenedName from 'shared/helper/string/getShortenedName';
import useSwitchIsActiveMemberCard from 'shared/hook/memberCard/switchIsActive/useSwitchIsActiveMemberCard';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { ClientFullNameT } from 'types/Client';
import { CustomHeaderData } from 'types/DataGrid';

import MemberCardEditButton from '../components/MemberCardEditButton';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, title } } = props;
    const { switchIsActiveMemberCard, loading } = useSwitchIsActiveMemberCard();

    return (
        <>
            <MemberCardEditButton id={ id } />
            <SwitchIsActiveButton id={ id } title={ title } actionDelete={ switchIsActiveMemberCard } loading={ loading } />
        </>
    );
};

const renderRelatedClients: FC<GridRenderCellParams> = (props) => {
    const { row } = props;
    const relatedClients = row.relatedClients as ClientFullNameT[];

    return relatedClients.length ? (
        <FlexWrapBox>
            { relatedClients.map(({ id, ...name }) => (
                <Chip
                    key={ id }
                    size="small"
                    color="primary"
                    variant="outlined"
                    label={ getShortenedName(name) }
                />
            )) }
        </FlexWrapBox>
    ) : <EmptyCell />;
};

export const MEMBER_CARD_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_CLIENT_LAST_NAME,
        field: 'clientLastName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.lastName),
        hasuraFilterFields: ['client', 'last_name'],
        hasuraSortFields: ['client', 'last_name'],
    },
    {
        headerName: PROPERTY_CLIENT_FIRST_NAME,
        field: 'clientFirstName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.firstName),
        hasuraFilterFields: ['client', 'first_name'],
        hasuraSortFields: ['client', 'first_name'],
    },
    {
        headerName: PROPERTY_CLIENT_MIDDLE_NAME,
        field: 'clientMiddleName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.middleName),
        hasuraFilterFields: ['client', 'middle_name'],
        hasuraSortFields: ['client', 'middle_name'],
    },
    {
        headerName: PROPERTY_RELATED_CLIENTS,
        field: 'relatedClients',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        renderCell: renderRelatedClients,
    },
    {
        headerName: PROPERTY_CARD_TYPE,
        field: 'memberCardType',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['member_card_type', 'id'],
        hasuraSortFields: ['member_card_type', 'title'],
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.memberCardType?.title),
    },
    {
        headerName: PROPERTY_START,
        field: 'datetimeStart',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        hasuraFilterFields: ['datetime_start'],
        hasuraSortFields: ['datetime_start'],
        renderCell: (params) => renderDateToGrid(params.row.datetimeStart, DATE_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_END,
        field: 'datetimeEnd',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        hasuraFilterFields: ['datetime_end'],
        hasuraSortFields: ['datetime_end'],
        renderCell: (params) => renderDateToGrid(params.row.datetimeEnd, DATE_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_CASHBACK_FIAT_TOTAL_ACCRUED,
        field: 'cashbackFiatTotalAccrued',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraFilterFields: ['cashback_fiat_total_accrued'],
        hasuraSortFields: ['cashback_fiat_total_accrued'],
    },
    {
        headerName: PROPERTY_CASHBACK_FIAT_TOTAL_OPERATIONS,
        field: 'cashbackFiatTotalOperations',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraFilterFields: ['cashback_fiat_total_operations'],
        hasuraSortFields: ['cashback_fiat_total_operations'],
    },
    {
        headerName: PROPERTY_STATUS,
        field: 'isActive',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
        renderCell: isActiveIcon,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
    },
];
