import React, { FC } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, IconButton, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import TextField from 'shared/component/Form/TextField';

import { ServiceAppFormFields } from '../formData';

type NotificationIdsPropsT = {
    control: Control<ServiceAppFormFields>;
    title: string;
    name: keyof Pick<ServiceAppFormFields,
        | 'videoAnalyticsTelegramBotChatIds'
        | 'entryNotificationTelegramBotChatIds'
        | 'acquiringNotificationTelegramBotChatIds'
    >;
}

const NotificationIds: FC<NotificationIdsPropsT> = (props) => {
    const {
        control, title, name,
    } = props;
    const { fields, insert, remove } = useFieldArray<ServiceAppFormFields>({ control, name });

    return (
        <>
            <Typography variant="body1" mt={ 1 }>{ title }</Typography>
            { fields.map((field1, index) => (
                <Grid2 container spacing={ 2 } alignItems="center" key={ field1.id }>
                    <Grid2 xs={ 9.6 }>
                        <TextField
                            name={ `${name}.${index}.id` }
                            control={ control }
                            fullWidth
                        />
                    </Grid2>
                    <Grid2>
                        <IconButton
                            color="info"
                            onClick={ () => insert(index + 1, { id: '' }) }
                        >
                            <AddIcon />
                        </IconButton>
                        <IconButton
                            color="error"
                            onClick={ () => remove(index) }
                        >
                            <RemoveIcon />
                        </IconButton>
                    </Grid2>
                </Grid2>
            )) }
            { fields.length === 0 && (
                <Grid2 sm={ 12 } md={ 2 } mt={ 1 }>
                    <Button
                        variant="outlined"
                        startIcon={ <AddIcon /> }
                        onClick={ () => insert(0, { id: '' }) }
                    >
                        Добавить поле
                    </Button>
                </Grid2>
            ) }
        </>
    );
};

export default NotificationIds;
