import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { CoachDTO } from 'shared/data-hook/coach/fetch/DTO/CoachDTO';
import { CoachCategoryT } from 'types/CoachCategory';

const DTO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        coach_basic_sale_rate: t.number,
    }),
    t.partial({
        coaches: t.array(CoachDTO),
    }),
]);

export type CoachCategoryDTOT = t.TypeOf<typeof DTO>;

export const CoachCategoryDTO = tPromise.extendDecoder<CoachCategoryDTOT, CoachCategoryT>(
    DTO,
    ({
        id,
        name,
        coach_basic_sale_rate,
        coaches,
    }) => ({
        id,
        name,
        basicRate: coach_basic_sale_rate,
        coaches: coaches ?? [],
    }),
) as t.Type<CoachCategoryT, CoachCategoryDTOT>;
