import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { TaskTypeT } from 'types/TaskType';

const DTO = t.type({
    id: t.string,
    name: t.string,
    position: t.number,
    is_active: t.boolean,
    default_should_create_finish_comment: t.boolean,
    default_duration_hours: Nullable(t.number),
    default_title: Nullable(t.string),
    default_description: Nullable(t.string),
});

export type TaskTypeDTOT = t.TypeOf<typeof DTO>;

export const TaskTypeDTO = tPromise.extendDecoder<TaskTypeDTOT, TaskTypeT>(
    DTO,
    ({
        id, name, position, is_active, default_should_create_finish_comment, default_duration_hours, default_title, default_description,
    }) => ({
        id,
        name,
        position,
        isActive: is_active,
        defaultShouldCreateFinishComment: default_should_create_finish_comment,
        defaultDurationHours: default_duration_hours,
        defaultTitle: default_title ?? '',
        defaultDescription: default_description ?? '',
    }),
) as t.Type<TaskTypeT, TaskTypeDTOT>;
