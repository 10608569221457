import { useMutation } from '@apollo/client';
import { TaskStatusFormFields } from 'page/TaskStatus/components/formData';
import {
    TASK_STATUS_UPDATE,
    TaskStatusUpdateResponseDataT,
    TaskStatusUpdateVar,
} from 'shared/graphql/mutation/taskStatus/TaskStatusUpdate';
import { TaskStatusT } from 'types/TaskStatus';

import { TaskStatusUpdateDataT } from './TaskStatusUpdateData';

const useTaskStatusUpdate = (): TaskStatusUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        TaskStatusUpdateResponseDataT,
        TaskStatusUpdateVar
    >(TASK_STATUS_UPDATE);

    const updateAction = async (
        input: TaskStatusFormFields,
        taskStatusId: TaskStatusT['id'],
    ) => {
        if (!taskStatusId) {
            // TODO: use Sentry
            throw new Error('TaskStatus is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: taskStatusId,
                    set: {
                        category: input.category,
                        name: input.name,
                        position: input.position,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        taskStatusUpdate: updateAction,
        loading,
        error,
    };
};

export default useTaskStatusUpdate;
