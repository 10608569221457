import { gql } from '@apollo/client';

export type AgeFields = {
    name: string;
    value: number;
};

export type AgeUpdateVar = {
    id: string;
    set: AgeFields;
};

export type AgeUpdateResponseDataT = {
    updateAge: {
        id: string;
    };
} | null | undefined;

export const AGE_UPDATE = gql`
    mutation updateAge(
        $set: age_set_input = {},
        $id: String = ""
    ) {
        updateAge: update_age_by_pk(
            pk_columns: { id: $id },
            _set: $set
        ) {
            id
        }
    }
`;
