import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { PrtsT } from 'types/Prt';

import { PrtDTO } from './PrtDTO';

const DTO = t.type({
    prts: t.array(PrtDTO),
    prtsCount: AggregateDTO,
});

type PrtDTOT = t.TypeOf<typeof DTO>;

export const PrtsDTO = tPromise.extendDecoder<PrtDTOT, PrtsT>(
    DTO,
    ({
        prts,
        prtsCount,
    }) => ({
        prts,
        prtsCount,
    }),
) as t.Type<PrtsT, PrtDTOT>;
