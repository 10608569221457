import {
    PROPERTY_ACTIONS, PROPERTY_DESCRIPTION, PROPERTY_ORDER,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import useDeletePrt from 'shared/hook/prt/delete/useDeletePrt';
import { CustomHeaderData } from 'types/DataGrid';

import PrtEditButton from '../components/PrtEditButton';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const {
        row: { id, title },
    } = props;
    const { prtDelete } = useDeletePrt();

    return (
        <>
            <PrtEditButton id={ id } />
            <DeleteButton id={ id } title={ title } actionDelete={ prtDelete } />
        </>
    );
};

export const PRT_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        width: 250,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_DESCRIPTION,
        field: 'description',
        flex: 1,
        align: 'left',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams) => (
            <Tooltip
                title={ params.row.description }
                placement="right-start"
                componentsProps={ {
                    tooltip: {
                        style: { fontSize: '16px' },
                    },
                } }
            >
                <span
                    style={ {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    } }
                >
                    { params.row.description }
                </span>
            </Tooltip>
        ),
    },
    {
        headerName: PROPERTY_ORDER,
        field: 'order',
        width: 250,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
