import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { CourtT } from 'types/Court';

const DTO = t.type({
    id: t.string,
    name: t.string,
    order: t.number,
    bonus_classes: t.boolean,
    organization_location: t.partial({
        id: t.string,
        name: t.string,
    }),
    organization: t.partial({
        id: t.string,
        name: t.string,
    }),
    court_type: t.partial({
        id: t.string,
        name: t.string,
    }),
    camera_id: Nullable(t.string),
    schedule: t.array(t.type({
        end: t.string,
        start: t.string,
        day: t.number,
    })),
    enabled_at_months: t.array(t.string),
    promo_category: Nullable(t.number),
});

export type CourtDTOT = t.TypeOf<typeof DTO>;

export const CourtDTO = tPromise.extendDecoder<CourtDTOT, CourtT>(
    DTO,
    ({
        id,
        name,
        order,
        bonus_classes,
        organization_location,
        organization,
        court_type,
        camera_id,
        schedule,
        enabled_at_months,
        promo_category,
    }) => ({
        id,
        name,
        order,
        organizationLocation: organization_location.id || organization_location.name || '',
        courtType: court_type.id || court_type.name || '',
        bonusClasses: bonus_classes,
        schedule,
        organization: organization.id || organization.name || '',
        cameraId: camera_id || '',
        enabledAtMonths: enabled_at_months,
        promoCategory: promo_category ? promo_category.toString() : '',
    }),
) as t.Type<CourtT, CourtDTOT>;
