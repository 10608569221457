import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { MemberCardTypeT } from 'types/MemberCardType';

export type MemberCardTypeQueryVars = {
    id: string;
}

export type MemberCardTypeResponseT = {
    memberCardType: MemberCardTypeT;
};

export type MemberCardTypesResponseT = {
    memberCardTypes: MemberCardTypeT[];
    memberCardTypesCount: AggregateT;
};

export const MEMBER_CARD_TYPE_FILTER_OPTIONS_BY_TITLE_QUERY = gql`
    query MemberCardFilterOptionsByTitle {
        filterOptions: member_card_type(
            order_by: { title: asc }
        ) {
            value: title,
            label: title
        }
    }
`;

export const MEMBER_CARD_TYPE_FILTER_OPTIONS_QUERY = gql`
    query MemberCardFilterOptions {
        filterOptions: member_card_type(
            order_by: { title: asc }
        ) {
            value: id,
            label: title
        }
    }
`;

export const MEMBER_CARD_TYPE_QUERY = gql`
    query MemberCardType($id: String!) {
        memberCardType: member_card_type_by_pk(id: $id) {
            id
            title
            is_active
            color
            expiration_period
            cashback_fiat_operations_limit
            cashback_fiat_sum_limit
        }
    }
`;

export const MEMBER_CARD_TYPES_QUERY = gql`
    query MemberCardTypes(
        $limit: Int,
        $offset: Int,
        $orderBy: member_card_type_order_by! = {},
        $where: member_card_type_bool_exp! = {}
    ) {
        memberCardTypes: member_card_type(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            title
            is_active
            color
            expiration_period
            cashback_fiat_operations_limit
            cashback_fiat_sum_limit
        }
        memberCardTypesCount: member_card_type_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
