import { gql } from '@apollo/client';
import { PrtT } from 'types/Prt';

export type PrtDeleteResponseDataT = {
    deletePrt: {
        id: PrtT['id'];
    };
} | null | undefined;

export type PrtDeleteVarT = {
    id: PrtT['id'];
};

export const PRT_DELETE = gql`
    mutation DeletePrt($id: String = "") {
        deletePrt: delete_prt_by_pk(id: $id) {
            id
        }
    }
`;
