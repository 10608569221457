import { gql } from '@apollo/client';

export type CoachCategoryUpdateVarT = {
    id: string;
    set: CoachCategoryUpdateMutationPayloadT;
};

type CoachCategoryUpdateMutationPayloadT = {
    name: string;
    coach_basic_sale_rate: number;
}

export type CoachCategoryUpdateResponseDataT = {
    updateCoachCategory: {
        id: string;
    };
} | null | undefined;

export const COACH_CATEGORY_UPDATE = gql`
    mutation UpdateCoachCategory($set: coach_category_set_input = {}, $id: String = "") {
        updateCoachCategory: update_coach_category_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
