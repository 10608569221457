export type TaskTypeT = {
    id: string;
    name: string;
    position: number;
    isActive: boolean;
    defaultDurationHours: number | null;
    defaultTitle: string | null;
    defaultDescription: string | null;
    defaultShouldCreateFinishComment: boolean;
};

export type TaskTypesT = {
    taskTypes: TaskTypeT[];
    taskTypesCount: number;
};

export const EMPTY_TASK_TYPES_RESULT: TaskTypesT = {
    taskTypes: [],
    taskTypesCount: 0,
};

export const EMPTY_TASK_TYPE_RESULT: TaskTypeT = {
    id: '',
    name: '',
    position: 0,
    isActive: false,
    defaultDurationHours: 0,
    defaultTitle: '',
    defaultDescription: '',
    defaultShouldCreateFinishComment: false,
};
