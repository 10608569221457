import { ERROR_IMPORT_PRICE_CREATE, SUCCESS_IMPORT_PRICE_CREATE } from 'constants/notificationMessage';

import { Dispatch, SetStateAction, useState } from 'react';
import useImportPriceData from 'shared/data-hook/import/useImportPriceData';
import { useNotification } from 'shared/hook/useNotification';
import { base64FileEncode } from 'shared/utils/base64';
import { useStore } from 'store';

type ReturnT = {
    file: File | null;
    setFile: Dispatch<SetStateAction<File | null>>;
    fileName: string;
    setFileName: Dispatch<SetStateAction<string>>;
    isLoading: boolean;
    send: () => Promise<void>;
};

const useImportPrice = (): ReturnT => {
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { create } = useImportPriceData();
    const { serviceApp: { id: appId } } = useStore('serviceApp');
    const { showSuccess, showError } = useNotification();

    const convert = async (): Promise<string> => {
        if (!file) {
            return '';
        }

        return base64FileEncode(file);
    };

    const send = async (): Promise<void> => {
        setIsLoading(true);

        try {
            const data = await convert();

            const result = await create(appId, data);

            if (!result || result.adminImportPrices.status === 'error') {
                throw new Error();
            }

            showSuccess(SUCCESS_IMPORT_PRICE_CREATE);
        } catch {
            showError(ERROR_IMPORT_PRICE_CREATE);
        } finally {
            setFile(null);
            setFileName('');
            setIsLoading(false);
        }
    };

    return {
        file,
        setFile,
        fileName,
        setFileName,
        isLoading,
        send,
    };
};

export default useImportPrice;
