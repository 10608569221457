import { MODAL_TITLE_UPDATE_COACH_CATEGORY } from 'constants/modalTitles';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Skeleton,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useFetchCoachCategory from 'shared/hook/coachCategory/fetch/fetchOne/useFetchCoachCategory';
import useUpdateCoachCategory from 'shared/hook/coachCategory/update/useUpdateCoachCategory';
import { useStore } from 'store';

import {
    COACH_CATEGORY_DEFAULT_VALUES,
    COACH_CATEGORY_FIELD_KEYS, COACH_CATEGORY_LABEL_DATA,
    COACH_CATEGORY_VALIDATION_SCHEMA,
    CoachCategoryFormFields,
} from './formData';

const UpdateCoachCategoryModal: FC = observer(() => {
    const {
        isOpen, onClose, coachCategory, itemId: coachCategoryId,
    } = useStore('updateCoachCategoryModal');
    const { loading: loadingFetch } = useFetchCoachCategory(coachCategoryId);
    const { updateCoachCategory, loading: loadingUpdate } = useUpdateCoachCategory();
    const {
        control, handleSubmit, reset, setValue, formState: { isValid, isDirty },
    } = useForm<CoachCategoryFormFields>({
        defaultValues: COACH_CATEGORY_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(COACH_CATEGORY_VALIDATION_SCHEMA),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateCoachCategory = (data: CoachCategoryFormFields) => {
        updateCoachCategory(data, coachCategoryId).then(handleCloseModal);
    };

    useEffect(() => {
        if (coachCategory) {
            Object.keys(coachCategory).forEach((key: string) => {
                setValue(key as keyof CoachCategoryFormFields, coachCategory[key as keyof CoachCategoryFormFields] as never);
            });
        }
    }, [coachCategory]);

    return (
        <Modal open={ isOpen } fullWidth={ true } maxWidth="lg">
            <ModalTitle onClose={ onClose }>
                { loadingFetch ? <Skeleton height={ 32 } /> : `${MODAL_TITLE_UPDATE_COACH_CATEGORY} ${coachCategory?.name}` }
            </ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch ? <Skeleton /> : (
                    <Grid2 spacing={ 2 } container padding={ 2 }>
                        <Grid2 xs={ 12 }>
                            <TextField
                                control={ control }
                                name={ COACH_CATEGORY_FIELD_KEYS.name }
                                key={ `key-coachCategory-${coachCategory?.name} ` }
                                label={ COACH_CATEGORY_LABEL_DATA[COACH_CATEGORY_FIELD_KEYS.name] }
                                type="text"
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <TextField
                                control={ control }
                                name={ COACH_CATEGORY_FIELD_KEYS.basicRate }
                                key={ `key-coachCategory-${coachCategory?.basicRate} ` }
                                label={ COACH_CATEGORY_LABEL_DATA[COACH_CATEGORY_FIELD_KEYS.basicRate] }
                                type="number"
                            />
                        </Grid2>
                    </Grid2>
                ) }
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    onClick={ handleSubmit(handleUpdateCoachCategory) }
                    loading={ loadingUpdate }
                    disabled={ !isValid || !isDirty }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateCoachCategoryModal;
