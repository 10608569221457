import { gql } from '@apollo/client';

export type CoachCategoryCreatePayloadT = {
    name: string;
    coach_basic_sale_rate: number;
};

export type CoachCategoryCreateResponseDataT = {
    createCoachCategory: {
        id: string;
    };
} | null | undefined;

export type CoachCategoryCreateVarT = {
    object: CoachCategoryCreatePayloadT;
};

export const COACH_CATEGORY_CREATE = gql`
    mutation CoachCategoryCreate($object: coach_category_insert_input = {}) {
        createCoachCategory: insert_coach_category_one(
            object: $object
        ) {
            id
        }
    }
`;
