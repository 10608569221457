import { action, makeObservable, observable } from 'mobx';
import { CourtT } from 'types/Court';

import BaseModalStore from './BaseModalStore';

export default class UpdateCourtModal extends BaseModalStore {
    public court: CourtT | undefined = undefined;

    public setCourt(court: CourtT) {
        this.court = court;
    }

    constructor() {
        super();

        makeObservable(this, {
            court: observable,
            setCourt: action.bound,
        });
    }
}
