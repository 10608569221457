import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { TASK_STATUSES_QUERY, TaskStatusesResponseT } from 'shared/graphql/query/taskStatus/TaskStatusQuery';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_TASK_STATUSES_RESULT } from 'types/TaskStatus';

import { TaskStatusesDTO } from '../DTO/TaskStatusesDTO';

import { TaskStatusesDataT } from './TaskStatusesData';

const useTaskStatuses = (): TaskStatusesDataT => {
    const [fetch, { data, loading, error }] = useLazyQuery<TaskStatusesResponseT, QueryVarsT>(
        TASK_STATUSES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedTaskStatusesData = decodeDTO(
        TaskStatusesDTO,
        EMPTY_TASK_STATUSES_RESULT,
        (taskStatuses) => taskStatuses,
        {
            taskStatuses: data?.taskStatuses,
            taskStatusesCount: data?.taskStatusesCount,
        },
    );

    const fetchTaskStatuses = async ({
        limit,
        offset,
        orderBy = {},
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        data: decodedTaskStatusesData.taskStatuses,
        count: decodedTaskStatusesData.taskStatusesCount,
        fetchTaskStatuses,
        loading,
        error,
    };
};

export default useTaskStatuses;
