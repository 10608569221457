import { makeAutoObservable } from 'mobx';
import { EMPTY_TASK_TYPE_RESULT, TaskTypeT } from 'types/TaskType';

export default class TaskTypeStore {
    public taskTypes: TaskTypeT[] = [];

    public taskType: TaskTypeT = EMPTY_TASK_TYPE_RESULT;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setTaskTypes(taskTypes: TaskTypeT[], count: number) {
        this.taskTypes = taskTypes;
        this.count = count;
    }

    public setTaskType(taskType: TaskTypeT) {
        this.taskType = taskType;
    }
}
