import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { TaskStatusT } from 'types/TaskStatus';

export type TaskStatusesResponseT = {
    taskStatuses: TaskStatusT[];
    taskStatusesCount: AggregateT;
};

export type TaskStatusResponseT = {
    taskStatus: TaskStatusT;
};

export const TASK_STATUS_QUERY = gql`
    query TaskStatus($id: String!) {
        taskStatus: task_status_by_pk(id: $id) {
            id
            category
            name
            position
        }
    }
`;

export const TASK_STATUSES_QUERY = gql`
    query TaskStatuses(
        $limit: Int
        $offset: Int
        $orderBy: task_status_order_by! = {}
        $where: task_status_bool_exp! = {}
    ) {
        taskStatuses: task_status(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            category
            name
            position
        }
        taskStatusesCount: task_status_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
