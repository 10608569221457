import {
    PROPERTY_ACTIONS,
    PROPERTY_POSITION, PROPERTY_TASK_CATEGORY,
    PROPERTY_TITLE,
} from 'constants/propertyNames';
import { TASK_CATEGORIES_OPTIONS } from 'constants/taskCategories';

import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import useDeleteTaskStatus from 'shared/hook/taskStatus/delete/useDeleteTaskStatus';
import { CustomHeaderData } from 'types/DataGrid';

import TaskStatusEditButton from '../components/TaskStatusEditButton';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const {
        row: { id, title },
    } = props;
    const { taskStatusDelete } = useDeleteTaskStatus();

    return (
        <>
            <TaskStatusEditButton id={ id } />
            <DeleteButton id={ id } title={ title } actionDelete={ taskStatusDelete } />
        </>
    );
};

export const TASK_STATUS_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TASK_CATEGORY,
        field: 'category',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        valueOptions: TASK_CATEGORIES_OPTIONS,
    },
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_POSITION,
        field: 'position',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
