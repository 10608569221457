import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { MemberCardTypeT } from 'types/MemberCardType';

const DTO = t.type({
    id: t.string,
    title: t.string,
    is_active: t.boolean,
    color: Nullable(t.string),
    expiration_period: t.number,
    cashback_fiat_operations_limit: t.number,
    cashback_fiat_sum_limit: t.number,
});

export type MemberCardTypeDTOT = t.TypeOf<typeof DTO>;

export const MemberCardTypeDTO = tPromise.extendDecoder<MemberCardTypeDTOT, MemberCardTypeT>(
    DTO,
    ({
        id, title, is_active, expiration_period, cashback_fiat_operations_limit, cashback_fiat_sum_limit, color,
    }) => {
        return {
            id,
            title,
            isActive: is_active,
            color: color || '',
            expirationPeriod: expiration_period,
            cashbackFiatOperationsLimit: cashback_fiat_operations_limit,
            cashbackFiatSumLimit: cashback_fiat_sum_limit,
        };
    },
) as t.Type<MemberCardTypeT, MemberCardTypeDTOT>;
