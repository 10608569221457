import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceMemberCardFormFields } from 'page/PriceMemberCard/components/formData';

const DTO = t.type({
    member_card_type_id: t.string,
    price_season_id: t.string,
    card_price: t.number,
    price_type_id: t.string,
    discount_rates: t.array(t.type({
        price_sales_round_id: t.string,
        discount_percent_fiat: t.number,
        discount_percent_bonus: t.number,
        multiplying_factor: t.number,
        discount_percent_bonus_per_free_slot: t.number,
        cashback_percent_bonus: t.number,
        cashback_percent_fiat: t.number,
    })),
    discount_full_returns: t.array(t.type({
        enabled_at_months: t.array(t.string),
        full_return_quantity_limit: t.number,
        price_sales_round_ids: t.array(t.string),
        is_performer_income_recalculating: t.boolean,
    })),
});

export type UpdatePriceMemberCardTypeDTOT = t.TypeOf<typeof DTO>;

type UpdatePriceMemberCardTypeT = PriceMemberCardFormFields & {
    priceSeasonId: string;
    memberCardTypeId: string;
    priceTypeId: string;
}

export const UpdatePriceMemberCardTypeDTO = tPromise.extendType<UpdatePriceMemberCardTypeDTOT, UpdatePriceMemberCardTypeT>(
    DTO,
    () => ({
        price: 0,
        memberCardTypeDiscountRates: [],
        memberCardTypeDiscountFullReturns: [],
        priceSeasonId: '',
        memberCardTypeId: '',
        priceTypeId: '',
    }),
    (
        {
            price, memberCardTypeDiscountRates, priceSeasonId, memberCardTypeId, memberCardTypeDiscountFullReturns, priceTypeId,
        },
    ) => {
        const discountRates = memberCardTypeDiscountRates.map((item) => ({
            price_sales_round_id: item.priceSalesRoundId,
            discount_percent_fiat: item.discountPercentFiat,
            discount_percent_bonus: item.discountPercentBonus,
            discount_percent_bonus_per_free_slot: item.discountPercentBonusPerFreeSlot,
            multiplying_factor: item.multiplyingFactor,
            cashback_percent_bonus: item.cashbackPercentBonus,
            cashback_percent_fiat: item.cashbackPercentFiat,
        }));

        const fullReturns = memberCardTypeDiscountFullReturns.map((item) => ({
            enabled_at_months: item.enabledAtMonths,
            full_return_quantity_limit: item.fullReturnQuantityLimit,
            price_sales_round_ids: item.memberCardTypeDiscountFullReturnPriceSalesRounds.map((id) => id),
            is_performer_income_recalculating: item.isPerformerIncomeRecalculating,
        }));

        return {
            member_card_type_id: memberCardTypeId,
            price_season_id: priceSeasonId,
            price_type_id: priceTypeId,
            card_price: price,
            discount_rates: discountRates,
            discount_full_returns: fullReturns,
        };
    },
) as t.Type<UpdatePriceMemberCardTypeT, UpdatePriceMemberCardTypeDTOT>;
