import {
    PROPERTY_DESCRIPTION, PROPERTY_ORDER,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import { number, object, string } from 'yup';

export type PrtFormFields = {
    name: string;
    description?: string;
    order: number;
};

export const PRT_DEFAULT_VALUES: PrtFormFields = {
    name: '',
    description: '',
    order: 0,
};

export const PRT_LABEL_DATA = {
    name: PROPERTY_TITLE,
    description: PROPERTY_DESCRIPTION,
    order: PROPERTY_ORDER,
};

export enum PRT_FIELD_KEYS {
    name = 'name',
    description = 'description',
    order = 'order',
}

export const getPrtValidationSchema = () =>
    object()
        .shape({
            name: string().required(),
            description: string(),
            order: number().required(),
        })
        .required();
