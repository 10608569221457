export const TASK_TODO = 'TODO';
export const TASK_WIP = 'WIP';
export const TASK_DONE = 'DONE';

export const TASK_CATEGORIES_OPTIONS = [
    {
        value: TASK_TODO,
        label: TASK_TODO,
    },
    {
        value: TASK_WIP,
        label: TASK_WIP,
    },
    {
        value: TASK_DONE,
        label: TASK_DONE,
    },
];
