import { gql } from '@apollo/client';
import { AcquiringSettingT } from 'types/AcquiringSetting';
import { AggregateT } from 'types/Graphql';

export type AcquiringSettingsResponseT = {
    acquiringSettings: AcquiringSettingT[];
    acquiringSettingsCount: AggregateT;
};

export type AcquiringSettingResponseT = {
    acquiringSetting: AcquiringSettingT;
};

export const ACQUIRING_SETTING_QUERY = gql`
    query AcquiringSetting($id: String!) {
        acquiringSetting: service_app_acquiring_setting_by_pk(id: $id) {
            id
            type
            api_login
            api_password
            api_token
            api_url
            payment_method {
                id
                title
            }
            payment_type {
                id
                title
            }
            service_app {
                id
                label
            }
        }
    }
`;

export const ACQUIRING_SETTINGS_QUERY = gql`
    query AcquiringSettings(
        $limit: Int
        $offset: Int
        $orderBy: service_app_acquiring_setting_order_by! = {}
        $where: service_app_acquiring_setting_bool_exp! = {}
    ) {
        acquiringSettings: service_app_acquiring_setting(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            type
            api_login
            api_password
            api_token
            api_url
            id
            payment_method {
                id
                title
            }
            payment_type {
                id
                title
            }
            service_app {
                id
                label
            }
        }
        acquiringSettingsCount: service_app_acquiring_setting_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
