import {
    PROPERTY_BONUS_CLASSES,
    PROPERTY_CAMERA_ID, PROPERTY_COURT_ENABLED_AT_MONTH,
    PROPERTY_ORDER,
    PROPERTY_ORGANIZATION, PROPERTY_ORGANIZATION_LOCATION, PROPERTY_PROMO_CATEGORY, PROPERTY_SCHEDULE,
    PROPERTY_TITLE, PROPERTY_TYPE,
} from 'constants/propertyNames';

import { COMMON_FORM_ERRORS } from 'config/FormErrors';
import { FormErrorMessages } from 'enums/FormErrorMessages';
import {
    array, number, object, string,
} from 'yup';

export type CourtFormFields = {
    name: string;
    order: number;
    schedule: Array<{
        day: number;
        end: string;
        start: string;
    }>;
    organizationLocation: string;
    organization: string;
    bonusClasses: boolean;
    courtType: string;
    cameraId: string;
    enabledAtMonths: string[];
    promoCategory: string;
}

export const COURT_DEFAULT_VALUES = {
    name: '',
    order: 0,
    schedule: [],
    bonusClasses: false,
    organization: '',
    organizationLocation: '',
    courtType: '',
    cameraId: '',
    enabledAtMonths: [],
    promoCategory: '',
};

export enum COURT_FIELD_KEYS {
    name = 'name',
    order = 'order',
    schedule = 'schedule',
    bonusClasses = 'bonusClasses',
    organization = 'organization',
    organizationLocation = 'organizationLocation',
    courtType = 'courtType',
    cameraId = 'cameraId',
    enabledAtMonths = 'enabledAtMonths',
    promoCategory = 'promoCategory',
}

type CourtLabelDataT = Record<keyof CourtFormFields, string>

export const COURT_LABEL_DATA: CourtLabelDataT = {
    name: PROPERTY_TITLE,
    order: PROPERTY_ORDER,
    schedule: PROPERTY_SCHEDULE,
    cameraId: PROPERTY_CAMERA_ID,
    organization: PROPERTY_ORGANIZATION,
    bonusClasses: PROPERTY_BONUS_CLASSES,
    organizationLocation: PROPERTY_ORGANIZATION_LOCATION,
    courtType: PROPERTY_TYPE,
    enabledAtMonths: PROPERTY_COURT_ENABLED_AT_MONTH,
    promoCategory: PROPERTY_PROMO_CATEGORY,
};

const SCHEDULE_SCHEMA = object().shape({
    day: number().required(),
    start: string().required(COMMON_FORM_ERRORS[FormErrorMessages.Required]).test({
        name: 'isLarger',
        message: 'Время начала >= время окончания',
        test: (value, testContext) => {
            const { end } = testContext.parent;

            if (end && value) {
                return end >= value;
            }

            return true;
        },
    }),
    end: string().required(COMMON_FORM_ERRORS[FormErrorMessages.Required]).test({
        name: 'isLarger',
        message: 'Время окончания <= время начала',
        test: (value, testContext) => {
            const { start } = testContext.parent;

            if (start && value) {
                return start <= value;
            }

            return true;
        },
    }),
});

export const COURT_VALIDATION_SCHEMA = object().shape({
    name: string().required(),
    organizationLocation: string().required(),
    courtType: string().required(),
    organization: string().required(),
    schedule: array().of(SCHEDULE_SCHEMA),
    enabledAtMonths: array().of(string()).min(1),
    promoCategory: number().nullable().transform((v, o) => (o ? v : null)),
}).required();
