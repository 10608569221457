import { useMutation } from '@apollo/client';
import { AGE_DELETE, AgeDeleteResponseDataT, AgeDeleteVarT } from 'shared/graphql/mutation/age/AgeDelete';
import { AgeT } from 'types/Age';

import { AgeDeleteDataT } from './AgeDeleteData';

const useAgeDelete = (): AgeDeleteDataT => {
    const [ageDelete, { loading, error }] = useMutation<
        AgeDeleteResponseDataT,
        AgeDeleteVarT
    >(AGE_DELETE);

    const deleteAction = async (id: AgeT['id']) => {
        try {
            const { data } = await ageDelete({
                variables: {
                    id,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteAge: deleteAction,
        loading,
        error,
    };
};

export default useAgeDelete;
