export const OPERATION_SELL_LABEL = 'Покупка';
export const OPERATION_CANCEL_LABEL = 'Отмена';
export const OPERATION_TRANSFER_LABEL = 'Перенос';
export const OPERATION_SELL = 'sell';
export const OPERATION_CANCEL = 'cancel';
export const OPERATION_TRANSFER = 'transfer';

export const OPERATIONS_OPTIONS = [
    {
        value: OPERATION_CANCEL,
        label: OPERATION_CANCEL_LABEL,
    },
    {
        value: OPERATION_SELL,
        label: OPERATION_SELL_LABEL,
    },
    {
        value: OPERATION_TRANSFER,
        label: OPERATION_TRANSFER_LABEL,
    },
];
