import { useMutation } from '@apollo/client';
import { TaskTypeFormFields } from 'page/TaskType/components/formData';
import {
    CREATE_TASK_TYPE,
    CreateTaskTypeResponseT,
    CreateTaskTypeT,
    CreateTaskTypeVarT, EMPTY_CREATE_TASK_TYPE_RESPONSE,
} from 'shared/graphql/mutation/taskType/TaskTypeCreate';

import { CreateTaskTypeDataT } from './CreateTaskTypeData';

const useTaskTypeCreate = (): CreateTaskTypeDataT => {
    const [create, { loading, error }] = useMutation<CreateTaskTypeT, CreateTaskTypeVarT>(
        CREATE_TASK_TYPE,
    );

    const createTaskType = async (input: TaskTypeFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        name: input.name,
                        position: input.position,
                        is_active: input.isActive,
                        default_should_create_finish_comment: input.defaultShouldCreateFinishComment,
                        default_duration_hours: input.defaultDurationHours || null,
                        default_title: input.defaultTitle || null,
                        default_description: input.defaultDescription || null,
                    },
                },
            });

            return data?.adminTaskTypeCreate as CreateTaskTypeResponseT;
        } catch {
            return EMPTY_CREATE_TASK_TYPE_RESPONSE;
        }
    };

    return {
        createTaskType,
        loading,
        error,
    };
};

export default useTaskTypeCreate;
