import React, { FC } from 'react';

const ExportIcon: FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00049 11.0273C7.72449 11.0273 7.50049 10.8033 7.50049 10.5273V2.5C7.50049 2.224 7.72449 2 8.00049 2C8.27649 2 8.50049 2.224 8.50049 2.5V10.5273C8.50049 10.8033 8.27649 11.0273 8.00049 11.0273Z"
                fill="#128FC9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00071 11.0275C7.86805 11.0275 7.74005 10.9749 7.64671 10.8802L5.70271 8.92885C5.50805 8.73285 5.50871 8.41618 5.70405 8.22152C5.90005 8.02685 6.21605 8.02685 6.41071 8.22285L8.00071 9.81952L9.59071 8.22285C9.78538 8.02685 10.1014 8.02685 10.2974 8.22152C10.4927 8.41618 10.4934 8.73285 10.2987 8.92885L8.35471 10.8802C8.26138 10.9749 8.13338 11.0275 8.00071 11.0275Z"
                fill="#128FC9"
            />
            <mask
                id="mask0_15703_118943"
                maskUnits="userSpaceOnUse"
                x="1"
                y="5"
                width="14"
                height="10"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.3335 5.15479H14.6667V14.3175H1.3335V5.15479Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_15703_118943)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.7168 14.3175H4.29016C2.66016 14.3175 1.3335 12.9915 1.3335 11.3608V8.10412C1.3335 6.47745 2.65683 5.15479 4.28416 5.15479H4.9115C5.1875 5.15479 5.4115 5.37879 5.4115 5.65479C5.4115 5.93079 5.1875 6.15479 4.9115 6.15479H4.28416C3.20816 6.15479 2.3335 7.02878 2.3335 8.10412V11.3608C2.3335 12.4401 3.21083 13.3175 4.29016 13.3175H11.7168C12.7915 13.3175 13.6668 12.4421 13.6668 11.3675V8.11145C13.6668 7.03212 12.7888 6.15479 11.7108 6.15479H11.0895C10.8135 6.15479 10.5895 5.93079 10.5895 5.65479C10.5895 5.37879 10.8135 5.15479 11.0895 5.15479H11.7108C13.3408 5.15479 14.6668 6.48145 14.6668 8.11145V11.3675C14.6668 12.9941 13.3428 14.3175 11.7168 14.3175Z"
                    fill="#128FC9"
                />
            </g>
        </svg>
    );
};

export default ExportIcon;
