import React, { FC, useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import TextField from 'shared/component/Form/TextField';
import { PrtT } from 'types/Prt';

import {
    PRT_FIELD_KEYS,
    PRT_LABEL_DATA,
    PrtFormFields,
} from './formData';

type PropsT = {
    control: Control<PrtFormFields>;
    setValue?: UseFormSetValue<PrtFormFields>;
    prt?: PrtT;
    isUpdate?: boolean;
};

const PrtForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, prt, isUpdate,
    } = props;

    useEffect(() => {
        if (isUpdate && prt && setValue) {
            Object.keys(prt).forEach((key) => {
                setValue(key as keyof PrtFormFields, prt[key as keyof PrtFormFields as never]);
            });
        }
    }, [prt, isUpdate]);

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ PRT_FIELD_KEYS.name }
                    label={ PRT_LABEL_DATA.name }
                    type="text"
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ PRT_FIELD_KEYS.description }
                    label={ PRT_LABEL_DATA.description }
                    type="text"
                    multiline={ true }
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ PRT_FIELD_KEYS.order }
                    label={ PRT_LABEL_DATA.order }
                    type="number"
                />
            </Grid2>
        </Grid2>
    );
});

export default PrtForm;
