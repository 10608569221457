import {
    ERROR_TASK_STATUS_DELETE,
    SUCCESS_TASK_STATUS_DELETE,
} from 'constants/notificationMessage';

import useTaskStatusDelete from 'shared/data-hook/taskStatus/delete/single/useTaskStatusDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { TaskStatusT } from 'types/TaskStatus';

const useDeleteTaskStatus = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteTaskStatus } = useTaskStatusDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const taskStatusDelete = async (taskStatusId: TaskStatusT['id']): Promise<boolean> => {
        const response = await deleteTaskStatus(taskStatusId);

        if (error || !response || !response.deleteTaskStatus) {
            showError(ERROR_TASK_STATUS_DELETE);

            return false;
        }

        showSuccess(SUCCESS_TASK_STATUS_DELETE);
        toggleRefetchTrigger();

        return true;
    };

    return { taskStatusDelete };
};

export default useDeleteTaskStatus;
