import { TypedDocumentNode, useQuery } from '@apollo/client';
import { EMPTY_FILTER_OPTIONS, FilterOptionsResponseT } from 'types/DataGrid';
import { FetchFilterOptionsDataT } from 'types/filterOptions/FetchFilterOptionsData';

import decodeDTO from '../utils/DTO/decodeDTO';

import { FilterOptionsDTO } from './DTO/filterOptionsDTO';

const useFetchFilterOptionsData = (query: TypedDocumentNode, filter?: any): FetchFilterOptionsDataT => {
    const {
        data, loading, refetch, networkStatus,
    } = useQuery<FilterOptionsResponseT>(query, {
        fetchPolicy: 'network-only',
        variables: {
            filter,
        },
        notifyOnNetworkStatusChange: true,
    });

    const decodedOptionsData = decodeDTO(
        FilterOptionsDTO,
        EMPTY_FILTER_OPTIONS,
        (filterOptions) => filterOptions as any,
        {
            filterOptions: data?.filterOptions,
        },
    );

    return {
        data: decodedOptionsData?.filterOptions,
        loading,
        refetch,
        networkStatus,
    };
};

export default useFetchFilterOptionsData;
