import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { TaskStatusT } from 'types/TaskStatus';

const DTO = t.type({
    id: t.string,
    category: t.string,
    name: t.string,
    position: t.number,
});

export type TaskStatusDTOT = t.TypeOf<typeof DTO>;

export const TaskStatusDTO = tPromise.extendDecoder<TaskStatusDTOT, TaskStatusT>(
    DTO,
    ({
        id, category, name, position,
    }) => ({
        id,
        category,
        name,
        position,
    }),
) as t.Type<TaskStatusT, TaskStatusDTOT>;
