import { action, makeObservable, observable } from 'mobx';
import { PaymentDocumentT } from 'types/PaymentDocument';

import BaseModalStore from './BaseModalStore';

export default class UpdatePaymentDocumentModal extends BaseModalStore {
    public paymentDocument: PaymentDocumentT | undefined = undefined;

    public setPaymentDocument(paymentDocument: PaymentDocumentT) {
        this.paymentDocument = paymentDocument;
    }

    constructor() {
        super();

        makeObservable(this, {
            paymentDocument: observable,
            setPaymentDocument: action.bound,
        });
    }
}
