import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { PRICE_SEASONS_QUERY, PriceSeasonsResponseT } from 'shared/graphql/query/priceSeason/PriceSeasonQuery';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_PRICE_SEASONS_RESULT } from 'types/PriceSeason';

import { PriceSeasonsDTO } from '../DTO/PriceSeasonsDTO';

import { PriceSeasonsDataT } from './PriceSeasonsData';

const usePriceSeasons = (): PriceSeasonsDataT => {
    const [fetch, { data: priceSeasonData, loading }] = useLazyQuery<PriceSeasonsResponseT, QueryVarsT>(
        PRICE_SEASONS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedSeasonData = decodeDTO(
        PriceSeasonsDTO,
        EMPTY_PRICE_SEASONS_RESULT,
        (priceSeason) => priceSeason,
        {
            priceSeasons: priceSeasonData?.priceSeasons,
            priceSeasonsCount: priceSeasonData?.priceSeasonsCount,
        },
    );

    const fetchPriceSeasons = async ({
        limit, offset, orderBy = { start_at: 'desc' }, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: decodedSeasonData.priceSeasons,
        count: decodedSeasonData.priceSeasonsCount,
        fetchPriceSeasons,
        loading,
    };
};

export default usePriceSeasons;
