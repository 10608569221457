import { DATE_TIME_FORMAT_FOR_DATA_GRID } from 'constants/date';
import { OPERATIONS_OPTIONS } from 'constants/operations';
import {
    PROPERTY_ACTIONS,
    PROPERTY_CATEGORY,
    PROPERTY_CLIENT_FIRST_NAME,
    PROPERTY_CLIENT_LAST_NAME,
    PROPERTY_CLIENT_MIDDLE_NAME,
    PROPERTY_DATETIME,
    PROPERTY_DATETIME_OPERATION,
    PROPERTY_DISCOUNT,
    PROPERTY_DOCUMENT,
    PROPERTY_DURATION,
    PROPERTY_OPERATION, PROPERTY_PAYER_FIRST_NAME, PROPERTY_PAYER_LAST_NAME, PROPERTY_PAYER_MIDDLE_NAME,
    PROPERTY_PERFORMER,
    PROPERTY_PLACE,
    PROPERTY_RATE,
    PROPERTY_SERVICE,
    PROPERTY_TITLE,
    PROPERTY_TOTAL,
    PROPERTY_TYPE,
    PROPERTY_USER,
} from 'constants/propertyNames';
import { SERVICES_OPTIONS } from 'constants/services';

import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { CustomHeaderData } from 'types/DataGrid';

import ImplementationEditButton from '../components/ImplementationEditButton';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const {
        row: { documentId },
    } = props;

    return (
        <ImplementationEditButton id={ documentId } />
    );
};

export const IMPLEMENTATION_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_CLIENT_LAST_NAME,
        field: 'clientLastName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.lastName),
        hasuraFilterFields: ['client_datum', 'client', 'last_name'],
        hasuraSortFields: ['client_datum', 'client', 'last_name'],
    },
    {
        headerName: PROPERTY_CLIENT_FIRST_NAME,
        field: 'clientFirstName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.firstName),
        hasuraFilterFields: ['client_datum', 'client', 'first_name'],
        hasuraSortFields: ['client_datum', 'client', 'first_name'],
    },
    {
        headerName: PROPERTY_CLIENT_MIDDLE_NAME,
        field: 'clientMiddleName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.middleName),
        hasuraFilterFields: ['client_datum', 'client', 'middle_name'],
        hasuraSortFields: ['client_datum', 'client', 'middle_name'],
    },
    {
        headerName: PROPERTY_PAYER_LAST_NAME,
        field: 'payerLastName',
        flex: 1,
        minWidth: 110,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.payer?.lastName),
        hasuraFilterFields: ['clientDatumByPayerId', 'client', 'last_name'],
        hasuraSortFields: ['clientDatumByPayerId', 'client', 'last_name'],
    },
    {
        headerName: PROPERTY_PAYER_FIRST_NAME,
        field: 'payerFirstName',
        flex: 1,
        minWidth: 110,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.payer?.firstName),
        hasuraFilterFields: ['clientDatumByPayerId', 'client', 'first_name'],
        hasuraSortFields: ['clientDatumByPayerId', 'client', 'first_name'],
    },
    {
        headerName: PROPERTY_PAYER_MIDDLE_NAME,
        field: 'payerMiddleName',
        flex: 1,
        minWidth: 110,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.payer?.middleName),
        hasuraFilterFields: ['clientDatumByPayerId', 'client', 'middle_name'],
        hasuraSortFields: ['clientDatumByPayerId', 'client', 'middle_name'],
    },
    {
        headerName: PROPERTY_CATEGORY,
        field: 'serviceCategory',
        minWidth: 150,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.serviceCategory),
        hasuraFilterFields: ['service_category', 'id'],
        hasuraSortFields: ['service_category', 'name'],
    },
    {
        headerName: PROPERTY_TYPE,
        field: 'priceType',
        minWidth: 100,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.priceType),
        hasuraFilterFields: ['price_type', 'id'],
        hasuraSortFields: ['price_type', 'title'],
    },
    {
        headerName: PROPERTY_USER,
        field: 'user',
        minWidth: 200,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.user),
        hasuraFilterFields: ['user', 'full_name'],
        hasuraSortFields: ['user', 'full_name'],
    },
    {
        headerName: PROPERTY_OPERATION,
        field: 'operation',
        minWidth: 100,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        valueOptions: OPERATIONS_OPTIONS,
    },
    {
        headerName: PROPERTY_SERVICE,
        field: 'service',
        minWidth: 100,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        valueOptions: SERVICES_OPTIONS,
    },
    {
        headerName: PROPERTY_PLACE,
        field: 'place',
        minWidth: 100,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.place),
    },
    {
        headerName: PROPERTY_PERFORMER,
        field: 'performer',
        minWidth: 150,
        flex: 1,
        align: 'left',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.performer),
    },
    {
        headerName: PROPERTY_RATE,
        field: 'rate',
        minWidth: 100,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        headerName: PROPERTY_TOTAL,
        field: 'total',
        minWidth: 150,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        headerName: PROPERTY_DURATION,
        field: 'duration',
        minWidth: 180,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        headerName: PROPERTY_DISCOUNT,
        field: 'discount',
        minWidth: 100,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        headerName: PROPERTY_DOCUMENT,
        field: 'document',
        minWidth: 400,
        flex: 1,
        align: 'left',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_DATETIME,
        field: 'datetime',
        align: 'left',
        headerAlign: 'center',
        minWidth: 200,
        flex: 2,
        renderCell: (params) => renderDateToGrid(params.row?.datetime, DATE_TIME_FORMAT_FOR_DATA_GRID),
        type: 'date',
    },
    {
        headerName: PROPERTY_DATETIME_OPERATION,
        field: 'datetimeOperation',
        minWidth: 200,
        flex: 2,
        align: 'left',
        headerAlign: 'center',
        renderCell: (params) => renderDateToGrid(params.row?.datetimeOperation, DATE_TIME_FORMAT_FOR_DATA_GRID),
        type: 'date',
    },
    {
        headerName: PROPERTY_TITLE,
        field: 'title',
        minWidth: 200,
        flex: 1,
        align: 'left',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.title),
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
