import { PROPERTY_TASK_CATEGORY } from 'constants/propertyNames';
import { TASK_CATEGORIES_OPTIONS } from 'constants/taskCategories';

import React, { FC, useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import TextField from 'shared/component/Form/TextField';
import { TaskStatusT } from 'types/TaskStatus';

import {
    TASK_STATUS_FIELD_KEYS,
    TASK_STATUS_LABEL_DATA,
    TaskStatusFormFields,
} from './formData';

type PropsT = {
    control: Control<TaskStatusFormFields>;
    setValue?: UseFormSetValue<TaskStatusFormFields>;
    taskStatus?: TaskStatusT;
    isUpdate?: boolean;
};

const TaskStatusForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, taskStatus, isUpdate,
    } = props;

    useEffect(() => {
        if (isUpdate && taskStatus && setValue) {
            Object.keys(taskStatus).forEach((key) => {
                setValue(key as keyof TaskStatusFormFields, taskStatus[key as keyof TaskStatusFormFields as never]);
            });
        }
    }, [taskStatus, isUpdate]);

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            <Grid2>
                <Select
                    options={ TASK_CATEGORIES_OPTIONS }
                    name={ TASK_STATUS_FIELD_KEYS.category }
                    control={ control }
                    size="small"
                    label={ PROPERTY_TASK_CATEGORY }
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ TASK_STATUS_FIELD_KEYS.name }
                    label={ TASK_STATUS_LABEL_DATA.name }
                    type="text"
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ TASK_STATUS_FIELD_KEYS.position }
                    label={ TASK_STATUS_LABEL_DATA.position }
                    type="number"
                />
            </Grid2>
        </Grid2>
    );
});

export default TaskStatusForm;
