import { EMPTY_CELL } from 'constants/dataGrid';

import { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';

const RenderLongStringCell = (value?: string | null, maxLines = 5, lineHeight = 20) => {
    if (!value) {
        return <div>{ EMPTY_CELL }</div>;
    }

    const [isExpanded, setIsExpanded] = useState(false);
    const [isTextLong, setIsTextLong] = useState(false);
    const textRef = useRef<HTMLDivElement | null>(null);
    const apiRef = useGridApiContext();

    useEffect(() => {
        if (textRef.current) {
            const contentHeight = textRef.current.scrollHeight;
            const visibleHeight = maxLines * lineHeight;

            setIsTextLong(contentHeight > visibleHeight);
        }
    }, [value]);

    const handleToggleExpand = () => {
        setIsExpanded((prev) => !prev);

        if (apiRef.current) {
            apiRef.current.resetRowHeights();
        }
    };

    return (
        <div>
            <div
                ref={ textRef }
                style={ {
                    maxHeight: isExpanded ? 'none' : `${maxLines * lineHeight}px`,
                    overflow: 'hidden',
                    whiteSpace: 'normal',
                } }
            >
                { value }
            </div>

            { isTextLong && (
                <Button
                    size="small"
                    onClick={ handleToggleExpand }
                    sx={ { textTransform: 'none', mt: 1 } }
                >
                    { isExpanded ? 'Скрыть' : 'Показать еще' }
                </Button>
            ) }
        </div>
    );
};

export default RenderLongStringCell;
