import { MODAL_TITLE_UPDATE_PAYMENT_DOCUMENT } from 'constants/modalTitles';

import React, { FC, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
    LoadingButton, TabContext, TabList, TabPanel,
} from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Skeleton, Tab,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import PaymentFields from 'page/Payment/components/PaymentFields';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useFetchPaymentDocument from 'shared/hook/paymentDocument/fetch/fetchOne/useFetchPaymentDocument';
import useUpdatePaymentDocument from 'shared/hook/paymentDocument/update/useUpdatePaymentDocument';
import { useStore } from 'store';

import {
    PAYMENT_DOCUMENT_DEFAULT_VALUES,
    PAYMENT_DOCUMENT_FIELD_KEYS,
    PAYMENT_DOCUMENT_LABEL_DATA,
    PaymentDocumentFormFields,
} from './formData';

const UpdatePaymentDocumentModal: FC = observer(() => {
    const {
        isOpen, onClose, itemId, paymentDocument,
    } = useStore('updatePaymentDocumentModal');
    const { loading } = useFetchPaymentDocument(itemId);
    const { updatePaymentDocument } = useUpdatePaymentDocument();
    const [tab, setTab] = useState('');

    const {
        control, formState: { isValid, isDirty }, handleSubmit, setValue, reset,
    } = useForm<PaymentDocumentFormFields>({
        defaultValues: PAYMENT_DOCUMENT_DEFAULT_VALUES,
        mode: 'onBlur',
    });

    const { fields } = useFieldArray<PaymentDocumentFormFields>({
        control,
        name: 'payments',
    });

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleUpdatePayment = (data: PaymentDocumentFormFields) => {
        updatePaymentDocument(data, itemId).then(handleClose);
    };

    useEffect(() => {
        if (paymentDocument) {
            setTab(paymentDocument?.payments[0].clientId || '');

            Object.keys(paymentDocument).forEach((key: string) => {
                setValue(key as keyof PaymentDocumentFormFields, paymentDocument[key as keyof PaymentDocumentFormFields as never]);
            });
        }
    }, [paymentDocument]);

    return (
        <Modal open={ isOpen } maxWidth="lg">
            <ModalTitle onClose={ handleClose }>
                {
                    loading
                        ? <Skeleton height={ 32 } />
                        : `${MODAL_TITLE_UPDATE_PAYMENT_DOCUMENT} ${paymentDocument?.number}`
                }
            </ModalTitle>
            <DialogContent>
                { loading ? <Skeleton height={ 32 } /> : (
                    <Grid2 container xs={ 12 } columnSpacing={ 2 }>
                        <Grid2 xs={ 12 }>
                            <TextField
                                label={ PAYMENT_DOCUMENT_LABEL_DATA.comment }
                                name={ PAYMENT_DOCUMENT_FIELD_KEYS.comment }
                                control={ control }
                                size="small"
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <TabContext value={ tab }>
                                <TabList>
                                    { fields.map((payment) => (
                                        <Tab
                                            key={ payment.id }
                                            label={ payment.clientDatum?.fullName }
                                            value={ payment?.clientId || '' }
                                            onClick={ () => setTab(payment.clientId || '') }
                                        />
                                    )) }
                                </TabList>
                                { fields.map((payment, index) => (
                                    <TabPanel value={ payment?.clientId || '' } key={ payment.id }>
                                        <PaymentFields
                                            index={ index }
                                            control={ control }
                                            payment={ payment }
                                            setValue={ setValue }
                                        />
                                    </TabPanel>
                                )) }
                            </TabContext>
                        </Grid2>
                    </Grid2>
                ) }
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose }>
                    Отмена
                </Button>
                <LoadingButton
                    variant="contained"
                    color="success"
                    autoFocus
                    disabled={ !isValid || !isDirty }
                    onClick={ handleSubmit(handleUpdatePayment) }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdatePaymentDocumentModal;
