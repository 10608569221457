import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AgeT } from 'types/Age';

const DTO = t.type({
    id: t.string,
    name: t.string,
    value: t.number,
});

export type AgeDTOT = t.TypeOf<typeof DTO>;

export const AgeDTO = tPromise.extendDecoder<AgeDTOT, AgeT>(
    DTO,
    ({
        id, name, value,
    }) => ({
        id,
        name,
        value,
    }),
) as t.Type<AgeT, AgeDTOT>;
