import { useMutation } from '@apollo/client';
import {
    IMPORT_PRICE_MUTATION,
    ImportPriceResponseDataT,
    ImportPriceVarT,
} from 'shared/graphql/mutation/import/ImportPrice';

import { ImportPriceDataT } from './ImportPriceData';

const useImportPriceData = (): ImportPriceDataT => {
    const [create, { loading, error }] = useMutation<ImportPriceResponseDataT, ImportPriceVarT>(
        IMPORT_PRICE_MUTATION,
    );

    const action = async (appId: string, data: string): Promise<ImportPriceResponseDataT> => {
        try {
            const { data: createData } = await create({
                variables: {
                    appId,
                    data,
                },
            });

            return createData;
        } catch (actionError) {
            // eslint-disable-next-line no-console
            console.error((actionError as Error).message);
            throw actionError;
        }
    };

    return {
        create: action,
        error,
        loading,
    };
};

export default useImportPriceData;
