import { gql } from '@apollo/client';

export type UserT = {
    activeClientId: string | null;
};

export type UserResponseT = {
    user: UserT;
};

export const USER_QUERY = gql`
    query User($id: String!) {
        user: user_by_pk(id: $id) {
            active_client_id
        }
    }
`;
