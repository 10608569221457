import { gql } from '@apollo/client';

export type TaskTypeFields = {
    name: string;
    position: number;
    is_active: boolean;
    default_duration_hours: number | null;
    default_title: string | null;
    default_description: string | null;
    default_should_create_finish_comment: boolean;
};

export type TaskTypeUpdateVar = {
    id: string;
    set: TaskTypeFields;
};

export type TaskTypeUpdateResponseDataT = {
    updateTaskType: {
        id: string;
    };
} | null | undefined;

export const TASK_TYPE_UPDATE = gql`
    mutation updateTaskType(
        $set: task_type_set_input = {},
        $id: String = ""
    ) {
        updateTaskType: update_task_type_by_pk(
            pk_columns: { id: $id },
            _set: $set
        ) {
            id
        }
    }
`;
