import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { CLIENT_QUERY, ClientResponseT } from 'shared/graphql/query/client/ClientQuery';
import { ClientT, EMPTY_CLIENT_RESULT } from 'types/Client';

import { ClientDTO } from '../DTO/ClientDTO';

import { ClientDataT } from './ClientData';

const useClient = (): ClientDataT => {
    const [fetch, { loading, error }] = useLazyQuery<ClientResponseT>(CLIENT_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchClient = async (id: string): Promise<ClientT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(ClientDTO, EMPTY_CLIENT_RESULT, (client) => client, data?.client);
        } catch {
            return EMPTY_CLIENT_RESULT;
        }
    };

    return {
        fetchClient,
        loading,
        error,
    };
};

export default useClient;
