import { useEffect } from 'react';
import useImplementationsByDocument from 'shared/data-hook/implementation/fetch/fetchAll/useImplementationsByDocument';
import { useStore } from 'store';

const useFetchImplementationsByDocument = (id: string) => {
    const { fetchImplementationsByDocument, loading } = useImplementationsByDocument();
    const { setImplementationsByDocument } = useStore('updateImplementationModal');

    useEffect(() => {
        if (id) {
            fetchImplementationsByDocument(id)
                .then((fetchedImplementationsByDocument) => {
                    setImplementationsByDocument(fetchedImplementationsByDocument);
                });
        }
    }, [id]);

    return {
        loading,
    };
};

export default useFetchImplementationsByDocument;
