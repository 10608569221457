import { GridSortModel } from '@mui/x-data-grid-premium';

export const DEFAULT_PRICE_SORT_MODEL: GridSortModel = [
    {
        field: 'courtType',
        sort: 'asc',
    },
    {
        field: 'day',
        sort: 'asc',
    },
    {
        field: 'timeStart',
        sort: 'asc',
    },
];
