import { PAGE_TITLE_COURT_SETTING } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import CourtTypeSettingList from 'page/Court/components/CourtTypeSettingList';
import CreateCourtTypeSettingModal from 'page/Court/components/CreateCourtTypeSettingModal';
import ControlPanel from 'shared/component/ControlPanel';
import useCourtTypes from 'shared/data-hook/courtType/fetch/fetchAll/useCourtTypes';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CreateCourtModal from './components/CourtSetting/components/CreateCourtModal';
import UpdateCourtModal from './components/CourtSetting/components/UpdateCourtModal';
import UpdateCourtTypeSettingModal from './components/UpdateCourtTypeSettingModal';
import { COURT_TYPE_SETTING_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: courtTypesData, loading: courtTypesLoading, count: rowCount, fetchCourtTypes,
    } = useCourtTypes();
    const { setCourtTypes } = useStore('courtType');
    const { onOpen } = useStore('createCourtTypeModal');
    const { refetchTrigger } = useStore('refetchTrigger');

    usePageTitle(PAGE_TITLE_COURT_SETTING);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchCourtTypes, refetchTrigger, COURT_TYPE_SETTING_HEADER_DATA);

    useEffect(() => {
        setCourtTypes(courtTypesData, rowCount);
    }, [courtTypesData, courtTypesLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button variant="outlined" size="small" onClick={ onOpen } startIcon={ <AddIcon /> }>добавить</Button>
            </ControlPanel>
            <CourtTypeSettingList
                loading={ courtTypesLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <UpdateCourtTypeSettingModal />
            <CreateCourtTypeSettingModal />
            <CreateCourtModal />
            <UpdateCourtModal />
        </StyledPageWrap>
    );
});

export default List;
