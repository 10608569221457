import { useMutation } from '@apollo/client';
import {
    ACQUIRING_SETTING_DELETE,
    AcquiringSettingDeleteResponseDataT,
    AcquiringSettingDeleteVarT,
} from 'shared/graphql/mutation/acquiringSetting/AcquiringSettingDelete';
import { AcquiringSettingT } from 'types/AcquiringSetting';

import { AcquiringSettingDeleteDataT } from './AcquiringSettingDeleteData';

const useAcquiringSettingDelete = (): AcquiringSettingDeleteDataT => {
    const [acquiringSettingDelete, { loading, error }] = useMutation<
        AcquiringSettingDeleteResponseDataT,
        AcquiringSettingDeleteVarT
    >(ACQUIRING_SETTING_DELETE);

    const deleteAction = async (acquiringSettingId: AcquiringSettingT['id']) => {
        try {
            const { data } = await acquiringSettingDelete({
                variables: {
                    id: acquiringSettingId,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteAcquiringSetting: deleteAction,
        loading,
        error,
    };
};

export default useAcquiringSettingDelete;
