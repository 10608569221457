import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { MEMBER_CARD_QUERY, MemberCardResponseT } from 'shared/graphql/query/memberCard/MemberCardQuery';
import { EMPTY_MEMBER_CARD_RESULT, MemberCardT } from 'types/MemberCard';

import { MemberCardDTO } from '../DTO/MemberCardDTO';

import { MemberCardDataT } from './MemberCardData';

const useMemberCard = (): MemberCardDataT => {
    const [fetch, { loading, error }] = useLazyQuery<MemberCardResponseT>(
        MEMBER_CARD_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchMemberCard = async (id: string): Promise<MemberCardT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                MemberCardDTO,
                EMPTY_MEMBER_CARD_RESULT,
                (memberCard) => memberCard,
                data?.memberCard,
            );
        } catch {
            return EMPTY_MEMBER_CARD_RESULT;
        }
    };

    return {
        fetchMemberCard,
        loading,
        error,
    };
};

export default useMemberCard;
