import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PaymentDTO } from 'shared/data-hook/payment/fetch/DTO/PaymentDTO';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { PaymentDocumentT } from 'types/PaymentDocument';

const DTO = t.intersection([
    t.type({
        id: t.string,
        number: t.string,
        datetime: t.string,
        organization: t.type({
            id: t.string,
            name: t.string,
        }),
        comment: Nullable(t.string),
    }),
    t.partial({
        payments: t.array(PaymentDTO),
    }),
]);

export type PaymentDocumentDTOT = t.TypeOf<typeof DTO>;

export const PaymentDocumentDTO = tPromise.extendDecoder<PaymentDocumentDTOT, PaymentDocumentT>(
    DTO,
    ({
        id,
        number,
        datetime,
        organization,
        comment,
        payments,
    }) => ({
        id,
        number,
        datetime: new Date(datetime),
        organization,
        comment: comment || '',
        payments: payments || [],
    }),
) as t.Type<PaymentDocumentT, PaymentDocumentDTOT>;
