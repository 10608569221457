import {
    PROPERTY_ACTIONS,
    PROPERTY_API_LOGIN,
    PROPERTY_API_PASSWORD,
    PROPERTY_API_TOKEN,
    PROPERTY_API_URL,
    PROPERTY_PAYMENT_METHOD,
    PROPERTY_PAYMENT_TYPE,
    PROPERTY_SERVICE_APP,
    PROPERTY_TYPE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import useDeleteAcquiringSetting from 'shared/hook/acquiringSettings/delete/useDeleteAcquiringSetting';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { CustomHeaderData } from 'types/DataGrid';

import AcquiringSettingEditButton from '../components/AcquiringSettingEditButton';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const {
        row: { id, title },
    } = props;
    const { acquiringSettingDelete } = useDeleteAcquiringSetting();

    return (
        <>
            <AcquiringSettingEditButton id={ id } />
            <DeleteButton id={ id } title={ title } actionDelete={ acquiringSettingDelete } />
        </>
    );
};

export const INTERNET_ACQUIRING_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TYPE,
        field: 'type',
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_API_LOGIN,
        field: 'apiLogin',
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_API_PASSWORD,
        field: 'apiPassword',
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_API_TOKEN,
        field: 'apiToken',
        minWidth: 300,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_API_URL,
        field: 'apiUrl',
        minWidth: 300,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_PAYMENT_METHOD,
        field: 'paymentMethod',
        minWidth: 300,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['payment_method', 'id'],
        hasuraSortFields: ['payment_method', 'title'],
        renderCell: (params: GridRenderCellParams<String>) =>
            renderStringCell(params.row.paymentMethod?.title),
    },
    {
        headerName: PROPERTY_PAYMENT_TYPE,
        field: 'paymentType',
        minWidth: 300,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['payment_type', 'id'],
        hasuraSortFields: ['payment_type', 'title'],
        renderCell: (params: GridRenderCellParams<String>) =>
            renderStringCell(params.row.paymentType?.title),
    },
    {
        headerName: PROPERTY_SERVICE_APP,
        field: 'serviceApp',
        minWidth: 350,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['service_app', 'label'],
        hasuraSortFields: ['service_app', 'label'],
        renderCell: (({ row }) => row.serviceApp.label),
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
