import { makeAutoObservable } from 'mobx';
import { EMPTY_TASK_STATUS_RESULT, TaskStatusT } from 'types/TaskStatus';

export default class TaskStatusStore {
    public taskStatuses: TaskStatusT[] = [];

    public taskStatus: TaskStatusT = EMPTY_TASK_STATUS_RESULT;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setTaskStatuses(taskStatuses: TaskStatusT[], count: number) {
        this.taskStatuses = taskStatuses;
        this.count = count;
    }

    public setTaskStatus(taskStatus: TaskStatusT) {
        this.taskStatus = taskStatus;
    }
}
