import { gql } from '@apollo/client';

export type PrtFields = {
    name: string;
    description: string | null;
    order: number;
};

export type PrtUpdateVar = {
    id: string;
    set: PrtFields;
};

export type PrtUpdateResponseDataT = {
    updatePrt: {
        id: string;
    };
} | null | undefined;

export const PRT_UPDATE = gql`
    mutation updatePrt(
        $set: prt_set_input = {},
        $id: String = ""
    ) {
        updatePrt: update_prt_by_pk(
            pk_columns: { id: $id },
            _set: $set
        ) {
            id
        }
    }
`;
