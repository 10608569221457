import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { GroupViewT } from 'types/GroupView';

export type GroupViewsResponseT = {
    groupViews: GroupViewT[];
    groupViewsCount: AggregateT;
};

export type GroupViewResponseT = {
    groupView: GroupViewT;
}

export const GROUP_VIEW_FILTER_OPTIONS_QUERY = gql`
    query GroupViewFilterOptions {
        filterOptions: group_view(
            order_by: { name: asc }
        ) {
            value: id,
            label: name
        }
    }
`;

export const GROUP_VIEWS_QUERY = gql`
    query GroupView(
        $limit: Int
        $offset: Int
        $orderBy: group_view_order_by! = {},
        $where: group_view_bool_exp! = {}
    ) {
        groupViews: group_view(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            name
            age_type {
                name
            }
            group_category {
                name
            }
            min_size
            avg_size
            max_size
            clients_for_main_coach
            correction_factors
            ignore_busy_coaches
            group_constant_price
        }
        groupViewsCount: group_view_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const GROUP_VIEW_QUERY = gql`
    query GroupView($id: String!) {
        groupView: group_view_by_pk(id: $id) {
            id
            name
            age_type {
                id
            }
            group_category {
                id
            }
            min_size
            avg_size
            max_size
            clients_for_main_coach
            correction_factors
            ignore_busy_coaches
            group_constant_price
        }
    }
`;

export const AGE_TYPE_OPTIONS_QUERY = gql`
    query AgeType {
        filterOptions: age_type(
            order_by: { name: asc }
        ) {
            value: id,
            label: name
        }

    }
`;

export const GROUP_CATEGORY_OPTIONS_QUERY = gql`
    query Category {
        filterOptions: group_category(
            order_by: { name: asc }
        ) {
            value: id,
            label: name
        }
    }
`;
