import {
    PROPERTY_ACTIONS,
    PROPERTY_CASHBACK_FIAT_OPERATIONS_LIMIT, PROPERTY_CASHBACK_FIAT_OPERATIONS_LIMIT_EXTENDED, PROPERTY_CASHBACK_LIMIT,
    PROPERTY_COLOR,
    PROPERTY_PERIOD,
    PROPERTY_STATUS,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Typography } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import AdornmentColor from 'shared/component/Adorment';
import AuxiliaryPopover from 'shared/component/AuxiliaryPopover';
import DeleteButton from 'shared/component/Button/DeleteButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import useDeleteMemberCardType from 'shared/hook/memberCardType/delete/useDeleteMemberCardType';
import { CountForm } from 'shared/utils/CountForm';
import { useStore } from 'store';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, title } } = props;
    const { memberCardTypeDelete } = useDeleteMemberCardType();
    const { onOpen } = useStore('updateMemberCardTypeModal');

    return (
        <>
            <IconButton onClick={ () => onOpen(id) }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ id } title={ title } actionDelete={ memberCardTypeDelete } />
        </>
    );
};

export const MEMBER_CARD_TYPE_HEADER_DATA: GridColumns = [
    {
        headerName: PROPERTY_TITLE,
        field: 'title',
        flex: 1,
        type: 'string',
    },
    {
        headerName: PROPERTY_STATUS,
        field: 'isActive',
        renderCell: isActiveIcon,
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
    },
    {
        headerName: PROPERTY_COLOR,
        field: 'color',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params) => (
            <Box sx={ {
                display: 'flex', alignItems: 'center', position: 'relative', width: '100%', justifyContent: 'center',
            } }
            >
                { params.row.color }
                <AdornmentColor color={ params.row.color } />
            </Box>
        ),
    },
    {
        headerName: PROPERTY_CASHBACK_FIAT_OPERATIONS_LIMIT,
        field: 'cashbackFiatOperationsLimit',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        renderHeader: () => (
            <>
                <Typography variant="subtitle2" sx={ { fontWeight: '500' } }>{ PROPERTY_CASHBACK_LIMIT }</Typography>
                <AuxiliaryPopover
                    auxiliaryPopoverText={ PROPERTY_CASHBACK_FIAT_OPERATIONS_LIMIT_EXTENDED }
                    auxiliaryPopoverIconSize="small"
                />
            </>
        ),
    },
    {
        headerName: PROPERTY_PERIOD,
        field: 'expirationPeriod',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        renderCell: (params) =>
            <span>{ params.row.expirationPeriod } { CountForm(params.row.expirationPeriod, ['месяц', 'месяца', 'месяцев']) }</span>,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 0.4,
        align: 'center',
        headerAlign: 'center',
    },
];
