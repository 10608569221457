import { useMutation } from '@apollo/client';
import { PrtFormFields } from 'page/Prt/components/formData';
import { PRT_UPDATE, PrtUpdateResponseDataT, PrtUpdateVar } from 'shared/graphql/mutation/prt/PrtUpdate';
import { PrtT } from 'types/Prt';

import { PrtUpdateDataT } from './PrtUpdateData';

const usePrtUpdate = (): PrtUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        PrtUpdateResponseDataT,
        PrtUpdateVar
    >(PRT_UPDATE);

    const updateAction = async (
        input: PrtFormFields,
        prtId: PrtT['id'],
    ) => {
        if (!prtId) {
            // TODO: use Sentry
            throw new Error('PRT is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: prtId,
                    set: {
                        name: input.name,
                        description: input.description ? input.description : null,
                        order: input.order,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        prtUpdate: updateAction,
        loading,
        error,
    };
};

export default usePrtUpdate;
