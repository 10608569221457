import { gql } from '@apollo/client';
import { CourtScheduleItem } from 'types/Court';

export type CourtUpdateResponseDataT = {
    updateCourt: {
        id: string;
    };
} | null | undefined;

type CourtUpdateMutationPayloadT = {
    name: string;
    order: number;
    organization_location_id: string;
    type_id: string;
    bonus_classes: boolean;
    schedule: CourtScheduleItem[];
    organization_id: string | null;
    camera_id: string | null;
    enabled_at_months: string[];
    promo_category: string | null;
};

export type CourtUpdateVarT = {
    id: string;
    set: CourtUpdateMutationPayloadT;
}

export const COURT_UPDATE = gql`
    mutation updateCourt($set: court_set_input = {}, $id: String = "") {
        updateCourt: update_court_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
