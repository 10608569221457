import { useMutation } from '@apollo/client';
import {
    PAYMENT_DOCUMENT_ARCHIVE,
    PaymentDocumentArchivePayloadT,
    PaymentDocumentArchiveResponseDataT,
    PaymentDocumentArchiveVarT,
} from 'shared/graphql/mutation/paymentDocument/PaymentDocumentArchive';
import { useStore } from 'store';

import { PaymentDocumentArchiveDataT } from './PaymentDocumentArchiveData';

const usePaymentDocumentArchive = (): PaymentDocumentArchiveDataT => {
    const [archive, { loading, error }] = useMutation<PaymentDocumentArchiveResponseDataT, PaymentDocumentArchiveVarT>(
        PAYMENT_DOCUMENT_ARCHIVE,
    );
    const { serviceApp } = useStore('serviceApp');

    const paymentDocumentArchive = async (payload: PaymentDocumentArchivePayloadT) => {
        try {
            const { data } = await archive({
                variables: {
                    appId: serviceApp.id,
                    payload: {
                        payment_document_id: payload.paymentDocumentId,
                    },
                },
            });

            return data;
        } catch (errorArchive) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw errorArchive;
        }
    };

    return {
        paymentDocumentArchive,
        loading,
        error,
    };
};

export default usePaymentDocumentArchive;
