import { gql } from '@apollo/client';
import { ServiceAppT } from 'types/serviceApp/ServiceApp';

export type ServiceAppDataT = {
    serviceApp: ServiceAppT[];
};

export const SERVICE_APP_FILTER_OPTIONS_QUERY = gql`
    query ServiceAppFilterOptions {
        filterOptions: service_app(
            order_by: { label: asc }
        ) {
            value: id
            label
        }
    }
`;

export const SERVICE_APP_QUERY = gql`
    query ServiceApp {
        serviceApp: service_app {
            id
            label
            token
            description
            url
            hash
            timezone
            fiat_currency_title
            domain_name
            sms_sender_name
            sms_sending_enabled
            locale
            default_credit_for_new_clients
            default_organization_location_id
            video_analytics_telegram_bot_chat_ids
            entry_notification_telegram_bot_chat_ids
            acquiring_notification_telegram_bot_chat_ids
        }
    }
`;
