import { useLazyQuery } from '@apollo/client';
import { uniq } from 'lodash';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { CLIENTS_QUERY } from 'shared/graphql/query/client/ClientQuery';
import { MEMBER_CARDS_QUERY, MemberCardsResponseT } from 'shared/graphql/query/memberCard/MemberCardQuery';
import { FetchDataParams, SearchT } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_MEMBER_CARDS_RESULT } from 'types/MemberCard';

import { MemberCardsDTO } from '../DTO/MemberCardsDTO';
import { getFullNameClientsFilter, getFullNameMemberCardFilter } from '../filter/getFullNameFilter';

import { MemberCardsDataT } from './MemberCardsData';

const useMemberCards = (): MemberCardsDataT => {
    const [fetchClients] = useLazyQuery(CLIENTS_QUERY, {
        fetchPolicy: 'network-only',
    });

    const [fetch, { data, loading }] = useLazyQuery<MemberCardsResponseT, QueryVarsT>(
        MEMBER_CARDS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const { memberCards: memberCardsData, memberCardsCount } = decodeDTO(
        MemberCardsDTO,
        EMPTY_MEMBER_CARDS_RESULT,
        (memberCards) => memberCards,
        {
            memberCards: data?.memberCards,
            memberCardsCount: data?.memberCardsCount,
        },
    );

    const fetchRelationIds = async (search: SearchT): Promise<string[]> => {
        try {
            const { data: clientsData } = await fetchClients({
                variables: {
                    where: {
                        ...getFullNameClientsFilter(search),
                    },
                },
            });

            const relationIds: string[] = clientsData?.clients
                ?.flatMap((client: { relation_id: string }) => client.relation_id) ?? [];

            return uniq(relationIds);
        } catch {
            return [];
        }
    };

    const fetchMemberCards = async ({
        limit, offset, orderBy = { client: { last_name: 'asc' } }, search = { name: '' }, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams<SearchT> = {}): Promise<void> => {
        const { name } = search;

        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: {
                        ...filterData(where),
                        ...name && getFullNameMemberCardFilter(await fetchRelationIds(search)),
                    },
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: memberCardsData,
        count: memberCardsCount,
        fetchMemberCards,
        loading,
    };
};

export default useMemberCards;
