import { action, makeObservable, observable } from 'mobx';
import { ClientT } from 'types/Client';

import BaseModalStore from './BaseModalStore';

export default class ExportPriceDataModal extends BaseModalStore {
    public client: ClientT | undefined = undefined;

    public setClient(client: ClientT) {
        this.client = client;
    }

    constructor() {
        super();

        makeObservable(this, {
            client: observable,
            setClient: action.bound,
        });
    }
}
