import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { AcquiringSettingT } from 'types/AcquiringSetting';

const DTO = t.type({
    type: t.string,
    api_login: t.string,
    api_password: t.string,
    api_token: t.string,
    api_url: t.string,
    id: t.string,
    payment_method: Nullable(t.type({
        id: t.string,
        title: t.string,
    })),
    payment_type: Nullable(t.type({
        id: t.string,
        title: t.string,
    })),
    service_app: t.type({
        id: t.string,
        label: t.string,
    }),
});

export type AcquiringSettingDTOT = t.TypeOf<typeof DTO>;

export const AcquiringSettingDTO = tPromise.extendDecoder<AcquiringSettingDTOT, AcquiringSettingT>(
    DTO,
    ({
        type, api_login, api_password, api_token, api_url, id, payment_method, payment_type, service_app,
    }) => ({
        id,
        type,
        apiLogin: api_login,
        apiUrl: api_url,
        apiPassword: api_password,
        apiToken: api_token,
        paymentMethod: payment_method ? { id: payment_method?.id, title: payment_method?.title } : undefined,
        paymentType: payment_type ? { id: payment_type?.id, title: payment_type?.title } : undefined,
        serviceApp: { id: service_app.id, label: service_app.label },
    }),
) as t.Type<AcquiringSettingT, AcquiringSettingDTOT>;
