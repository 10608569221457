export type CourtT = {
    id: string;
    name: string;
    order: number;
    bonusClasses: boolean;
    schedule: CourtScheduleItem[];
    organizationLocation: string;
    organization: string | null;
    courtType: string;
    cameraId: string | null;
    enabledAtMonths: string[];
    promoCategory: string;
};

export type CourtsT = {
    courts: CourtT[];
    courtsCount: number;
};

export type CourtScheduleItem = {
    day: number;
    start: string;
    end: string;
};

export const EMPTY_COURTS_RESULT = {
    courts: [],
    courtsCount: 0,
};

export const EMPTY_COURT_RESULT = {
    id: '',
    name: '',
    order: 0,
    bonusClasses: false,
    schedule: [],
    organizationLocation: '',
    organization: '',
    courtType: '',
    cameraId: '',
    enabledAtMonths: [],
    promoCategory: '',
};
