import { useEffect } from 'react';
import useCourt from 'shared/data-hook/court/fetch/fetchOne/useCourt';
import { useStore } from 'store';

const useFetchCourt = (id: string) => {
    const { fetchCourt, loading } = useCourt();
    const { setCourt } = useStore('updateCourtModal');

    useEffect(() => {
        fetchCourt(id).then((court) => {
            setCourt(court);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchCourt;
