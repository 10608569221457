import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ImplementationT } from 'types/Implementation';

import { ImplementationByDocumentDTO } from './ImplementationByDocumentDTO';

const DTO = t.array(
    t.type({
        implementations: t.array(ImplementationByDocumentDTO),
    }),
);

type ImplementationsByDocumentDTOT = t.TypeOf<typeof DTO>;

export const ImplementationsByDocumentDTO = tPromise.extendDecoder<
    ImplementationsByDocumentDTOT,
    ImplementationT[]
>(
    DTO,
    (implementations) => {
        return implementations?.[0]?.implementations;
    },
) as t.Type<ImplementationT[], ImplementationsByDocumentDTOT>;
