import { PriceT } from './Price';

export type PricesByServiceCategoryT = {
    id: string;
    name: string;
    prices: PriceT[];
};

export type PricesByServiceCategoriesT = {
    pricesByServiceCategories: PricesByServiceCategoryT[];
    pricesByServiceCategoriesCount: number;
};

export const EMPTY_PRICES_BY_SERVICE_CATEGORIES_RESULT: PricesByServiceCategoriesT = {
    pricesByServiceCategories: [],
    pricesByServiceCategoriesCount: 0,
};
