import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import {
    ACQUIRING_SETTINGS_QUERY,
    AcquiringSettingsResponseT,
} from 'shared/graphql/query/acquiringSetting/AcquiringSettingQuery';
import { EMPTY_ACQUIRING_SETTINGS_RESULT } from 'types/AcquiringSetting';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';

import { AcquiringSettingsDTO } from '../DTO/AcquiringSettingsDTO';

import { AcquiringSettingsDataT } from './AcquiringSettingsData';

const useAcquiringSettings = (): AcquiringSettingsDataT => {
    const [fetch, { data, loading, error }] = useLazyQuery<AcquiringSettingsResponseT, QueryVarsT>(
        ACQUIRING_SETTINGS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedAcquiringSettingsData = decodeDTO(
        AcquiringSettingsDTO,
        EMPTY_ACQUIRING_SETTINGS_RESULT,
        (acquiringSettings) => acquiringSettings,
        {
            acquiringSettings: data?.acquiringSettings,
            acquiringSettingsCount: data?.acquiringSettingsCount,
        },
    );

    const fetchAcquiringSettings = async ({
        limit,
        offset,
        orderBy = {},
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) { // eslint-disable-line
            console.error((error as Error).message); // eslint-disable-line no-console
            throw error;
        }
    };

    return {
        data: decodedAcquiringSettingsData.acquiringSettings,
        count: decodedAcquiringSettingsData.acquiringSettingsCount,
        fetchAcquiringSettings,
        loading,
        error,
    };
};

export default useAcquiringSettings;
