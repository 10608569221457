export const SERVICE_GROUP_RENT_LABEL = 'Аренда для группы';
export const SERVICE_RENT_LABEL = 'Аренда';
export const SERVICE_COACH_LABEL = 'Тренер';
export const SERVICE_GROUP_LABEL = 'Группа';
export const SERVICE_MEMBER_CARD_LABEL = 'Членская карта';
export const SERVICE_GROUP_RENT = 'group_rent';
export const SERVICE_RENT = 'rent';
export const SERVICE_COACH = 'coach';
export const SERVICE_GROUP = 'group';
export const SERVICE_MEMBER_CARD = 'member_card';

export const SERVICES_OPTIONS = [
    {
        value: SERVICE_RENT,
        label: SERVICE_RENT_LABEL,
    },
    {
        value: SERVICE_COACH,
        label: SERVICE_COACH_LABEL,
    },
    {
        value: SERVICE_GROUP,
        label: SERVICE_GROUP_LABEL,
    },
    {
        value: SERVICE_GROUP_RENT,
        label: SERVICE_GROUP_RENT_LABEL,
    },
];
