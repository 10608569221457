import { gql } from '@apollo/client';

export type PayloadVarT = {
    filter: {
        price_season_id: string;
        price_type_id: string;
    };
};

export type PayloadT = {
    priceSeasonId: string;
    priceTypeId: string;
}

export type ExportPriceResponseDataT = {
    adminExportPrices: {
        status: string;
        reason: string | null;
    };
} | null | undefined;

export type ExportPriceVarT = {
    appId: string;
    payload: PayloadVarT;
};

export const EXPORT_PRICE_MUTATION = gql`
    mutation ExportPriceMutation($appId: String!, $payload: json!) {
        adminExportPrices(
            app_id: $appId
            payload: $payload
        ) {
            status
            reason
        }
    }
`;
