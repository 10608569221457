import { gql } from '@apollo/client';

export type CreateAgePayloadT = {
    name: string;
    value: number;
}

export type CreateAgeResponseT = {
    status: string;
    reason: null | string;
}

export type CreateAgeVarT = {
    object: CreateAgePayloadT;
};

export type CreateAgeT = {
    adminAgeCreate: CreateAgeResponseT;
}

export const EMPTY_CREATE_AGE_RESPONSE: CreateAgeResponseT = {
    status: 'error',
    reason: '',
};

export const CREATE_AGE = gql`
    mutation AgeCreate($object: age_insert_input = {}) {
        createAge: insert_age_one(
            object: $object
        ) {
            id
        }
    }
`;
