import { DATE_FORMAT_BASIC } from 'constants/date';
import { MODAL_ACTION_ARCHIVE, MODAL_ACTION_IRREVERSIBLE } from 'constants/modalTitles';

import React, { FC, MouseEventHandler } from 'react';
import { Button, DialogActions, DialogTitle } from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal/index';
import { useStore } from 'store';

type PropsT = {
    id: string;
    title: string;
    datetime: Date;
    onArchiveButtonClick: () => void;
};

const ArchiveModal: FC<PropsT> = observer((props) => {
    const {
        id, title, datetime, onArchiveButtonClick,
    } = props;
    const { itemId, onClose } = useStore('archiveItemModal');

    const handleArchiveButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onArchiveButtonClick();
        onClose();
    };

    return (
        <Modal open={ itemId === id } onClose={ onClose }>
            <DialogTitle>
                { ` ${MODAL_ACTION_ARCHIVE} #${title} от ${dayjs(datetime).format(DATE_FORMAT_BASIC)}?` }
                <br />
                { MODAL_ACTION_IRREVERSIBLE }
            </DialogTitle>
            <DialogActions>
                <Button variant="outlined" onClick={ onClose }>Отмена</Button>
                <Button onClick={ handleArchiveButtonClick } variant="contained" autoFocus color="error">Перенести</Button>
            </DialogActions>
        </Modal>
    );
});

export default ArchiveModal;
