import { gql } from '@apollo/client';

export type MemberCardTypeCreateFields = {
    title: string;
    is_active: boolean;
    color: string | null;
    expiration_period: number;
    cashback_fiat_operations_limit: number;
    cashback_fiat_sum_limit: number;
};

export type MemberCardTypeCreateVar = {
    object: MemberCardTypeCreateFields;
};

export type MemberCardTypeCreateResponseDataT = {
    createMemberCardType: {
        id: string;
    };
} | null | undefined;

export const MEMBER_CARD_TYPE_CREATE = gql`
    mutation CreateMemberCardType($object: member_card_type_insert_input = {}) {
        createMemberCardType: insert_member_card_type_one(
            object: $object
        ) {
            id
        }
    }
`;
