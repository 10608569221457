import { MODAL_TITLE_CREATE_AGE } from 'constants/modalTitles';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreateAge from 'shared/hook/age/create/useCreateAge';
import { useStore } from 'store';

import AgeForm from './AgeForm';
import {
    AGE_DEFAULT_VALUES,
    AgeFormFields, getAgeValidationSchema,
} from './formData';

const CreateAgeModal: FC = observer(() => {
    const { isOpen, onClose } = useStore('createAgeModal');
    const { createAge, loading: loadingSave } = useCreateAge();

    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<AgeFormFields>({
        defaultValues: AGE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getAgeValidationSchema()),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreateAge = (data: AgeFormFields) => {
        createAge(data).then(() => {
            handleCloseModal();
        });
    };

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_CREATE_AGE }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <AgeForm
                    control={ control }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                <Button variant="outlined" onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleCreateAge) }
                    autoFocus
                    disabled={ !isDirty || !isValid }
                    loading={ loadingSave }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateAgeModal;
