import {
    Control, FieldValues, Path, useController,
} from 'react-hook-form';
import MultipleSelect, { OptionsType } from 'shared/component/Form/MultipleSelect';

interface PropsT<T extends FieldValues> {
    label: string;
    name: Path<T>;
    options: OptionsType[];
    control: Control<T>;
}

const FieldSelect = <T extends FieldValues>(props: PropsT<T>) => {
    const {
        label, name, options, control,
    } = props;
    const { field } = useController({ control, name });

    return (
        <MultipleSelect
            options={ options }
            control={ control }
            label={ label }
            selectedValues={ field.value }
            name={ name }
        />
    );
};

export default FieldSelect;
