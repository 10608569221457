import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { ProviderIncomeT } from 'types/ProviderIncome';

const DTO = t.type({
    id: t.string,
    coach_id: Nullable(t.string),
    operation: Nullable(t.string),
    organization_id: Nullable(t.string),
    place: Nullable(t.string),
    price_type_id: Nullable(t.string),
    service: Nullable(t.string),
    income_in_bonus: Nullable(t.number),
    income_in_fiat: Nullable(t.number),
    covered_at: Nullable(t.string),
    datetime: Nullable(t.string),
    datetime_operation: t.string,
    payed_at: Nullable(t.string),
});

export type ProviderIncomeDTOT = t.TypeOf<typeof DTO>;

export const ProviderIncomeDTO = tPromise.extendDecoder<ProviderIncomeDTOT, ProviderIncomeT>(
    DTO,
    ({
        id,
        coach_id,
        operation,
        organization_id,
        place,
        price_type_id,
        service,
        income_in_bonus,
        income_in_fiat,
        covered_at,
        datetime,
        datetime_operation,
        payed_at,
    }) => ({
        id,
        coachId: coach_id ?? '',
        operation: operation ?? '',
        organizationId: organization_id ?? '',
        place: place ?? '',
        priceTypeId: price_type_id ?? '',
        service: service ?? '',
        incomeInBonus: income_in_bonus ?? 0,
        incomeInFiat: income_in_fiat ?? 0,
        coveredAt: covered_at ? new Date(covered_at) : null,
        datetime: datetime ? new Date(datetime) : null,
        datetimeOperation: new Date(datetime_operation),
        payedAt: payed_at ? new Date(payed_at) : null,
    }),
) as t.Type<ProviderIncomeT, ProviderIncomeDTOT>;
