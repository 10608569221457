import { action, makeObservable, observable } from 'mobx';
import { EMPTY_TASK_STATUS_RESULT, TaskStatusT } from 'types/TaskStatus';

import BaseModalStore from './BaseModalStore';

export default class UpdateTaskStatusModal extends BaseModalStore {
    public taskStatus: TaskStatusT = EMPTY_TASK_STATUS_RESULT;

    public setTaskStatus(taskStatus: TaskStatusT) {
        this.taskStatus = taskStatus;
    }

    constructor() {
        super();

        makeObservable(this, {
            taskStatus: observable,
            setTaskStatus: action.bound,
        });
    }
}
