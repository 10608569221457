import { useLazyQuery } from '@apollo/client';
import { PaymentDocumentDTO } from 'shared/data-hook/paymentDocument/fetch/DTO/PaymentDocumentDTO';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { PAYMENT_DOCUMENT_QUERY, PaymentDocumentResponseT } from 'shared/graphql/query/paymentDocument/PaymentDocumentQuery';
import { EMPTY_PAYMENT_DOCUMENT_RESULT, PaymentDocumentT } from 'types/PaymentDocument';

import { PaymentDocumentDataT } from './PaymentDocumentData';

const usePaymentDocument = (): PaymentDocumentDataT => {
    const [fetch, { loading, error }] = useLazyQuery<PaymentDocumentResponseT>(PAYMENT_DOCUMENT_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchPaymentDocument = async (id: string): Promise<PaymentDocumentT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(PaymentDocumentDTO, EMPTY_PAYMENT_DOCUMENT_RESULT, (paymentDocument) => paymentDocument, data?.paymentDocument);
        } catch {
            return EMPTY_PAYMENT_DOCUMENT_RESULT;
        }
    };

    return {
        fetchPaymentDocument,
        loading,
        error,
    };
};

export default usePaymentDocument;
