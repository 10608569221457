import { useMutation } from '@apollo/client';
import { TaskTypeFormFields } from 'page/TaskType/components/formData';
import {
    TASK_TYPE_UPDATE,
    TaskTypeUpdateResponseDataT,
    TaskTypeUpdateVar,
} from 'shared/graphql/mutation/taskType/TaskTypeUpdate';
import { TaskTypeT } from 'types/TaskType';

import { TaskTypeUpdateDataT } from './TaskTypeUpdateData';

const useTaskTypeUpdate = (): TaskTypeUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        TaskTypeUpdateResponseDataT,
        TaskTypeUpdateVar
    >(TASK_TYPE_UPDATE);

    const updateAction = async (
        input: TaskTypeFormFields,
        taskTypeId: TaskTypeT['id'],
    ) => {
        if (!taskTypeId) {
            // TODO: use Sentry
            throw new Error('TaskType is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: taskTypeId,
                    set: {
                        name: input.name,
                        position: input.position,
                        is_active: input.isActive,
                        default_should_create_finish_comment: input.defaultShouldCreateFinishComment,
                        default_duration_hours: input.defaultDurationHours || null,
                        default_title: input.defaultTitle || null,
                        default_description: input.defaultDescription || null,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        taskTypeUpdate: updateAction,
        loading,
        error,
    };
};

export default useTaskTypeUpdate;
