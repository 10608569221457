import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ClientFullNameDTO } from 'shared/data-hook/utils/DTO/Client';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { ClientT } from 'types/Client';

const DTO = t.type({
    id: t.string,
    client: Nullable(ClientFullNameDTO),
    email: Nullable(t.string),
    relation_id: t.array(t.string),
});

export type ClientDTOT = t.TypeOf<typeof DTO>;

export const ClientDTO = tPromise.extendDecoder<ClientDTOT, ClientT>(
    DTO,
    ({
        id, client, email, relation_id,
    }) => ({
        id,
        email,
        relationIds: relation_id,
        firstName: client?.firstName ?? null,
        lastName: client?.lastName ?? null,
        middleName: client?.middleName ?? null,
    }),
) as t.Type<ClientT, ClientDTOT>;
