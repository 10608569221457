import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-premium';
import { DisplayColumnsFormFields } from 'shared/component/DataGrid/DisplayColumnsModal/formData';
import { OptionT } from 'types/Option';

const useDisplayColumns = (setColumnVisibilityModel?: (model: GridColumnVisibilityModel) => void) => {
    const displayColumns = ({ columns = [] }: DisplayColumnsFormFields, columnOptions: OptionT[], columnsData: GridColDef[]) => {
        const selectedColumns = columnOptions
            .filter((columnName) => !columns.includes(columnName.value))
            .map((columnName) => columnName.value);

        const model = columnsData.reduce<GridColumnVisibilityModel>((acc, column) => {
            acc[column.field] = !selectedColumns.some(
                (selectedColumn) => selectedColumn === column.field.split('-')[0],
            );

            return acc;
        }, {});

        setColumnVisibilityModel?.(columns.length ? model : {});
    };

    return { displayColumns };
};

export default useDisplayColumns;
