import { useMemo } from 'react';
import { GridColumns } from '@mui/x-data-grid-premium';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { FilterOptionT } from 'types/DataGrid';

import { INTERNET_ACQUIRING_HEADER_DATA } from './HeaderData';

export const prepareAcquiringSettingHeaderData = (
    serviceApp: FilterOptionT[],
    paymentMethod: FilterOptionT[],
    paymentType: FilterOptionT[],
): GridColumns => {
    return useMemo(
        () =>
            mapFields(INTERNET_ACQUIRING_HEADER_DATA, {
                serviceApp,
                paymentMethod,
                paymentType,
            }),
        [serviceApp, paymentMethod, paymentType],
    );
};
