import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { PRICE_MEMBER_CARDS_QUERY } from 'shared/graphql/query/priceMemberCards/PriceMemberCardsQuery';
import { FetchDataParams } from 'types/DataGrid';
import { FetchDataByPriceTypeIdAndPriceSeasonIdQueryVarsT } from 'types/Graphql';
import { EMPTY_PRICE_MEMBER_CARDS_RESULT, PriceMemberCardsT } from 'types/PriceMemberCard';

import { filterData } from '../../../utils/filter/filterData';
import { PriceMemberCardsDTO } from '../DTO/PriceMemberCardsDTO';

import { PriceMemberCardsDataT } from './PriceMemberCardsData';

const usePriceMemberCards = (): PriceMemberCardsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<PriceMemberCardsT, FetchDataByPriceTypeIdAndPriceSeasonIdQueryVarsT>(PRICE_MEMBER_CARDS_QUERY, {
        fetchPolicy: 'network-only',
    });

    const decodedPriceMemberCardsData = decodeDTO(
        PriceMemberCardsDTO,
        EMPTY_PRICE_MEMBER_CARDS_RESULT,
        (priceMemberCardsData) => priceMemberCardsData,
        {
            priceMemberCards: data?.priceMemberCards,
            priceMemberCardsCount: data?.priceMemberCardsCount,
        },
    );

    const fetchPriceMemberCards = async (priceTypeId: string, priceSeasonId: string, {
        limit, offset, orderBy = { title: 'asc' }, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<any> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                    priceTypeId,
                    priceSeasonId,
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchPriceMemberCards,
        data: decodedPriceMemberCardsData.priceMemberCards,
        count: decodedPriceMemberCardsData.priceMemberCardsCount,
        loading,
    };
};

export default usePriceMemberCards;
