import { useMutation } from '@apollo/client';
import { AgeFormFields } from 'page/Age/components/formData';
import {
    CREATE_AGE,
    CreateAgeResponseT,
    CreateAgeT,
    CreateAgeVarT,
    EMPTY_CREATE_AGE_RESPONSE,
} from 'shared/graphql/mutation/age/AgeCreate';

import { CreateAgeDataT } from './CreateAgeData';

const useAgeCreate = (): CreateAgeDataT => {
    const [create, { loading, error }] = useMutation<CreateAgeT, CreateAgeVarT>(
        CREATE_AGE,
    );

    const createAge = async (input: AgeFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        name: input.name,
                        value: input.value,
                    },
                },
            });

            return data?.adminAgeCreate as CreateAgeResponseT;
        } catch {
            return EMPTY_CREATE_AGE_RESPONSE;
        }
    };

    return {
        createAge,
        loading,
        error,
    };
};

export default useAgeCreate;
