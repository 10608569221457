import { PROPERTY_IMPLEMENTATION_ID } from 'constants/propertyNames';

import React, { FC, useEffect } from 'react';
import {
    Control, useFieldArray, UseFormSetValue,
} from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import TextField from 'shared/component/Form/TextField';
import { ImplementationT } from 'types/Implementation';

import { ImplementationForm as ImplementationFormT, ImplementationFormFields } from './formData';
import ImplementationContent from './ImplementationContent';
import ProviderIncomes from './ProviderIncomes';

type PropsT = {
    control: Control<ImplementationFormFields>;
    setValue: UseFormSetValue<ImplementationFormFields>;
    implementationsByDocument: ImplementationT[];
};

const ImplementationForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, implementationsByDocument,
    } = props;

    useEffect(() => {
        if (implementationsByDocument.length > 0) {
            setValue('implementations', implementationsByDocument || []);
        }
    }, [implementationsByDocument]);

    const { fields } = useFieldArray<ImplementationFormFields>({
        name: 'implementations',
        control,
    });

    return (
        <Grid2 container direction="column" rowSpacing={ 7 }>
            { fields.map((field, index) => {
                const surname = (field as ImplementationFormT).client?.lastName ? (field as ImplementationFormT).client?.lastName : '';
                const name = (field as ImplementationFormT)?.client?.firstName ? `${(field as ImplementationFormT)?.client?.firstName?.slice(0, 1)}.` : '';

                return (
                    <Grid2 key={ field.id } container direction="column" rowSpacing={ 3 }>
                        <Grid2 container direction="row" spacing={ 1 } xs={ 12 } sx={ { alignItems: 'center', justifyContent: 'space-between' } }>
                            <Typography variant="h6" component="h6">
                                { `${field.service} ${field.operation} (клиент
                            ${(field as ImplementationFormT).client ? `${surname} ${name}` : 'не указан'})` }
                            </Typography>
                            <Grid2 xs={ 6 }>
                                <TextField
                                    name={ `implementations.${index}.id` }
                                    label={ PROPERTY_IMPLEMENTATION_ID }
                                    control={ control }
                                    readOnly={ true }
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2>
                            <ImplementationContent
                                control={ control }
                                setValue={ setValue }
                                implementation={ field as ImplementationT }
                                index={ index }
                            />
                        </Grid2>
                        <ProviderIncomes
                            control={ control }
                            setValue={ setValue }
                            index={ index }
                        />
                    </Grid2>
                );
            }) }
        </Grid2>
    );
});

export default ImplementationForm;
