import {
    PROPERTY_COMMENT, PROPERTY_DATETIME,
    PROPERTY_DEFAULT_BONUS_PAYMENT,
    PROPERTY_DEFAULT_FIAT_PAYMENT, PROPERTY_PAYMENT_METHOD, PROPERTY_PAYMENT_TYPE,
} from 'constants/propertyNames';

export const PAYMENT_LABEL_DATA = {
    paymentType: PROPERTY_PAYMENT_TYPE,
    datetime: PROPERTY_DATETIME,
    comment: PROPERTY_COMMENT,
    paymentInFiat: PROPERTY_DEFAULT_FIAT_PAYMENT,
    paymentInBonus: PROPERTY_DEFAULT_BONUS_PAYMENT,
    paymentMethod: PROPERTY_PAYMENT_METHOD,
};

export enum PAYMENT_FIELDS_KEYS {
    datetime = 'datetime',
    comment = 'comment',
    paymentInFiat = 'paymentInFiat',
    paymentInBonus = 'paymentInBonus',
    paymentMethod = 'paymentMethod',
    paymentType = 'paymentType',
}
