import { MODAL_TITLE_UPDATE_PRICE_MEMBER_CARD } from 'constants/modalTitles';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Skeleton, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useFetchPriceMemberCard from 'shared/hook/priceMemberCard/fetch/useFetchPriceMemberCard';
import usePriceMemberCardTypeUpdate from 'shared/hook/priceMemberCard/update/usePriceMemberCardTypeUpdate';
import { useStore } from 'store';
import { MemberCardTypeDiscountFullReturnsT } from 'types/PriceMemberCard';

import {
    FieldT,
    getPriceMemberCardTypeValidationSchema, MEMBER_CARD_TYPE_DISCOUNT_RATES_DEFAULT_VALUES,
    PRICE_MEMBER_CARD_DEFAULT_VALUES,
    PriceMemberCardFormFields,
} from './formData';
import PriceMemberCardForm from './PriceMemberCardForm';

type PropsT = {
    priceTypeId: string;
    priceSeasonId: string;
};

const UpdatePriceMemberCardModal: FC<PropsT> = observer((props) => {
    const { priceTypeId, priceSeasonId } = props;
    const { onClose, isOpen, priceMemberCard } = useStore('updatePriceMemberCardModal');
    const { activePriceType, activePriceSeason } = useStore('priceMemberCards');
    const { priceSalesRounds } = useStore('priceSalesRound');

    const { loading: loadingFetch } = useFetchPriceMemberCard(priceSeasonId, priceTypeId);
    const { loading: loadingUpdate, updatePriceMemberCardType } = usePriceMemberCardTypeUpdate();

    const {
        control, handleSubmit, reset, setValue, formState: { isDirty, isValid },
    } = useForm<PriceMemberCardFormFields>({
        defaultValues: PRICE_MEMBER_CARD_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getPriceMemberCardTypeValidationSchema()),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdatePriceSalesRound = (data: PriceMemberCardFormFields) => {
        updatePriceMemberCardType(data).then(() => onClose());
    };

    const memberCardDiscountRatesFormFill = (): FieldT[] => {
        if (!priceMemberCard?.memberCardTypeDiscountRates.length) {
            return priceSalesRounds.map((round) => ({
                ...MEMBER_CARD_TYPE_DISCOUNT_RATES_DEFAULT_VALUES,
                priceSalesRound: { title: round.title, position: round.position },
                priceSalesRoundId: round.id,
            }));
        }

        return priceMemberCard.memberCardTypeDiscountRates;
    };

    useEffect(() => {
        if (priceMemberCard) {
            setValue('price', priceMemberCard.price);
            setValue('memberCardTypeDiscountRates', memberCardDiscountRatesFormFill());
            setValue('memberCardTypeDiscountFullReturns', priceMemberCard.memberCardTypeDiscountFullReturns.map((item: MemberCardTypeDiscountFullReturnsT) => ({
                ...item,
                memberCardTypeDiscountFullReturnPriceSalesRounds: item.memberCardTypeDiscountFullReturnPriceSalesRounds.map((round) => round.priceSalesRound.id),
            })) ?? []);
        }
    }, [priceMemberCard]);

    return (
        <Modal open={ isOpen } fullWidth={ true } maxWidth="lg">
            <ModalTitle onClose={ handleCloseModal }>
                { loadingFetch ? (
                    <Skeleton height={ 32 } />
                ) : (
                    `${MODAL_TITLE_UPDATE_PRICE_MEMBER_CARD} "${priceMemberCard?.title}"`
                ) }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <Typography variant="h6">
                    Сезон { activePriceSeason.title }, режим { activePriceType?.title }
                </Typography>
                { loadingFetch ? (
                    <Spinner />
                ) : (
                    <PriceMemberCardForm control={ control } />
                ) }
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    onClick={ handleSubmit(handleUpdatePriceSalesRound) }
                    loading={ loadingUpdate }
                    disabled={ !isDirty || !isValid }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdatePriceMemberCardModal;
