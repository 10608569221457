import {
    PROPERTY_PAYMENT_METHOD,
    PROPERTY_PAYMENT_TYPE,
    PROPERTY_SERVICE_APP,
} from 'constants/propertyNames';

import React, { FC, useEffect, useMemo } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import TextField from 'shared/component/Form/TextField';
import { useStore } from 'store';
import { AcquiringSettingT } from 'types/AcquiringSetting';

import {
    ACQUIRING_SETTING_FIELDS_KEYS,
    AcquiringSettingFormFields,
    FIELDS,
    MAP_FIELDS_KEYS_TO_LABEL,
} from './formData';

type PropsT = {
    control: Control<AcquiringSettingFormFields>;
    setValue?: UseFormSetValue<AcquiringSettingFormFields>;
    acquiringSetting?: AcquiringSettingT;
    isUpdate?: boolean;
};

const AcquiringSettingForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, acquiringSetting, isUpdate,
    } = props;
    const { paymentTypes } = useStore('paymentType');
    const { getFilterOption } = useStore('filterOptions');
    const serviceAppFilterOptions = getFilterOption('serviceApp');
    const paymentMethodFilterOptions = getFilterOption('paymentMethod');

    const paymentMethodId = useWatch({
        control,
        name: ACQUIRING_SETTING_FIELDS_KEYS.paymentMethod,
        defaultValue: '',
    });

    const filteredPaymentTypes = useMemo(
        () =>
            paymentTypes
                .filter((option) => option.paymentMethodId === paymentMethodId)
                .map((option) => ({
                    value: option.id,
                    label: option.title,
                }))
        , [paymentTypes, paymentMethodId],
    );

    useEffect(() => {
        if (acquiringSetting?.paymentMethod?.id !== paymentMethodId) {
            setValue?.(ACQUIRING_SETTING_FIELDS_KEYS.paymentType, '');
        }
    }, [filteredPaymentTypes]);

    useEffect(() => {
        if (isUpdate && acquiringSetting) {
            Object.keys(acquiringSetting).forEach((key) => {
                if (key === 'serviceApp' || key === 'paymentType' || key === 'paymentMethod') {
                    setValue?.(key as keyof AcquiringSettingFormFields, acquiringSetting[key]?.id);
                } else {
                    setValue?.(key as keyof AcquiringSettingFormFields, acquiringSetting[key as keyof AcquiringSettingFormFields as never]);
                }
            });
        }
    }, [acquiringSetting, isUpdate]);

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            { FIELDS.map((field) => (
                <Grid2 key={ field }>
                    <TextField
                        size="small"
                        control={ control }
                        name={ field }
                        label={ MAP_FIELDS_KEYS_TO_LABEL[field] }
                        type="text"
                    />
                </Grid2>
            )) }

            <Grid2>
                <Select
                    options={ paymentMethodFilterOptions }
                    name={ ACQUIRING_SETTING_FIELDS_KEYS.paymentMethod }
                    control={ control }
                    size="small"
                    label={ PROPERTY_PAYMENT_METHOD }
                    empty
                />
            </Grid2>

            <Grid2>
                <Select
                    options={ filteredPaymentTypes }
                    name={ ACQUIRING_SETTING_FIELDS_KEYS.paymentType }
                    control={ control }
                    size="small"
                    label={ PROPERTY_PAYMENT_TYPE }
                    empty
                />
            </Grid2>

            <Grid2>
                <Select
                    options={ serviceAppFilterOptions }
                    name={ ACQUIRING_SETTING_FIELDS_KEYS.serviceApp }
                    control={ control }
                    size="small"
                    label={ PROPERTY_SERVICE_APP }
                />
            </Grid2>
        </Grid2>
    );
});

export default AcquiringSettingForm;
