import {
    PROPERTY_AGE,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import { number, object, string } from 'yup';

export type AgeFormFields = {
    name: string;
    value: number;
};

export const AGE_DEFAULT_VALUES: AgeFormFields = {
    name: '',
    value: 0,
};

export const AGE_LABEL_DATA = {
    name: PROPERTY_TITLE,
    value: PROPERTY_AGE,
};

export enum AGE_FIELD_KEYS {
    name = 'name',
    value = 'value',
}

export const getAgeValidationSchema = () =>
    object()
        .shape({
            name: string().required(),
            value: number().required(),
        })
        .required();
