import { useMutation } from '@apollo/client';
import { PrtFormFields } from 'page/Prt/components/formData';
import {
    CREATE_PRT,
    CreatePrtResponseT,
    CreatePrtT,
    CreatePrtVarT,
    EMPTY_CREATE_PRT_RESPONSE,
} from 'shared/graphql/mutation/prt/PrtCreate';

import { CreatePrtDataT } from './CreatePrtData';

const usePrtCreate = (): CreatePrtDataT => {
    const [create, { loading, error }] = useMutation<CreatePrtT, CreatePrtVarT>(
        CREATE_PRT,
    );

    const createPrt = async (input: PrtFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        name: input.name,
                        order: input.order,
                        description: input.description || null,
                    },
                },
            });

            return data?.adminPrtCreate as CreatePrtResponseT;
        } catch {
            return EMPTY_CREATE_PRT_RESPONSE;
        }
    };

    return {
        createPrt,
        loading,
        error,
    };
};

export default usePrtCreate;
