import { MODAL_TITLE_UPDATE_AGE } from 'constants/modalTitles';

import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useFetchAge from 'shared/hook/age/fetch/fetchOne/useFetchAge';
import useUpdateAge from 'shared/hook/age/update/useUpdateAge';
import { useStore } from 'store';

import AgeForm from './AgeForm';
import {
    AGE_DEFAULT_VALUES,
    AgeFormFields, getAgeValidationSchema,
} from './formData';

const UpdateAgeModal: FC = observer(() => {
    const {
        isOpen, onClose, age, itemId: ageId,
    } = useStore('updateAgeModal');
    const { loading: loadingFetch } = useFetchAge(ageId);
    const { updateAge, loading: loadingUpdate } = useUpdateAge();
    const {
        control, handleSubmit, reset, setValue, formState: { isValid, isDirty },
    } = useForm<AgeFormFields>({
        defaultValues: AGE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getAgeValidationSchema()),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateAge = async (data: AgeFormFields) => {
        await updateAge(data, ageId);
        handleCloseModal();
    };

    return (
        <Modal open={ isOpen } maxWidth="sm">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_UPDATE_AGE }
            </ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch && <Spinner /> }
                <AgeForm
                    control={ control }
                    setValue={ setValue }
                    age={ age }
                    isUpdate
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={ () => {
                        reset(age);
                    } }
                >
                    Сбросить
                </Button>
                <Button variant="outlined" onClick={ handleCloseModal }>
                    Отмена
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleUpdateAge) }
                    autoFocus
                    disabled={ !isValid || !isDirty }
                    loading={ loadingUpdate }
                >
                    Обновить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateAgeModal;
