import {
    ERROR_ACQUIRING_SETTING_UPDATE,
    SUCCESS_ACQUIRING_SETTING_UPDATE,
} from 'constants/notificationMessage';

import { AcquiringSettingFormFields } from 'page/InternetAcquiring/components/formData';
import useAcquiringSettingUpdate from 'shared/data-hook/acquiringSetting/update/useAcquiringSettingUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useUpdateAcquiringSetting = () => {
    const { error, acquiringSettingUpdate, loading } = useAcquiringSettingUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const updateAcquiringSetting = async (data: AcquiringSettingFormFields, id: string) => {
        const response = await acquiringSettingUpdate(data, id);

        if (error || !response) {
            showError(ERROR_ACQUIRING_SETTING_UPDATE);

            return false;
        }

        showSuccess(SUCCESS_ACQUIRING_SETTING_UPDATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        updateAcquiringSetting,
        loading,
    };
};

export default useUpdateAcquiringSetting;
