import { PaymentT } from './Payment';

export type PaymentDocumentT = {
    id: string;
    number: string;
    datetime: Date;
    organization: {
        id: string;
        name: string;
    };
    comment: string | null;
    payments: PaymentT[];
};

export type PaymentDocumentsT = {
    paymentDocuments: PaymentDocumentT[];
    paymentDocumentsCount: number;
};

export const EMPTY_PAYMENT_DOCUMENTS: PaymentDocumentsT = {
    paymentDocuments: [],
    paymentDocumentsCount: 0,
};

export const EMPTY_PAYMENT_DOCUMENT_RESULT: PaymentDocumentT = {
    id: '',
    number: '',
    datetime: new Date(),
    organization: {
        id: '',
        name: '',
    },
    comment: '',
    payments: [],
};
