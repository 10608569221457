import { makeAutoObservable } from 'mobx';
import { EMPTY_PRT_RESULT, PrtT } from 'types/Prt';

export default class PrtStore {
    public prts: PrtT[] = [];

    public prt: PrtT = EMPTY_PRT_RESULT;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setPrts(prts: PrtT[], count: number) {
        this.prts = prts;
        this.count = count;
    }

    public setPrt(prt: PrtT) {
        this.prt = prt;
    }
}
