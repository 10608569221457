import { FC } from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';

type PropsT = {
    color?: string | null;
};

const AdornmentColor: FC<PropsT> = observer((props) => {
    const { color } = props;

    return (
        <Box
            sx={ {
                width: '20px',
                height: '20px',
                right: '12px',
                borderRadius: '50%',
                position: 'absolute',
                border: '1px solid black',
                backgroundColor: color || 'transparent',
            } }
        />
    );
});

export default AdornmentColor;
