import { useEffect } from 'react';
import useAcquiringSetting from 'shared/data-hook/acquiringSetting/fetch/fetchOne/useAcquiringSetting';
import { useStore } from 'store';

const useFetchAcquiringSetting = (id: string) => {
    const { fetchAcquiringSetting, loading } = useAcquiringSetting();
    const { setAcquiringSetting } = useStore('updateAcquiringSettingModal');

    useEffect(() => {
        fetchAcquiringSetting(id).then((fetchedAcquiringSetting) => {
            setAcquiringSetting(fetchedAcquiringSetting);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchAcquiringSetting;
