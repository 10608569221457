import {
    PROPERTY_CLIENT,
    PROPERTY_MEMBER_CARD_TYPE,
} from 'constants/propertyNames';

import { FC } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { TextField as TextFieldBase, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import DatePicker from 'shared/component/Form/DatePicker';
import Select from 'shared/component/Form/Select';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { useStore } from 'store';
import { MemberCardT } from 'types/MemberCard';

import {
    UPDATE_MEMBER_CARD_FIELDS_KEYS,
    UPDATE_MEMBER_CARD_LABEL_DATA,
    UpdateMemberCardFormFields,
} from './updateFormData';

type PropsT = {
    control: Control<UpdateMemberCardFormFields>;
    setValue: UseFormSetValue<UpdateMemberCardFormFields>;
    memberCard: MemberCardT;
};

const UpdateMemberCardForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, memberCard,
    } = props;
    const { datetimeEnd, datetimeStart, client: { lastName, firstName, middleName } } = memberCard;
    const { getFilterOption } = useStore('filterOptions');
    const memberCardTypeFilterOptions = getFilterOption('memberCardType');

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            <Grid2>
                <Select
                    name={ UPDATE_MEMBER_CARD_FIELDS_KEYS.memberCardType }
                    control={ control }
                    label={ PROPERTY_MEMBER_CARD_TYPE }
                    options={ memberCardTypeFilterOptions }
                />
            </Grid2>
            <Grid2>
                <TextFieldBase
                    fullWidth
                    label={ PROPERTY_CLIENT }
                    value={ `${lastName ?? ''} ${firstName ?? ''} ${middleName ?? ''}` }
                    size="small"
                    inputProps={ { readOnly: true } }
                />
            </Grid2>
            <Grid2 direction="row" container>
                <Grid2 xs={ 5 }>
                    <DatePicker
                        fullWidth
                        setValue={ setValue }
                        value={ dayjs(datetimeStart) }
                        control={ control }
                        label={ UPDATE_MEMBER_CARD_LABEL_DATA.datetimeStart }
                        name={ UPDATE_MEMBER_CARD_FIELDS_KEYS.datetimeStart }
                        customError
                    />
                </Grid2>
                <Grid2 xs={ 5 }>
                    <DatePicker
                        fullWidth
                        setValue={ setValue }
                        value={ dayjs(datetimeEnd) }
                        control={ control }
                        label={ UPDATE_MEMBER_CARD_LABEL_DATA.datetimeEnd }
                        name={ UPDATE_MEMBER_CARD_FIELDS_KEYS.datetimeEnd }
                        customError
                    />
                </Grid2>
                <Grid2 xs={ 2 }>
                    <Switch
                        size="small"
                        label={ UPDATE_MEMBER_CARD_LABEL_DATA.isActive }
                        name={ UPDATE_MEMBER_CARD_FIELDS_KEYS.isActive }
                        control={ control }
                    />
                </Grid2>
                <Grid2>
                    <Typography variant="h6">Параметры операций начисления кэшбэков FIAT</Typography>
                </Grid2>
                <Grid2 container xs={ 12 }>
                    <Grid2 xs={ 6 }>
                        <TextField
                            fullWidth
                            name={ UPDATE_MEMBER_CARD_FIELDS_KEYS.cashbackFiatTotalAccrued }
                            label={ UPDATE_MEMBER_CARD_LABEL_DATA.cashbackFiatTotalAccrued }
                            control={ control }
                            size="small"
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <TextField
                            fullWidth
                            name={ UPDATE_MEMBER_CARD_FIELDS_KEYS.cashbackFiatTotalOperations }
                            label={ UPDATE_MEMBER_CARD_LABEL_DATA.cashbackFiatTotalOperations }
                            control={ control }
                            size="small"
                            type="number"
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
    );
});

export default UpdateMemberCardForm;
