import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ClientFullNameDTO } from 'shared/data-hook/utils/DTO/Client';
import { MemberCardT } from 'types/MemberCard';

const DTO = t.type({
    id: t.string,
    is_active: t.boolean,
    member_card_type: t.type({
        id: t.string,
        title: t.string,
    }),
    datetime_start: t.string,
    datetime_end: t.string,
    client: t.intersection([
        ClientFullNameDTO,
        t.type({
            client_data: t.array(t.type({
                related_clients: t.array(ClientFullNameDTO),
            })),
        }),
    ]),
    cashback_fiat_total_accrued: t.number,
    cashback_fiat_total_operations: t.number,
});

export type MemberCardDTOT = t.TypeOf<typeof DTO>;

export const MemberCardDTO = tPromise.extendDecoder<MemberCardDTOT, MemberCardT>(
    DTO,
    ({
        id,
        is_active,
        member_card_type,
        datetime_start,
        datetime_end,
        client: { client_data, ...fullName },
        cashback_fiat_total_accrued,
        cashback_fiat_total_operations,
    }) => ({
        id,
        isActive: is_active,
        memberCardType: member_card_type,
        datetimeStart: new Date(datetime_start),
        datetimeEnd: new Date(datetime_end),
        client: fullName,
        cashbackFiatTotalAccrued: cashback_fiat_total_accrued,
        cashbackFiatTotalOperations: cashback_fiat_total_operations,
        relatedClients: client_data?.[0]?.related_clients,
    }),
) as t.Type<MemberCardT, MemberCardDTOT>;
