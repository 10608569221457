import { useMutation } from '@apollo/client';
import { AgeFormFields } from 'page/Age/components/formData';
import { AGE_UPDATE, AgeUpdateResponseDataT, AgeUpdateVar } from 'shared/graphql/mutation/age/AgeUpdate';
import { AgeT } from 'types/Age';

import { AgeUpdateDataT } from './AgeUpdateData';

const useAgeUpdate = (): AgeUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        AgeUpdateResponseDataT,
        AgeUpdateVar
    >(AGE_UPDATE);

    const updateAction = async (
        input: AgeFormFields,
        ageId: AgeT['id'],
    ) => {
        if (!ageId) {
            // TODO: use Sentry
            throw new Error('Age is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: ageId,
                    set: {
                        name: input.name,
                        value: input.value,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        ageUpdate: updateAction,
        loading,
        error,
    };
};

export default useAgeUpdate;
