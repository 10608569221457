import { gql } from '@apollo/client';

export type CreateTaskStatusPayloadT = {
    category: string;
    name: string;
    position: number;
}

export type CreateTaskStatusResponseT = {
    status: string;
    reason: null | string;
}

export type CreateTaskStatusVarT = {
    object: CreateTaskStatusPayloadT;
};

export type CreateTaskStatusT = {
    adminTaskStatusCreate: CreateTaskStatusResponseT;
}

export const EMPTY_CREATE_TASK_STATUS_RESPONSE: CreateTaskStatusResponseT = {
    status: 'error',
    reason: '',
};

export const CREATE_TASK_STATUS = gql`
    mutation TaskStatusCreate($object: task_status_insert_input = {}) {
        createTaskStatus: insert_task_status_one(
            object: $object
        ) {
            id
        }
    }
`;
