import {
    ERROR_AGE_CREATE, SUCCESS_AGE_CREATE,
} from 'constants/notificationMessage';

import { AgeFormFields } from 'page/Age/components/formData';
import useAgeCreate from 'shared/data-hook/age/create/useAgeCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateAge = () => {
    const { error, loading, createAge: actionCreate } = useAgeCreate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createAge = async (input: AgeFormFields): Promise<boolean> => {
        await actionCreate(input);

        if (error) {
            showError(ERROR_AGE_CREATE);

            return false;
        }

        showSuccess(SUCCESS_AGE_CREATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        createAge,
        loading,
    };
};

export default useCreateAge;
