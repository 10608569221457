import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { prepareAcquiringSettingHeaderData } from '../domain/prepareAcquiringSettingHeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const InternetAcquiringList: FC<PropsT> = observer((props) => {
    const {
        sortModel,
        filterModel,
        page,
        pageSize,
        loading,
        setPageSize,
        setPage,
        setSortModel,
        setFilterModel,
    } = props;
    const { acquiringSettings, count } = useStore('acquiringSettings');
    const { getFilterOption } = useStore('filterOptions');
    const serviceAppFilterOptions = getFilterOption('serviceApp');
    const paymentTypeFilterOptions = getFilterOption('paymentType');
    const paymentMethodFilterOptions = getFilterOption('paymentMethod');

    const columns = prepareAcquiringSettingHeaderData(
        serviceAppFilterOptions,
        paymentMethodFilterOptions,
        paymentTypeFilterOptions,
    );

    return (
        <DataGrid
            rows={ acquiringSettings }
            rowCount={ count }
            columns={ columns }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
        />
    );
});

export default InternetAcquiringList;
