import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { UserT } from 'shared/graphql/query/user/UserQuery';

const DTO = t.type({
    active_client_id: Nullable(t.string),
});

export type UserDTOT = t.TypeOf<typeof DTO>;

export const UserDTO = tPromise.extendDecoder<UserDTOT, UserT>(
    DTO,
    ({
        active_client_id,
    }) => ({
        activeClientId: active_client_id,
    }),
) as t.Type<UserT, UserDTOT>;
