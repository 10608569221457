import { useEffect } from 'react';
import usePrt from 'shared/data-hook/prt/fetch/fetchOne/usePrt';
import { useStore } from 'store';

const useFetchPrt = (id: string) => {
    const { fetchPrt, loading } = usePrt();
    const { setPrt } = useStore('updatePrtModal');

    useEffect(() => {
        fetchPrt(id).then((fetchedPrt) => {
            setPrt(fetchedPrt);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchPrt;
