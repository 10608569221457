import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import {
    PRICES_BY_SERVICE_CATEGORIES_QUERY, PricesByServiceCategoriesResponseT,
} from 'shared/graphql/query/PricesByServiceCategory/PricesByServiceCategoryQuery';
import { useStore } from 'store';
import { FetchDataParams } from 'types/DataGrid';
import { FetchDataByPriceTypeIdAndPriceSeasonIdQueryVarsT } from 'types/Graphql';
import { EMPTY_PRICES_BY_SERVICE_CATEGORIES_RESULT } from 'types/PricesByServiceCategory';

import { PricesByServiceCategoriesDTO } from '../DTO/PricesByServiceCategoriesDTO';

import { PricesByServiceCategoriesDataT } from './PricesByServiceCategoriesData';

const usePricesByServiceCategories = (): PricesByServiceCategoriesDataT => {
    const [fetch, { data, loading }] = useLazyQuery<PricesByServiceCategoriesResponseT, FetchDataByPriceTypeIdAndPriceSeasonIdQueryVarsT>(PRICES_BY_SERVICE_CATEGORIES_QUERY, {
        fetchPolicy: 'network-only',
    });
    const { serviceApp } = useStore('serviceApp');

    const decodedPricesByServiceCategories = decodeDTO(
        PricesByServiceCategoriesDTO(serviceApp.fiatCurrencyTitle),
        EMPTY_PRICES_BY_SERVICE_CATEGORIES_RESULT,
        (pricesByServiceCategoriesData) => pricesByServiceCategoriesData,
        {
            pricesByServiceCategories: data?.serviceCategories,
            pricesByServiceCategoriesCount: data?.serviceCategoriesCount,
        },
    );

    const fetchPricesByServiceCategories = async (priceTypeId: string, priceSeasonId: string, {
        limit, offset, orderBy = {}, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<any> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                    priceTypeId,
                    priceSeasonId,
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchPricesByServiceCategories,
        data: decodedPricesByServiceCategories.pricesByServiceCategories,
        count: decodedPricesByServiceCategories.pricesByServiceCategoriesCount,
        loading,
    };
};

export default usePricesByServiceCategories;
