import {
    ERROR_TASK_TYPE_CREATE,
    SUCCESS_TASK_TYPE_CREATE,
} from 'constants/notificationMessage';

import { TaskTypeFormFields } from 'page/TaskType/components/formData';
import useTaskTypeCreate from 'shared/data-hook/taskType/create/useTaskTypeCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateTaskType = () => {
    const { error, loading, createTaskType: actionCreate } = useTaskTypeCreate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createTaskType = async (input: TaskTypeFormFields): Promise<boolean> => {
        await actionCreate(input);

        if (error) {
            showError(ERROR_TASK_TYPE_CREATE);

            return false;
        }

        showSuccess(SUCCESS_TASK_TYPE_CREATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        createTaskType,
        loading,
    };
};

export default useCreateTaskType;
