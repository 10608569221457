import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { PRT_HEADER_DATA } from '../domain/HeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const PrtList: FC<PropsT> = observer((props) => {
    const { prts, count } = useStore('prt');

    return (
        <DataGrid
            rows={ prts }
            rowCount={ count }
            columns={ PRT_HEADER_DATA }
            { ...props }
        />
    );
});

export default PrtList;
