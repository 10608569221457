import { PAGE_TITLE_PAYMENT } from 'constants/pageTitles';
import { PROPERTY_SEARCH_BY_NAME } from 'constants/propertyNames';
import { DEFAULT_DEBOUNCE_DELAY_IN_MS } from 'constants/time';

import {
    ChangeEvent, FC, useEffect, useState,
} from 'react';
import { TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import UpdatePaymentDocumentModal from 'page/PaymentDocument/components/UpdatePaymentDocumentModal';
import ControlPanel from 'shared/component/ControlPanel';
import usePayments from 'shared/data-hook/payment/fetch/fetchAll/usePayments';
import {
    ORGANIZATION_LOCATION_FILTER_OPTIONS_QUERY,
} from 'shared/graphql/query/organizationLocation/OrganizationLocationQuery';
import { PAYMENT_METHOD_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/paymentMethod/PaymentMethodQuery';
import { PAYMENT_TYPE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/paymentType/PaymentTypeQuery';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import useFetchPaymentTypes from 'shared/hook/paymentType/fetch/FetchAll/useFetchPaymentTypes';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';
import { SearchT } from 'types/DataGrid';

import PaymentList from './components/PaymentList';
import { PAYMENT_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: paymentsData, loading: paymentsLoading, count: rowCount, fetchPayments,
    } = usePayments();
    const { loading: paymentTypesLoading } = useFetchPaymentTypes();
    const { loading: organizationLocationFilterOptionsLoading } = useFetchFilterOptions(ORGANIZATION_LOCATION_FILTER_OPTIONS_QUERY, 'organizationLocation');
    const { loading: paymentTypeFilterOptionsLoading } = useFetchFilterOptions(PAYMENT_TYPE_FILTER_OPTIONS_QUERY, 'paymentType');
    const { loading: paymentMethodFilterOptionsLoading } = useFetchFilterOptions(PAYMENT_METHOD_FILTER_OPTIONS_QUERY, 'paymentMethod');
    const { setPayments } = useStore('payment');
    const { refetchTrigger } = useStore('refetchTrigger');
    const loading = paymentsLoading || organizationLocationFilterOptionsLoading || paymentTypeFilterOptionsLoading || paymentMethodFilterOptionsLoading || paymentTypesLoading;
    const [searchFilters, setSearchFilters] = useState<SearchT>({ name: '' });

    usePageTitle(PAGE_TITLE_PAYMENT);

    useFetchDataForGrid(
        sortModel,
        filterModel,
        page,
        pageSize,
        fetchPayments,
        refetchTrigger,
        PAYMENT_HEADER_DATA,
        searchFilters,
    );

    useEffect(() => {
        setPayments(paymentsData, rowCount);
    }, [paymentsData, paymentsLoading]);

    const handleSetSearchValue = debounce((name: string) => {
        setSearchFilters({ name });
    }, DEFAULT_DEBOUNCE_DELAY_IN_MS);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetSearchValue(e.target.value);
    };

    return (
        <StyledPageWrap>
            <ControlPanel>
                <TextField
                    label={ PROPERTY_SEARCH_BY_NAME }
                    size="small"
                    onChange={ handleSearchChange }
                />
            </ControlPanel>
            <PaymentList
                loading={ loading }
                pageSize={ pageSize }
                page={ page }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <UpdatePaymentDocumentModal />
        </StyledPageWrap>
    );
});

export default List;
