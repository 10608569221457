import { useMutation } from '@apollo/client';
import {
    EXPORT_PRICE_MUTATION,
    ExportPriceResponseDataT,
    ExportPriceVarT,
    PayloadT,
} from 'shared/graphql/mutation/export/ExportPrice';

import { ExportPriceDataT } from './ExportPriceData';

const useExportPriceData = (): ExportPriceDataT => {
    const [exportPriceData, { loading, error }] = useMutation<ExportPriceResponseDataT, ExportPriceVarT>(
        EXPORT_PRICE_MUTATION,
    );

    const action = async (appId: string, payload: PayloadT): Promise<ExportPriceResponseDataT> => {
        try {
            const { data: exportData } = await exportPriceData({
                variables: {
                    appId,
                    payload: {
                        filter: {
                            price_season_id: payload.priceSeasonId,
                            price_type_id: payload.priceTypeId,
                        },
                    },
                },
            });

            return exportData;
        } catch (actionError) {
            // eslint-disable-next-line no-console
            console.error((actionError as Error).message);
            throw actionError;
        }
    };

    return {
        export: action,
        error,
        loading,
    };
};

export default useExportPriceData;
