import {
    ERROR_TASK_TYPE_UPDATE,
    SUCCESS_TASK_TYPE_UPDATE,
} from 'constants/notificationMessage';

import { TaskTypeFormFields } from 'page/TaskType/components/formData';
import useTaskTypeUpdate from 'shared/data-hook/taskType/update/useTaskTypeUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useUpdateTaskType = () => {
    const { error, taskTypeUpdate, loading } = useTaskTypeUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const updateTaskType = async (data: TaskTypeFormFields, id: string) => {
        const response = await taskTypeUpdate(data, id);

        if (error || !response) {
            showError(ERROR_TASK_TYPE_UPDATE);

            return false;
        }

        showSuccess(SUCCESS_TASK_TYPE_UPDATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        updateTaskType,
        loading,
    };
};

export default useUpdateTaskType;
