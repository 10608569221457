import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { AGES_QUERY, AgesResponseT } from 'shared/graphql/query/age/ageQuery';
import { EMPTY_AGES_RESULT } from 'types/Age';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';

import { AgesDTO } from '../DTO/AgesDTO';

import { AgesDataT } from './AgesData';

const useAges = (): AgesDataT => {
    const [fetch, { data, loading, error }] = useLazyQuery<AgesResponseT, QueryVarsT>(
        AGES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedAgesData = decodeDTO(
        AgesDTO,
        EMPTY_AGES_RESULT,
        (ages) => ages,
        {
            ages: data?.ages,
            agesCount: data?.agesCount,
        },
    );

    const fetchAges = async ({
        limit,
        offset,
        orderBy = {},
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        data: decodedAgesData.ages,
        count: decodedAgesData.agesCount,
        fetchAges,
        loading,
        error,
    };
};

export default useAges;
