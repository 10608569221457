import { ERROR_EXPORT_PRICE, SUCCESS_EXPORT_PRICE } from 'constants/notificationMessage';

import { useState } from 'react';
import useExportPriceData from 'shared/data-hook/export/useExportPriceData';
import { PayloadT } from 'shared/graphql/mutation/export/ExportPrice';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

type ReturnT = {
    isLoading: boolean;
    send: (payload: PayloadT) => Promise<void>;
};

const useExportPrice = (): ReturnT => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { export: exportData } = useExportPriceData();
    const { serviceApp: { id: appId } } = useStore('serviceApp');
    const { showSuccess, showError } = useNotification();

    const send = async (payload: PayloadT): Promise<void> => {
        setIsLoading(true);

        try {
            const result = await exportData(appId, payload);

            if (!result || result.adminExportPrices.status === 'error') {
                throw new Error();
            }

            showSuccess(SUCCESS_EXPORT_PRICE);
        } catch {
            showError(ERROR_EXPORT_PRICE);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        send,
    };
};

export default useExportPrice;
