import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { TaskTypeT } from 'types/TaskType';

export type TaskTypesResponseT = {
    taskTypes: TaskTypeT[];
    taskTypesCount: AggregateT;
};

export type TaskTypeResponseT = {
    taskType: TaskTypeT;
};

export const TASK_TYPE_QUERY = gql`
    query TaskType($id: String!) {
        taskType: task_type_by_pk(id: $id) {
            id
            name
            position
            is_active
            default_duration_hours
            default_title
            default_description
            default_should_create_finish_comment
        }
    }
`;

export const TASK_TYPES_QUERY = gql`
    query TaskTypes(
        $limit: Int
        $offset: Int
        $orderBy: task_type_order_by! = {}
        $where: task_type_bool_exp! = {}
    ) {
        taskTypes: task_type(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            name
            position
            is_active
            default_duration_hours
            default_title
            default_description
            default_should_create_finish_comment
        }
        taskTypesCount: task_type_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
