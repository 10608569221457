import { useMutation } from '@apollo/client';
import { MemberCardTypeFormFields } from 'page/MemberCardType/components/formData';
import {
    MEMBER_CARD_TYPE_UPDATE,
    MemberCardTypeUpdateResponseDataT, MemberCardTypeUpdateVar,
} from 'shared/graphql/mutation/memberCardType/MemberCardTypeUpdate';
import { MemberCardTypeT } from 'types/MemberCardType';

import { MemberCardTypeUpdateDataT } from './MemberCardTypeUpdateData';

const useMemberCardTypeUpdate = (): MemberCardTypeUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        MemberCardTypeUpdateResponseDataT,
        MemberCardTypeUpdateVar
    >(MEMBER_CARD_TYPE_UPDATE);

    const updateAction = async (
        input: MemberCardTypeFormFields,
        memberCardTypeId: MemberCardTypeT['id'],
    ) => {
        if (!memberCardTypeId) {
            // TODO: use Sentry
            throw new Error('MemberCardType is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: memberCardTypeId,
                    set: {
                        title: input.title,
                        is_active: input.isActive,
                        color: input.color || null,
                        expiration_period: input.expirationPeriod,
                        cashback_fiat_operations_limit: input.cashbackFiatOperationsLimit,
                        cashback_fiat_sum_limit: input.cashbackFiatSumLimit,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        memberCardTypeUpdate: updateAction,
        loading,
        error,
    };
};

export default useMemberCardTypeUpdate;
