import { gql } from '@apollo/client';
import { AgeT } from 'types/Age';

export type AgeDeleteResponseDataT = {
    deleteAge: {
        id: AgeT['id'];
    };
} | null | undefined;

export type AgeDeleteVarT = {
    id: AgeT['id'];
};

export const AGE_DELETE = gql`
    mutation DeleteAge($id: String = "") {
        deleteAge: delete_age_by_pk(id: $id) {
            id
        }
    }
`;
