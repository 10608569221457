import { gql } from '@apollo/client';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

import { PriceSaleRoundRolePermissionUpdatePayloadT } from './PriceSalesRoundUpdate';

export type PriceSalesRoundCreatePayloadT = {
    id?: string;
    title: string;
    position: number;
    price_type_id: string;
    end_day: number;
    end_hour: number;
    end_month: number;
    end_week: number;
    start_day: number;
    start_hour: number;
    start_month: number;
    start_week: number;
    end_day_from_start: boolean;
    end_hour_from_start: boolean;
    end_month_from_start: boolean;
    end_week_from_start: boolean;
    start_day_from_start: boolean;
    start_hour_from_start: boolean;
    start_month_from_start: boolean;
    start_week_from_start: boolean;
    sale_rate_title: string | null;
    sale_rate_short_title: string | null;
    return_rate_title: string | null;
    return_rate_short_title: string | null;
    club_return_rate_title: string | null;
    club_return_rate_short_title: string | null;
    min_rate_title: string | null;
    min_rate_short_title: string | null;
    transfer_title: string | null;
    transfer_short_title: string | null;
    transfer_return_rate_title: string | null;
    transfer_sale_rate_title: string | null;
    price_sale_round_role_permissions?: {
        data: PriceSaleRoundRolePermissionUpdatePayloadT[];
        on_conflict: {
            constraint: string;
            update_columns: string[];
        };
    };
};

export type PriceSalesRoundCreateFormFieldsPayloadT = Omit<PriceSalesRoundT, 'id'>

export type CreatePriceSalesRoundResponseT = {
    status: string;
    reason: null | string;
};

export type CreatePriceSalesRoundVarT = {
    appId: string;
    payload: PriceSalesRoundCreatePayloadT;
};

export type CreatePriceSalesRoundT = {
    priceCreateSalesRound: CreatePriceSalesRoundResponseT;
};

export const PRICE_SALES_ROUND_CREATE = gql`
    mutation CreatePriceSalesRound($appId: String!, $payload: json!) {
        priceCreateSalesRound(app_id: $appId, payload: $payload) {
            status
            reason
        }
    }
`;
