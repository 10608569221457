import { MODAL_TITLE_UPDATE_TASK_STATUS } from 'constants/modalTitles';

import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useFetchTaskStatus from 'shared/hook/taskStatus/fetch/fetchOne/useFetchTaskStatus';
import useUpdateTaskStatus from 'shared/hook/taskStatus/update/useUpdateTaskStatus';
import { useStore } from 'store';

import {
    getTaskStatusValidationSchema,
    TASK_STATUS_DEFAULT_VALUES,
    TaskStatusFormFields,
} from './formData';
import TaskStatusForm from './TaskStatusForm';

const UpdateTaskStatusModal: FC = observer(() => {
    const {
        isOpen, onClose, taskStatus, itemId: taskStatusId,
    } = useStore('updateTaskStatusModal');
    const { loading: loadingFetch } = useFetchTaskStatus(taskStatusId);
    const { updateTaskStatus, loading: loadingUpdate } = useUpdateTaskStatus();
    const {
        control, handleSubmit, reset, setValue, formState: { isValid, isDirty },
    } = useForm<TaskStatusFormFields>({
        defaultValues: TASK_STATUS_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getTaskStatusValidationSchema()),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateTaskStatus = async (data: TaskStatusFormFields) => {
        await updateTaskStatus(data, taskStatusId);
        handleCloseModal();
    };

    return (
        <Modal open={ isOpen } maxWidth="sm">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_UPDATE_TASK_STATUS }
            </ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch && <Spinner /> }
                <TaskStatusForm
                    isUpdate
                    control={ control }
                    setValue={ setValue }
                    taskStatus={ taskStatus }
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={ () => {
                        reset(taskStatus);
                    } }
                >
                    Сбросить
                </Button>
                <Button variant="outlined" onClick={ handleCloseModal }>
                    Отмена
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleUpdateTaskStatus) }
                    autoFocus
                    disabled={ !isValid || !isDirty }
                    loading={ loadingUpdate }
                >
                    Обновить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateTaskStatusModal;
