import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { TASK_STATUS_QUERY, TaskStatusResponseT } from 'shared/graphql/query/taskStatus/TaskStatusQuery';
import { EMPTY_TASK_STATUS_RESULT, TaskStatusT } from 'types/TaskStatus';

import { TaskStatusDTO } from '../DTO/TaskStatusDTO';

import { TaskStatusDataT } from './TaskStatusData';

const useTaskStatus = (): TaskStatusDataT => {
    const [fetch, { loading, error }] = useLazyQuery<TaskStatusResponseT>(
        TASK_STATUS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchTaskStatus = async (id: string): Promise<TaskStatusT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                TaskStatusDTO,
                EMPTY_TASK_STATUS_RESULT,
                (taskStatus) => taskStatus,
                data?.taskStatus,
            );
        } catch {
            return EMPTY_TASK_STATUS_RESULT;
        }
    };

    return {
        fetchTaskStatus,
        loading,
        error,
    };
};

export default useTaskStatus;
