import { action, makeObservable, observable } from 'mobx';
import { MemberCardTypeT } from 'types/MemberCardType';

import BaseModalStore from './BaseModalStore';

export default class UpdateMemberCardTypeModal extends BaseModalStore {
    public memberCardType: MemberCardTypeT | undefined = undefined;

    public setMemberCardType(memberCardTypes: MemberCardTypeT) {
        this.memberCardType = memberCardTypes;
    }

    constructor() {
        super();

        makeObservable(this, {
            memberCardType: observable,
            setMemberCardType: action.bound,
        });
    }
}
