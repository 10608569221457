import { useLazyQuery } from '@apollo/client';
import { PaymentTypesDTO } from 'shared/data-hook/paymentType/fetch/DTO/PaymentTypesDTO';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { PAYMENT_TYPES_QUERY, PaymentTypesResponseT } from 'shared/graphql/query/paymentType/PaymentTypeQuery';
import { FetchDataParams } from 'types/DataGrid';
import { EMPTY_PAYMENT_TYPES } from 'types/PaymentType';

import { PaymentTypesDataT } from './PaymentTypesData';

const usePaymentTypes = (): PaymentTypesDataT => {
    const [fetch, { data, loading }] = useLazyQuery<PaymentTypesResponseT>(
        PAYMENT_TYPES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedPaymentTypesData = decodeDTO(
        PaymentTypesDTO,
        EMPTY_PAYMENT_TYPES,
        (paymentTypes) => paymentTypes,
        {
            paymentTypes: data?.paymentTypes,
            paymentTypesCount: data?.paymentTypesCount,
        },
    );

    const fetchPaymentTypes = async ({
        limit,
        offset,
        orderBy = { position: 'asc' },
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        fetchPaymentTypes,
        loading,
        data: decodedPaymentTypesData.paymentTypes,
        count: decodedPaymentTypesData.paymentTypesCount,
    };
};

export default usePaymentTypes;
