import { MODAL_TITLE_DISPLAY_COLUMNS } from 'constants/modalTitles';
import { PROPERTY_DISPLAY_COLUMNS } from 'constants/propertyNames';

import { FC, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { observer } from 'mobx-react';
import MultipleSelect from 'shared/component/Form/MultipleSelect';
import DisplayColumnsIcon from 'shared/component/Icons/DisplayColumn';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useDisplayColumns from 'shared/hook/displayColumns/display/useDisplayColumns';
import { useStore } from 'store';
import { DisplayColumnsModalSupportedTable } from 'types/DataGrid';
import { OptionT } from 'types/Option';

import {
    DISPLAY_COLUMNS_DEFAULT_VALUES,
    DISPLAY_COLUMNS_FIELDS_KEYS,
    DISPLAY_COLUMNS_VALIDATION_SCHEMA,
    DisplayColumnsFormFields,
} from './formData';

type DisplayColumnsModalPropsT = {
    table: DisplayColumnsModalSupportedTable;
    columns: GridColDef[];
    initialState: GridInitialStatePremium;
    setColumnVisibilityModel: (model: GridColumnVisibilityModel) => void;
};

const DisplayColumnsModal: FC<DisplayColumnsModalPropsT> = observer((props) => {
    const {
        columns, initialState, setColumnVisibilityModel, table,
    } = props;
    const {
        onOpen, isOpen, onClose, selectedColumns, setSelectedColumns,
    } = useStore('displayColumns');
    const [loading, setLoading] = useState(false);
    const {
        control, handleSubmit, reset, formState: { isValid }, setValue,
    } = useForm<DisplayColumnsFormFields>({
        defaultValues: DISPLAY_COLUMNS_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(DISPLAY_COLUMNS_VALIDATION_SCHEMA),
    });

    const { field: columnsField } = useController({ control, name: DISPLAY_COLUMNS_FIELDS_KEYS.columns });
    const { displayColumns } = useDisplayColumns(setColumnVisibilityModel);

    const pinnedColumns: string[] = [
        ...(initialState.pinnedColumns?.left || []),
        ...(initialState.pinnedColumns?.right || []),
    ];

    const optionsColumn: OptionT[] = [...new Map(
        columns
            .filter((item) => item.headerName && !pinnedColumns.includes(item.field.split('-')[0]))
            .map((item) => [
                item.headerName,
                { value: item.field.split('-')[0], label: item.headerName as string },
            ]),
    ).values()];

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleDisplayColumn = (data: DisplayColumnsFormFields) => {
        setLoading(true);

        setSelectedColumns({
            ...selectedColumns,
            [table]: data.columns ?? [],
        });
        displayColumns(data, optionsColumn, columns);

        setLoading(false);
        handleCloseModal();
    };

    const handleReset = () => {
        const optionValues = optionsColumn.map((option) => option.value);

        setValue(DISPLAY_COLUMNS_FIELDS_KEYS.columns, optionValues);
    };

    useEffect(() => {
        handleReset();
    }, [columns]);

    useEffect(() => {
        if (isOpen && selectedColumns[table].length) {
            reset({
                columns: selectedColumns[table],
            });
        }
    }, [isOpen]);

    return (
        <>
            <Button size="small" variant="outlined" startIcon={ <DisplayColumnsIcon /> } onClick={ () => onOpen() }>
                Отобразить столбцы
            </Button>
            <Modal open={ isOpen } fullWidth={ true } maxWidth="lg">
                <ModalTitle onClose={ onClose }>{ MODAL_TITLE_DISPLAY_COLUMNS }</ModalTitle>
                <DialogContent dividers={ true }>
                    <Grid2 spacing={ 2 } container padding={ 2 }>
                        <Grid2 xs={ 12 }>
                            <MultipleSelect
                                options={ optionsColumn }
                                name={ DISPLAY_COLUMNS_FIELDS_KEYS.columns }
                                control={ control }
                                size="small"
                                selectedValues={ columnsField.value }
                                label={ PROPERTY_DISPLAY_COLUMNS }
                            />
                        </Grid2>
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={ () => handleReset() }>Сбросить</Button>
                    <Button onClick={ handleCloseModal }>Отмена</Button>
                    <LoadingButton
                        color="success"
                        variant="contained"
                        autoFocus
                        loading={ loading }
                        onClick={ handleSubmit(handleDisplayColumn) }
                        disabled={ !isValid }
                    >
                        Подтвердить
                    </LoadingButton>
                </DialogActions>
            </Modal>
        </>
    );
});

export default DisplayColumnsModal;
