export type AgeT = {
    id: string;
    name: string;
    value: number;
};

export type AgesT = {
    ages: AgeT[];
    agesCount: number;
};

export const EMPTY_AGES_RESULT: AgesT = {
    ages: [],
    agesCount: 0,
};

export const EMPTY_AGE_RESULT: AgeT = {
    id: '',
    name: '',
    value: 0,
};
