import { PRICE_MEMBER_CARD_DATA_GRID_INITIAL_STATE } from 'constants/dataGrid';

import React, { FC, memo } from 'react';
import { TabContext as BaseTabContext, TabList } from '@mui/lab';
import {
    Box, FormControl, InputLabel, MenuItem, Select, Tab,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Wrapper } from 'page/Main/styles';
import { StyledPricePageTabPanel, StyledPricePageWrap } from 'page/Price/domain/Styled';
import ControlPanel from 'shared/component/ControlPanel';
import DataGrid from 'shared/component/DataGrid';
import { DataGridPropsT, FetchDataParams } from 'types/DataGrid';
import { PriceMemberCardT } from 'types/PriceMemberCard';

import { preparePriceMemberCardHeaderData } from '../domain/preparePriceMemberCardHeaderData';
import { setHeaderData } from '../domain/setHeaderData';

import useTabContext from './useTabContext';

type PropsT = {
    tab: string;
    handleTabChange: (newTab: string) => void;
    loading: boolean;
    fetchSalesRounds: (priceTypeId?: string | undefined) => Promise<void>;
    fetchPriceMemberCards: (
        priceTypeId: string,
        priceSeasonId: string,
        params?: FetchDataParams
    ) => Promise<PriceMemberCardT[]>;
} & DataGridPropsT;

const TabContext: FC<PropsT> = observer((props: PropsT) => {
    const {
        filterModel,
        handleTabChange,
        loading,
        page,
        pageSize,
        setFilterModel,
        setPage,
        setPageSize,
        setSortModel,
        sortModel,
        tab,
    } = props;
    const {
        activePriceSeason,
        columnGroupingModel,
        columnVisibilityModel,
        count,
        headerData,
        memberCardTypeFilterOptions,
        priceMemberCardsList,
        priceSalesRounds,
        priceSeasonOptions,
        priceSeasons,
        priceTypes,
        selectedPriceSeason,
        setActivePriceSeason,
        setColumnVisibilityModel,
        setSelectedPriceSeason,
    } = useTabContext(props);

    return (
        <Wrapper>
            <BaseTabContext value={ tab }>
                <Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
                    <TabList
                        scrollButtons={ true }
                        variant="scrollable"
                        onChange={ (_, newTab) => {
                            handleTabChange(newTab);
                        } }
                    >
                        {
                            tab && priceTypes.map((item) => (
                                <Tab label={ item.title } value={ item.id } key={ item.id } />
                            ))
                        }
                    </TabList>
                </Box>
                <StyledPricePageWrap>
                    <ControlPanel>
                        {
                            (priceSeasonOptions.length > 0 && activePriceSeason.id !== '') && (
                                <FormControl sx={ { minWidth: '220px' } }>
                                    <InputLabel id="season-label">Прайс</InputLabel>
                                    <Select
                                        size="small"
                                        label="Сезон"
                                        labelId="season-label"
                                        value={ selectedPriceSeason }
                                        onChange={ (e) => {
                                            const selectedItem = priceSeasons.find((item) => item.id === e.target.value);

                                            if (selectedItem) {
                                                setSelectedPriceSeason(selectedItem.id);
                                                setActivePriceSeason({ id: selectedItem.id, title: selectedItem.title });
                                            }
                                        } }
                                    >
                                        { priceSeasonOptions.map((item) => (
                                            <MenuItem
                                                key={ item.value }
                                                value={ item.value }
                                            >
                                                { item.label }
                                            </MenuItem>
                                        )) }
                                    </Select>
                                </FormControl>
                            )
                        }
                    </ControlPanel>
                    {
                        priceTypes.map((item) => {
                            priceSalesRounds.forEach((priceSalesRound, index) => {
                                if (!headerData.find((findItem) => findItem.field.includes(priceSalesRound.id))) {
                                    setHeaderData(headerData, priceSalesRound, index);
                                }
                            });

                            return (
                                <StyledPricePageTabPanel value={ item.id } key={ item.id }>
                                    <DataGrid
                                        rows={ priceMemberCardsList }
                                        rowCount={ count }
                                        columns={ preparePriceMemberCardHeaderData(headerData, memberCardTypeFilterOptions) }
                                        columnGroupingModel={ columnGroupingModel }
                                        loading={ loading }
                                        pageSize={ pageSize }
                                        page={ page }
                                        setPageSize={ setPageSize }
                                        setPage={ setPage }
                                        setSortModel={ setSortModel }
                                        setFilterModel={ setFilterModel }
                                        sortModel={ sortModel }
                                        filterModel={ filterModel }
                                        initialState={ PRICE_MEMBER_CARD_DATA_GRID_INITIAL_STATE }
                                        columnVisibilityModel={ columnVisibilityModel }
                                        onColumnVisibilityModelChange={ (model) => setColumnVisibilityModel(model) }
                                    />
                                </StyledPricePageTabPanel>
                            );
                        })
                    }
                </StyledPricePageWrap>
            </BaseTabContext>
        </Wrapper>
    );
});

export default memo(TabContext);
