import React, {
    FC, useEffect, useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Skeleton,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import AdornmentColor from 'shared/component/Adorment';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useFetchServiceCategory from 'shared/hook/serviceCategory/fetch/fetchOne/useFetchServiceCategory';
import useServiceCategoryUpdate from 'shared/hook/serviceCategory/update/useServiceCategoryUpdate';
import { useStore } from 'store';

import {
    getServiceCategoryValidationSchema,
    SERVICE_CATEGORY_DEFAULT_VALUES, SERVICE_CATEGORY_FIELDS_KEYS,
    ServiceCategoryFormFields,
} from './formData';

type PropsT = {
    id: string;
};

const ServiceCategoryUpdateModal: FC<PropsT> = observer((props) => {
    const { id } = props;
    const {
        updatedItemId, onClose, serviceCategory,
    } = useStore('updateServiceCategoryModal');

    const { loading: loadingFetch } = useFetchServiceCategory(id);
    const { updateServiceCategory, loading: loadingUpdate } = useServiceCategoryUpdate();

    const {
        control, handleSubmit, reset, setValue, formState: { isDirty, isValid },
    } = useForm<ServiceCategoryFormFields>({
        defaultValues: SERVICE_CATEGORY_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getServiceCategoryValidationSchema()),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateServiceCategory = (data: ServiceCategoryFormFields) => {
        updateServiceCategory(data, id).then(() => {
            handleCloseModal();
        });
    };

    const serviceCategoryInfo: ServiceCategoryFormFields = useMemo(() => {
        const info = SERVICE_CATEGORY_DEFAULT_VALUES;

        if (serviceCategory) {
            info['name' as keyof ServiceCategoryFormFields] = serviceCategory['name' as keyof ServiceCategoryFormFields] as never;
            info['color' as keyof ServiceCategoryFormFields] = serviceCategory['color' as keyof ServiceCategoryFormFields] as never;
            info['minDuration' as keyof ServiceCategoryFormFields] = serviceCategory['minDuration' as keyof ServiceCategoryFormFields] as never;
            info['minAdditionalDuration' as keyof ServiceCategoryFormFields] = serviceCategory['minAdditionalDuration' as keyof ServiceCategoryFormFields] as never;
            info['position' as keyof ServiceCategoryFormFields] = serviceCategory['position' as keyof ServiceCategoryFormFields] as never;
        }

        return info;
    }, [serviceCategory]);

    useEffect(() => {
        Object.keys(serviceCategoryInfo).forEach((key) => {
            setValue(key as keyof ServiceCategoryFormFields, serviceCategoryInfo[key as keyof ServiceCategoryFormFields]);
        });
    }, [serviceCategoryInfo, serviceCategory]);

    return (
        <Modal className="fullHeightModal modalWithTabs" open={ updatedItemId === id } fullWidth={ true } maxWidth="lg">
            <ModalTitle onClose={ onClose }>
                { loadingFetch ? (
                    <Skeleton height={ 32 } />
                ) : (
                    <>Редактирование { serviceCategory?.name }</>
                ) }
            </ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch ? <Spinner /> : (
                    <Grid2 spacing={ 2 } container padding={ 2 }>
                        <Grid2 xs={ 12 }>
                            <TextField
                                control={ control }
                                name={ SERVICE_CATEGORY_FIELDS_KEYS.name as keyof ServiceCategoryFormFields }
                                label="Название"
                                type="text"
                            />
                        </Grid2>
                        <Grid2 xs={ 3 }>
                            <TextField
                                control={ control }
                                name={ SERVICE_CATEGORY_FIELDS_KEYS.color as keyof ServiceCategoryFormFields }
                                key={ `key-serviceCategory-${serviceCategory?.id}` }
                                label="Цвет"
                                type="text"
                                inputAdornment={ <AdornmentColor color={ serviceCategory?.color } /> }
                            />
                        </Grid2>
                        <Grid2 xs={ 3 }>
                            <TextField
                                control={ control }
                                name={ SERVICE_CATEGORY_FIELDS_KEYS.minDuration as keyof ServiceCategoryFormFields }
                                key={ `key-serviceCategory-${serviceCategory?.minDuration}` }
                                label="Мин. длительность"
                                type="number"
                            />
                        </Grid2>
                        <Grid2 xs={ 3 }>
                            <TextField
                                control={ control }
                                name={ SERVICE_CATEGORY_FIELDS_KEYS.minAdditionalDuration as keyof ServiceCategoryFormFields }
                                key={ `key-serviceCategory-${serviceCategory?.minAdditionalDuration}` }
                                label="Мин. доп. длительность"
                                type="number"
                            />
                        </Grid2>
                        <Grid2 xs={ 3 }>
                            <TextField
                                control={ control }
                                name={ SERVICE_CATEGORY_FIELDS_KEYS.position as keyof ServiceCategoryFormFields }
                                key={ `key-serviceCategory-${serviceCategory?.position}` }
                                label="Позиция"
                                type="number"
                            />
                        </Grid2>
                    </Grid2>
                ) }
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    onClick={ handleSubmit(handleUpdateServiceCategory) }
                    loading={ loadingUpdate }
                    disabled={ !isDirty || !isValid }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default ServiceCategoryUpdateModal;
