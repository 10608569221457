import { gql } from '@apollo/client';

import { PriceSalesRoundCreateFormFieldsPayloadT, PriceSalesRoundCreatePayloadT } from './PriceSalesRoundCreate';

export type PriceSalesRoundUpdateFormFields = Omit<PriceSalesRoundCreateFormFieldsPayloadT, 'priceTypeId'>;

export const PriceSalesRoundDbColumns = {
    title: 'title',
    position: 'position',
    endDay: 'end_day',
    endHour: 'end_hour',
    endMonth: 'end_month',
    endWeek: 'end_week',
    startDay: 'start_day',
    startHour: 'start_hour',
    startMonth: 'start_month',
    startWeek: 'start_week',
    endDayFromStart: 'end_day_from_start',
    endHourFromStart: 'end_hour_from_start',
    endMonthFromStart: 'end_month_from_start',
    endWeekFromStart: 'end_week_from_start',
    startDayFromStart: 'start_day_from_start',
    startHourFromStart: 'start_hour_from_start',
    startMonthFromStart: 'start_month_from_start',
    startWeekFromStart: 'start_week_from_start',
    saleRateTitle: 'sale_rate_title',
    saleRateShortTitle: 'sale_rate_short_title',
    returnRateTitle: 'return_rate_title',
    returnRateShortTitle: 'return_rate_short_title',
    clubReturnRateTitle: 'club_return_rate_title',
    clubReturnRateShortTitle: 'club_return_rate_short_title',
    minRateTitle: 'min_rate_title',
    minRateShortTitle: 'min_rate_short_title',
    transferTitle: 'transfer_title',
    transferShortTitle: 'transfer_short_title',
    transferReturnRateTitle: 'transfer_return_rate_title',
    transferSaleRateTitle: 'transfer_sale_rate_title',
};

export const PriceSaleRoundRolePermissionDbColumns = {
    id: 'id',
    entryIndividualCreate: 'entry_individual_create',
    entryIndividualCreateOnlyRegularSchedule: 'entry_individual_create_only_regular_schedule',
    entryIndividualAddCoach: 'entry_individual_add_coach',
    entryIndividualCreateAllowedCourtIds: 'entry_individual_create_allowed_court_ids',
    entryIndividualCancel: 'entry_individual_cancel',
    entryIndividualTransfer: 'entry_individual_transfer',
    entryGroupAdd: 'entry_group_add',
    entryGroupCancel: 'entry_group_cancel',
    entryGroupCreate: 'entry_group_create',
    entryGroupUpdate: 'entry_group_update',
    entryGroupCancelClient: 'entry_group_cancel_client',
    role: 'role',
    entryIndividualCreateLowerThanMinDuration: 'entry_individual_create_lower_than_min_duration',
};

export type PriceSaleRoundRolePermissionUpdatePayloadT = {
    entry_individual_create: boolean;
    entry_individual_create_only_regular_schedule: boolean;
    entry_individual_add_coach: boolean;
    entry_individual_create_allowed_court_ids: string[];
    entry_individual_cancel: boolean;
    entry_individual_transfer: boolean;
    entry_group_add: boolean;
    entry_group_cancel: boolean;
    entry_group_create: boolean;
    entry_group_update: boolean;
    entry_group_cancel_client: boolean;
    role: string;
    entry_individual_create_lower_than_min_duration: boolean | null;
};

export type PriceSalesRoundUpdateVar = {
    priceSalesRoundPayload: PriceSalesRoundCreatePayloadT[];
    onConflict: {
        constraint: string;
        update_columns: string[];
    };
};

export type PriceSalesRoundUpdateResponseDataT = {
    updatePriceSalesRound: {
        id: string;
    };
} | null | undefined;

export const PRICE_SALES_ROUND_UPDATE = gql`
    mutation updatePriceSalesRound(
        $priceSalesRoundPayload: [price_sales_round_insert_input] = {}
        $onConflict: price_sales_round_on_conflict = {}
    ) {
        updatePriceSalesRound: insert_price_sales_round(
            objects: $priceSalesRoundPayload,
            on_conflict: $onConflict
        ) {
            affected_rows
        }
    }
`;
