import { PROPERTY_COMMENT, PROPERTY_IS_ARCHIVE, PROPERTY_PAYMENTS } from 'constants/propertyNames';

import { PaymentT } from 'types/Payment';

export type PaymentDocumentFormFields = {
    isArchive: boolean;
    number: string;
    datetime: Date;
    organization: {
        id: string;
        name: string;
    };
    comment: string | null;
    payments: PaymentT[];
};

export const PAYMENT_DOCUMENT_DEFAULT_VALUES: PaymentDocumentFormFields = {
    isArchive: false,
    number: '',
    datetime: new Date(),
    organization: {
        id: '',
        name: '',
    },
    comment: '',
    payments: [],
};

export const PAYMENT_DOCUMENT_FIELD_KEYS = {
    isArchive: 'isArchive',
    comment: 'comment',
    payments: 'payments',
};

export const PAYMENT_DOCUMENT_LABEL_DATA = {
    isArchive: PROPERTY_IS_ARCHIVE,
    comment: PROPERTY_COMMENT,
    payments: PROPERTY_PAYMENTS,
};
