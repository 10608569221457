import {
    PROPERTY_ACTIONS, PROPERTY_AGE,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import useDeleteAge from 'shared/hook/age/delete/useDeleteAge';
import { CustomHeaderData } from 'types/DataGrid';

import AgeEditButton from '../components/AgeEditButton';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const {
        row: { id, title },
    } = props;
    const { ageDelete } = useDeleteAge();

    return (
        <>
            <AgeEditButton id={ id } />
            <DeleteButton id={ id } title={ title } actionDelete={ ageDelete } />
        </>
    );
};

export const AGE_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_AGE,
        field: 'value',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
