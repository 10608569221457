import { useMutation } from '@apollo/client';
import { AcquiringSettingFormFields } from 'page/InternetAcquiring/components/formData';
import {
    ACQUIRING_SETTING_UPDATE,
    AcquiringSettingUpdateResponseDataT,
    AcquiringSettingUpdateVar,
} from 'shared/graphql/mutation/acquiringSetting/AcquiringSettingUpdate';
import { AcquiringSettingT } from 'types/AcquiringSetting';

import { AcquiringSettingUpdateDataT } from './AcquiringSettingUpdateData';

const useAcquiringSettingUpdate = (): AcquiringSettingUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        AcquiringSettingUpdateResponseDataT,
        AcquiringSettingUpdateVar
    >(ACQUIRING_SETTING_UPDATE);

    const updateAction = async (
        input: AcquiringSettingFormFields,
        acquiringSettingId: AcquiringSettingT['id'],
    ) => {
        if (!acquiringSettingId) {
            // TODO: use Sentry
            throw new Error('AcquiringSetting is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: acquiringSettingId,
                    set: {
                        type: input.type,
                        api_login: input.apiLogin,
                        api_password: input.apiPassword,
                        api_token: input.apiToken,
                        api_url: input.apiUrl,
                        payment_method_id: input.paymentMethod ? input.paymentMethod : null,
                        payment_type_id: input.paymentType ? input.paymentType : null,
                        service_app_id: input.serviceApp,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        acquiringSettingUpdate: updateAction,
        loading,
        error,
    };
};

export default useAcquiringSettingUpdate;
