import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { AGE_QUERY, AgeResponseT } from 'shared/graphql/query/age/ageQuery';
import { AgeT, EMPTY_AGE_RESULT } from 'types/Age';

import { AgeDTO } from '../DTO/AgeDTO';

import { AgeDataT } from './AgeData';

const useAge = (): AgeDataT => {
    const [fetch, { loading, error }] = useLazyQuery<AgeResponseT>(
        AGE_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchAge = async (id: string): Promise<AgeT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                AgeDTO,
                EMPTY_AGE_RESULT,
                (age) => age,
                data?.age,
            );
        } catch {
            return EMPTY_AGE_RESULT;
        }
    };

    return {
        fetchAge,
        loading,
        error,
    };
};

export default useAge;
