import { OPERATIONS_OPTIONS } from 'constants/operations';
import {
    PROPERTY_CANCEL_ID,
    PROPERTY_CASHBACK_BONUS_AMOUNT,
    PROPERTY_CASHBACK_BONUS_PAYMENT_DOCUMENT_ID,
    PROPERTY_CASHBACK_FIAT_AMOUNT,
    PROPERTY_CASHBACK_FIAT_PAYMENT_DOCUMENT_ID,
    PROPERTY_CATEGORY, PROPERTY_CLIENT,
    PROPERTY_COACH,
    PROPERTY_COMMENT,
    PROPERTY_COURT,
    PROPERTY_DATETIME,
    PROPERTY_DATETIME_OPERATION,
    PROPERTY_DISCOUNT_AMOUNT,
    PROPERTY_DISCOUNT_PERCENTAGE,
    PROPERTY_DOCUMENT,
    PROPERTY_DURATION, PROPERTY_FORM_RATE,
    PROPERTY_GROUP,
    PROPERTY_GUESTS_AMOUNT,
    PROPERTY_IMPLEMENTATION_DOCUMENT_ID,
    PROPERTY_IS_MADE_BY_CLUB,
    PROPERTY_OPERATION, PROPERTY_PAYER,
    PROPERTY_PAYMENT_IN_BONUS,
    PROPERTY_PAYMENT_IN_FIAT,
    PROPERTY_PERFORMER,
    PROPERTY_PERFORMER_INCOME_IN_BONUS,
    PROPERTY_PERFORMER_INCOME_IN_FIAT,
    PROPERTY_PLACE,
    PROPERTY_PRICE_TYPE,
    PROPERTY_RATE_CONDITION,
    PROPERTY_SALES_ROUND,
    PROPERTY_SERVICE,
    PROPERTY_TOTAL_PAYMENT,
    PROPERTY_TRANSFER_ID,
    PROPERTY_USER_ID,
} from 'constants/propertyNames';
import { SERVICES_OPTIONS } from 'constants/services';

import React, { FC, useEffect } from 'react';
import { Control, useController, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import { parseClientOptions } from 'page/MemberCard/domain/parseClientOptions';
import { useStore } from 'store';
import { ImplementationT } from 'types/Implementation';

import { ImplementationFormFields } from './formData';
import {
    Checkbox, DatePicker, DateTimePicker, Select, SelectWithSearch, TextField,
} from './inputs';

type PropsT = {
    control: Control<ImplementationFormFields>;
    setValue: UseFormSetValue<ImplementationFormFields>;
    implementation: ImplementationT;
    index: number;
};

const ImplementationContent: FC<PropsT> = observer((props) => {
    const {
        control, implementation, setValue, index,
    } = props;
    const {
        clients,
    } = useStore('implementation');

    const { getFilterOption } = useStore('filterOptions');
    const serviceCategoryOptions = getFilterOption('serviceCategory');
    const priceTypeOptions = getFilterOption('priceType');
    const priceSalesRoundOptions = getFilterOption('priceSalesRound');
    const coachOptions = getFilterOption('employee');
    const courtOptions = getFilterOption('court');
    const groupOptions = getFilterOption('group');
    const clientsOptions = parseClientOptions(clients);
    const defaultPayer = implementation.clientId;
    const { field: client } = useController({ control, name: `implementations.${index}.clientId` });
    const { field: payer } = useController({ control, name: `implementations.${index}.payerId` });

    useEffect(() => {
        if (defaultPayer && !implementation.payerId) {
            setValue(`implementations.${index}.payerId`, defaultPayer);
        }
    }, [defaultPayer, index]);

    return (
        <Grid2 container direction="row" spacing={ 1 }>
            <Grid2 xs={ 6 }>
                <SelectWithSearch
                    label={ PROPERTY_CLIENT }
                    name={ `implementations.${index}.clientId` }
                    options={ clientsOptions }
                    control={ control }
                    value={ client.value }
                />
                <SelectWithSearch
                    label={ PROPERTY_PAYER }
                    name={ `implementations.${index}.payerId` }
                    options={ clientsOptions }
                    control={ control }
                    value={ payer.value }
                />
                <Select
                    label={ PROPERTY_OPERATION }
                    name={ `implementations.${index}.operation` }
                    options={ OPERATIONS_OPTIONS }
                    control={ control }
                />
                <Select
                    label={ PROPERTY_SERVICE }
                    name={ `implementations.${index}.service` }
                    options={ SERVICES_OPTIONS }
                    control={ control }
                    empty={ false }
                />
                <Select
                    label={ PROPERTY_PRICE_TYPE }
                    name={ `implementations.${index}.priceTypeId` }
                    options={ priceTypeOptions }
                    control={ control }
                />
                <DateTimePicker
                    name={ `implementations.${index}.datetime` }
                    label={ PROPERTY_DATETIME }
                    control={ control }
                    setValue={ setValue }
                    date={ implementation.datetime }
                />
                <TextField
                    name={ `implementations.${index}.duration` }
                    label={ PROPERTY_DURATION }
                    control={ control }
                    type="number"
                />
                <TextField
                    name={ `implementations.${index}.guestsAmount` }
                    label={ PROPERTY_GUESTS_AMOUNT }
                    control={ control }
                    type="number"
                />
                <Select
                    label={ PROPERTY_COURT }
                    name={ `implementations.${index}.courtId` }
                    options={ courtOptions }
                    control={ control }
                />
                <TextField
                    name={ `implementations.${index}.place` }
                    label={ PROPERTY_PLACE }
                    control={ control }
                />
                <TextField
                    name={ `implementations.${index}.performer` }
                    label={ PROPERTY_PERFORMER }
                    control={ control }
                />
                <Select
                    label={ PROPERTY_SALES_ROUND }
                    name={ `implementations.${index}.priceSalesRoundId` }
                    options={ priceSalesRoundOptions }
                    control={ control }
                />
                <TextField
                    name={ `implementations.${index}.title` }
                    label={ PROPERTY_RATE_CONDITION }
                    control={ control }
                    multiline={ true }
                />
                <TextField
                    name={ `implementations.${index}.rate` }
                    label={ PROPERTY_FORM_RATE }
                    control={ control }
                    type="number"
                />
                <TextField
                    name={ `implementations.${index}.discount` }
                    label={ PROPERTY_DISCOUNT_AMOUNT }
                    control={ control }
                    type="number"
                />
                <TextField
                    name={ `implementations.${index}.discountPercent` }
                    label={ PROPERTY_DISCOUNT_PERCENTAGE }
                    control={ control }
                    type="number"
                />
                <TextField
                    name={ `implementations.${index}.total` }
                    label={ PROPERTY_TOTAL_PAYMENT }
                    control={ control }
                    type="number"
                />
                <TextField
                    name={ `implementations.${index}.paymentInFiat` }
                    label={ PROPERTY_PAYMENT_IN_FIAT }
                    control={ control }
                    type="number"
                />
                <TextField
                    name={ `implementations.${index}.paymentInBonus` }
                    label={ PROPERTY_PAYMENT_IN_BONUS }
                    control={ control }
                    type="number"
                />
            </Grid2>
            <Grid2 xs={ 6 }>
                <TextField
                    name={ `implementations.${index}.document` }
                    label={ PROPERTY_DOCUMENT }
                    control={ control }
                />
                <TextField
                    name={ `implementations.${index}.documentId` }
                    label={ PROPERTY_IMPLEMENTATION_DOCUMENT_ID }
                    control={ control }
                />
                <Select
                    label={ PROPERTY_COACH }
                    name={ `implementations.${index}.coachId` }
                    options={ coachOptions }
                    control={ control }
                />
                <Select
                    label={ PROPERTY_GROUP }
                    name={ `implementations.${index}.groupId` }
                    options={ groupOptions }
                    control={ control }
                />
                <TextField
                    name={ `implementations.${index}.cancelId` }
                    label={ PROPERTY_CANCEL_ID }
                    control={ control }
                />
                <TextField
                    name={ `implementations.${index}.transferId` }
                    label={ PROPERTY_TRANSFER_ID }
                    control={ control }
                />
                <Select
                    label={ PROPERTY_CATEGORY }
                    name={ `implementations.${index}.serviceCategoryId` }
                    options={ serviceCategoryOptions }
                    control={ control }
                />
                <DatePicker
                    name={ `implementations.${index}.datetimeOperation` }
                    label={ PROPERTY_DATETIME_OPERATION }
                    control={ control }
                    setValue={ setValue }
                    date={ implementation.datetimeOperation }
                />
                <TextField
                    name={ `implementations.${index}.userId` }
                    label={ PROPERTY_USER_ID }
                    control={ control }
                />
                <TextField
                    name={ `implementations.${index}.cashbackBonusAmount` }
                    label={ PROPERTY_CASHBACK_BONUS_AMOUNT }
                    control={ control }
                    type="number"
                />
                <TextField
                    name={ `implementations.${index}.cashbackFiatAmount` }
                    label={ PROPERTY_CASHBACK_FIAT_AMOUNT }
                    control={ control }
                    type="number"
                />
                <TextField
                    name={ `implementations.${index}.cashbackFiatPaymentDocumentId` }
                    label={ PROPERTY_CASHBACK_FIAT_PAYMENT_DOCUMENT_ID }
                    control={ control }
                />
                <TextField
                    name={ `implementations.${index}.cashbackBonusPaymentDocumentId` }
                    label={ PROPERTY_CASHBACK_BONUS_PAYMENT_DOCUMENT_ID }
                    control={ control }
                />
                <TextField
                    name={ `implementations.${index}.performerIncomeFiat` }
                    label={ PROPERTY_PERFORMER_INCOME_IN_FIAT }
                    control={ control }
                    type="number"
                />
                <TextField
                    name={ `implementations.${index}.performerIncomeBonus` }
                    label={ PROPERTY_PERFORMER_INCOME_IN_BONUS }
                    control={ control }
                    type="number"
                />
                <TextField
                    name={ `implementations.${index}.comment` }
                    label={ PROPERTY_COMMENT }
                    control={ control }
                />
                <Checkbox
                    name={ `implementations.${index}.isMadeByClub` }
                    label={ PROPERTY_IS_MADE_BY_CLUB }
                    control={ control }
                />
            </Grid2>
        </Grid2>
    );
});

export default ImplementationContent;
