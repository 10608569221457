export const base64Encode = (value: string) =>
    btoa(
        encodeURIComponent(value).replace(
            /%([0-9A-F]{2})/g,
            (match, p1) => String.fromCharCode(parseInt(p1, 16)),
        ),
    );

export const base64Decode = (value: string) =>
    decodeURIComponent(Array.prototype.map.call(atob(value), (c) => {
        return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
    }).join(''));

export const base64FileEncode = async (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
        if (reader.result) {
            resolve(reader.result.toString());
        } else {
            reject(new Error('Ошибка чтения файла'));
        }
    };

    reader.onerror = () => {
        reject(new Error('Ошибка конвертации'));
    };

    reader.readAsDataURL(file);
});
