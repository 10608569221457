import { useMutation } from '@apollo/client';
import { ServiceAppFormFields } from 'page/ServiceApp/formData';
import {
    SERVICE_APP_UPDATE,
    ServiceAppUpdateResponseDataT,
    ServiceAppUpdateVar,
} from 'shared/graphql/mutation/serviceApp/serviceAppUpdate';
import { ServiceAppT } from 'types/serviceApp/ServiceApp';

import { ServiceAppUpdateDataT } from './ServiceAppUpdateData';

const useServiceAppUpdate = (): ServiceAppUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        ServiceAppUpdateResponseDataT,
        ServiceAppUpdateVar
    >(SERVICE_APP_UPDATE);

    const updateAction = async (
        input: ServiceAppFormFields,
        serviceAppId: ServiceAppT['id'],
    ) => {
        if (!serviceAppId) {
            // TODO: use Sentry
            throw new Error('ServiceApp is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: serviceAppId,
                    set: {
                        label: input.label,
                        token: input.token,
                        description: input.description,
                        url: input.url,
                        hash: input.hash,
                        timezone: input.timezone,
                        fiat_currency_title: input.fiatCurrencyTitle,
                        video_analytics_telegram_bot_chat_ids: input.videoAnalyticsTelegramBotChatIds.map((elem) => elem.id),
                        entry_notification_telegram_bot_chat_ids: input.entryNotificationTelegramBotChatIds.map((elem) => elem.id),
                        acquiring_notification_telegram_bot_chat_ids: input.acquiringNotificationTelegramBotChatIds.map((elem) => elem.id),
                        domain_name: input.domainName,
                        sms_sender_name: input.smsSenderName,
                        sms_sending_enabled: input.smsSendingEnabled,
                        locale: input.locale,
                        default_credit_for_new_clients: input.defaultCreditForNewClients,
                        default_organization_location_id: input.defaultOrganizationLocationId || null,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO; use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        serviceAppUpdate: updateAction,
        loading,
        error,
    };
};

export default useServiceAppUpdate;
