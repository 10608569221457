import {
    PROPERTY_POSITION, PROPERTY_TASK_CATEGORY, PROPERTY_TITLE,
} from 'constants/propertyNames';

import { number, object, string } from 'yup';

export type TaskStatusFormFields = {
    category: string;
    name: string;
    position: number;
};

export const TASK_STATUS_DEFAULT_VALUES: TaskStatusFormFields = {
    category: '',
    name: '',
    position: 0,
};

export const TASK_STATUS_LABEL_DATA = {
    category: PROPERTY_TASK_CATEGORY,
    name: PROPERTY_TITLE,
    position: PROPERTY_POSITION,
};

export enum TASK_STATUS_FIELD_KEYS {
    category = 'category',
    name = 'name',
    position = 'position',
}

export const getTaskStatusValidationSchema = () =>
    object()
        .shape({
            category: string().required(),
            name: string().required(),
            position: number().required(),
        })
        .required();
