import { TIME_24_HOURS_30_STEP } from 'constants/hours';
import { MODAL_TITLE_CREATE_COURT } from 'constants/modalTitles';
import { MONTHS_LIST } from 'constants/MonthsList';
import { PROPERTY_END, PROPERTY_START } from 'constants/propertyNames';
import { WEEK_DAYS_VALUE_OPTIONS, WEEK_DAYS_VALUE_SHORT_OPTIONS } from 'constants/weekdays';

import React, { FC, useEffect } from 'react';
import { useController, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import MultipleSelect from 'shared/component/Form/MultipleSelect';
import Select from 'shared/component/Form/Select';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { ORGANIZATION_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/organization/OrganizationQuery';
import { ORGANIZATION_LOCATION_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/organizationLocation/OrganizationLocationQuery';
import useCreateCourt from 'shared/hook/court/create/useCreateCourt';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import { useStore } from 'store';

import {
    COURT_DEFAULT_VALUES, COURT_FIELD_KEYS, COURT_LABEL_DATA, COURT_VALIDATION_SCHEMA, CourtFormFields,
} from './formData';

const CreateCourtModal: FC = observer(() => {
    const { isOpen, onClose, itemId } = useStore('createCourtModal');
    const { getFilterOption } = useStore('filterOptions');
    const { createCourt, loading: loadingCreate } = useCreateCourt();

    const organizationOptions = getFilterOption('organization');
    const organizationLocationOptions = getFilterOption('organizationLocation');

    const { loading: loadingOrganizations } = useFetchFilterOptions(ORGANIZATION_FILTER_OPTIONS_QUERY, 'organization');
    const { loading: loadingOrganizationLocations } = useFetchFilterOptions(ORGANIZATION_LOCATION_FILTER_OPTIONS_QUERY, 'organizationLocation');

    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<CourtFormFields>({
        defaultValues: COURT_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(COURT_VALIDATION_SCHEMA),
    });

    const { fields } = useFieldArray<CourtFormFields>({ name: 'schedule', control });
    const { field: enabledAtMonthsField } = useController({ control, name: COURT_FIELD_KEYS.enabledAtMonths });

    const handleClose = () => {
        onClose();
        reset();
    };

    const handleCreateCourt = (data: CourtFormFields) => {
        createCourt(data).then(() => handleClose());
    };

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        reset({
            ...COURT_DEFAULT_VALUES,
            courtType: itemId || COURT_DEFAULT_VALUES.courtType,
            schedule: WEEK_DAYS_VALUE_OPTIONS.map((weekDay) => ({
                day: weekDay.value,
                start: '',
                end: '',
            })),
        });
    }, [itemId, isOpen]);

    return (
        <Modal maxWidth="lg" open={ isOpen }>
            <ModalTitle onClose={ handleClose }>{ MODAL_TITLE_CREATE_COURT }</ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 container spacing={ 2 } padding={ 2 } alignItems="center" justifyContent="center">
                    <Grid2 xs={ 12 }>
                        <TextField
                            control={ control }
                            name={ COURT_FIELD_KEYS.name }
                            label={ COURT_LABEL_DATA.name }
                            type="text"
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <TextField
                            control={ control }
                            name={ COURT_FIELD_KEYS.order }
                            label={ COURT_LABEL_DATA.order }
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <TextField
                            control={ control }
                            name={ COURT_FIELD_KEYS.cameraId }
                            label={ COURT_LABEL_DATA.cameraId }
                            type="text"
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <Select
                            options={ organizationOptions }
                            loading={ loadingOrganizations }
                            control={ control }
                            name={ COURT_FIELD_KEYS.organization }
                            label={ COURT_LABEL_DATA.organization }
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <Select
                            options={ organizationLocationOptions }
                            loading={ loadingOrganizationLocations }
                            control={ control }
                            name={ COURT_FIELD_KEYS.organizationLocation }
                            label={ COURT_LABEL_DATA.organizationLocation }
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <MultipleSelect
                            size="small"
                            label={ COURT_LABEL_DATA.enabledAtMonths }
                            name={ COURT_FIELD_KEYS.enabledAtMonths }
                            control={ control }
                            options={ MONTHS_LIST }
                            selectedValues={ enabledAtMonthsField.value }
                        />
                    </Grid2>
                    { fields.map((field, index) => (
                        <Grid2 container key={ field.id } alignItems="flex-start" xs={ 12 }>
                            <Grid2 pt={ 4.5 }>
                                <Typography fontSize={ 18 } textAlign="center">
                                    { WEEK_DAYS_VALUE_SHORT_OPTIONS[index].label }
                                </Typography>
                            </Grid2>
                            <Grid2 flex={ 1 }>
                                <Select
                                    label={ PROPERTY_START }
                                    options={ TIME_24_HOURS_30_STEP }
                                    control={ control }
                                    name={ `schedule.${index}.start` }
                                    customError
                                />
                            </Grid2>
                            <Grid2 flex={ 1 }>
                                <Select
                                    label={ PROPERTY_END }
                                    options={ TIME_24_HOURS_30_STEP }
                                    control={ control }
                                    name={ `schedule.${index}.end` }
                                    customError
                                />
                            </Grid2>
                        </Grid2>
                    )) }
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ loadingCreate }
                    disabled={ !isValid || !isDirty }
                    onClick={ handleSubmit(handleCreateCourt) }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateCourtModal;
