import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { AcquiringSettingsT } from 'types/AcquiringSetting';

import { AcquiringSettingDTO } from './AcquiringSettingDTO';

const DTO = t.type({
    acquiringSettings: t.array(AcquiringSettingDTO),
    acquiringSettingsCount: AggregateDTO,
});

type AcquiringSettingDTOT = t.TypeOf<typeof DTO>;

export const AcquiringSettingsDTO = tPromise.extendDecoder<AcquiringSettingDTOT, AcquiringSettingsT>(
    DTO,
    ({
        acquiringSettings,
        acquiringSettingsCount,
    }) => ({
        acquiringSettings,
        acquiringSettingsCount,
    }),
) as t.Type<AcquiringSettingsT, AcquiringSettingDTOT>;
