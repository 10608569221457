import { gql } from '@apollo/client';

export type ImportPriceResponseDataT = {
    adminImportPrices: {
        status: string;
        reason: string | null;
    };
} | null | undefined;

export type ImportPriceVarT = {
    appId: string;
    data: string;
};

export const IMPORT_PRICE_MUTATION = gql`
    mutation ImportPriceMutation($appId: String!, $data: String!) {
        adminImportPrices(
            app_id: $appId
            file: $data
        ) {
            status
            reason
        }
    }
`;
