import {
    PROPERTY_COMMENT,
    PROPERTY_DESCRIPTION, PROPERTY_DURATION,
    PROPERTY_IS_ACTIVE,
    PROPERTY_POSITION,
    PROPERTY_TITLE, PROPERTY_TITLE_BY_DEFAULT,
} from 'constants/propertyNames';

import {
    boolean, number, object, string,
} from 'yup';

export type TaskTypeFormFields = {
    name: string;
    position: number;
    isActive: boolean;
    defaultShouldCreateFinishComment: boolean;
    defaultDurationHours?: number;
    defaultTitle?: string;
    defaultDescription?: string;
};

export const TASK_TYPE_DEFAULT_VALUES: TaskTypeFormFields = {
    name: '',
    position: 0,
    isActive: false,
    defaultShouldCreateFinishComment: false,
    defaultDurationHours: 0,
    defaultTitle: '',
    defaultDescription: '',
};

export const TASK_TYPE_LABEL_DATA = {
    name: PROPERTY_TITLE,
    position: PROPERTY_POSITION,
    isActive: PROPERTY_IS_ACTIVE,
    defaultShouldCreateFinishComment: PROPERTY_COMMENT,
    defaultDurationHours: PROPERTY_DURATION,
    defaultTitle: PROPERTY_TITLE_BY_DEFAULT,
    defaultDescription: PROPERTY_DESCRIPTION,
};

export enum TASK_TYPE_FIELD_KEYS {
    name = 'name',
    position = 'position',
    isActive = 'isActive',
    defaultShouldCreateFinishComment = 'defaultShouldCreateFinishComment',
    defaultDurationHours = 'defaultDurationHours',
    defaultTitle = 'defaultTitle',
    defaultDescription = 'defaultDescription',
}

export const getTaskTypeValidationSchema = () =>
    object()
        .shape({
            name: string().required(),
            position: number().required(),
            isActive: boolean().required(),
            defaultShouldCreateFinishComment: boolean().required(),
            defaultTitle: string(),
            defaultDescription: string(),
        })
        .required();
