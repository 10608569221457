import { useMutation } from '@apollo/client';
import {
    TASK_STATUS_DELETE,
    TaskStatusDeleteResponseDataT,
    TaskStatusDeleteVarT,
} from 'shared/graphql/mutation/taskStatus/TaskStatusDelete';
import { TaskStatusT } from 'types/TaskStatus';

import { TaskStatusDeleteDataT } from './TaskStatusDeleteData';

const useTaskStatusDelete = (): TaskStatusDeleteDataT => {
    const [taskStatusDelete, { loading, error }] = useMutation<
        TaskStatusDeleteResponseDataT,
        TaskStatusDeleteVarT
    >(TASK_STATUS_DELETE);

    const deleteAction = async (taskStatusId: TaskStatusT['id']) => {
        try {
            const { data } = await taskStatusDelete({
                variables: {
                    id: taskStatusId,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteTaskStatus: deleteAction,
        loading,
        error,
    };
};

export default useTaskStatusDelete;
