import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { PRT_QUERY, PrtResponseT } from 'shared/graphql/query/prt/prtQuery';
import { EMPTY_PRT_RESULT, PrtT } from 'types/Prt';

import { PrtDTO } from '../DTO/PrtDTO';

import { PrtDataT } from './PrtData';

const usePrt = (): PrtDataT => {
    const [fetch, { loading, error }] = useLazyQuery<PrtResponseT>(
        PRT_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchPrt = async (id: string): Promise<PrtT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                PrtDTO,
                EMPTY_PRT_RESULT,
                (prt) => prt,
                data?.prt,
            );
        } catch {
            return EMPTY_PRT_RESULT;
        }
    };

    return {
        fetchPrt,
        loading,
        error,
    };
};

export default usePrt;
