import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { PrtT } from 'types/Prt';

export type PrtsResponseT = {
    prts: PrtT[];
    prtsCount: AggregateT;
};

export type PrtResponseT = {
    prt: PrtT;
};

export const PRT_QUERY = gql`
    query Prt($id: String!) {
        prt: prt_by_pk(id: $id) {
            id
            name
            description
            order
        }
    }
`;

export const PRTS_QUERY = gql`
    query Prt(
        $limit: Int
        $offset: Int
        $orderBy: prt_order_by! = {}
        $where: prt_bool_exp! = {}
    ) {
        prts: prt(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            name
            description
            order
        }
        prtsCount: prt_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
