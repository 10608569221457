import { Dispatch, SetStateAction } from 'react';
import { GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';

export type DataGridPropsT = {
    pageSize: number;
    setPageSize: Dispatch<SetStateAction<number>>;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    sortModel: GridSortModel;
    setSortModel: Dispatch<SetStateAction<GridSortModel>>;
    filterModel: GridFilterModel;
    setFilterModel: Dispatch<SetStateAction<GridFilterModel>>;
};

export type SortT = Object;

export type SearchT = {
    name: string;
};

export type WhereT = {
    items: any[];
    linkOperator: string;
};

export interface CustomHeaderData extends GridColDef {
    hasuraFilterFields?: string[];
    hasuraSortFields?: string[];
}

export type FetchDataParams<SearchType = undefined> = {
    limit?: number;
    offset?: number;
    orderBy?: SortT;
    where?: WhereT;
    search?: SearchType;
};

export type FilterOptionT = {
    value: string;
    label: string;
};

export type FilterOptionsResponseT = {
    filterOptions: FilterOptionT[];
};

export const EMPTY_FILTER_OPTIONS = [];

export type DisplayColumnsModalSupportedTable = 'price' | 'price-member-cards';
