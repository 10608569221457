import { gql } from '@apollo/client';
import { ImplementationT } from 'types/Implementation';

export type ImplementationsByDocumentResponseT = {
    implementation_document: ImplementationT[];
};
export const IMPLEMENTATIONS_BY_DOCUMENT_QUERY = gql`
    query ImplementationsByDocument($id: String!) {
        implementation_document(where: { id: { _eq: $id } }) {
            implementations {
                id
                provider_incomes {
                    id
                    coach_id
                    operation
                    organization_id
                    place
                    price_type_id
                    service
                    income_in_bonus
                    income_in_fiat
                    covered_at
                    datetime
                    datetime_operation
                    payed_at
                }
                client_datum {
                    client {
                        first_name
                        last_name
                        middle_name
                    }
                }
                client_id
                payer_id
                cancel_id
                is_made_by_club
                cashback_bonus_amount
                cashback_bonus_payment_document_id
                cashback_fiat_payment_document_id
                coach_id
                court_id
                group_id
                operation
                performer
                place
                price_sales_round_id
                price_type_id
                service
                service_category_id
                transfer_id
                user_id
                discount_percent
                duration
                guests_amount
                cashback_fiat_amount
                discount
                payment_in_bonus
                payment_in_fiat
                performer_income_bonus
                performer_income_fiat
                rate
                total
                comment
                document
                document_id
                clientDatumByPayerId {
                    client {
                        first_name
                        last_name
                        middle_name
                    }
                }
                title
                datetime
                datetime_operation
                service_category {
                    name
                }
                price_type {
                    title
                }
                user {
                    full_name
                }
            }
        }
    }
`;
