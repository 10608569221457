import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { TASK_TYPES_QUERY, TaskTypesResponseT } from 'shared/graphql/query/taskType/TaskTypeQuery';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_TASK_TYPES_RESULT } from 'types/TaskType';

import { TaskTypesDTO } from '../DTO/TaskTypesDTO';

import { TaskTypesDataT } from './TaskTypesData';

const useTaskTypes = (): TaskTypesDataT => {
    const [fetch, { data, loading, error }] = useLazyQuery<TaskTypesResponseT, QueryVarsT>(
        TASK_TYPES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedTaskTypesData = decodeDTO(
        TaskTypesDTO,
        EMPTY_TASK_TYPES_RESULT,
        (taskTypes) => taskTypes,
        {
            taskTypes: data?.taskTypes,
            taskTypesCount: data?.taskTypesCount,
        },
    );

    const fetchTaskTypes = async ({
        limit,
        offset,
        orderBy = {},
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        data: decodedTaskTypesData.taskTypes,
        count: decodedTaskTypesData.taskTypesCount,
        fetchTaskTypes,
        loading,
        error,
    };
};

export default useTaskTypes;
