import { makeAutoObservable } from 'mobx';
import { ClientT } from 'types/Client';
import { EMPTY_IMPLEMENTATION_RESULT, ImplementationT } from 'types/Implementation';
import { PriceTypeT } from 'types/PriceType';

export default class ImplementationStore {
    public implementationList: ImplementationT[] = [];

    public implementation: ImplementationT = EMPTY_IMPLEMENTATION_RESULT;

    public count: number = 0;

    public priceTypes: PriceTypeT[] = [];

    public clients: ClientT[] = [];

    public setClients(clients: ClientT[]) {
        this.clients = clients;
    }

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get implementations(): ImplementationT[] {
        return this.implementationList;
    }

    public setImplementations(implementations: ImplementationT[], count?: number) {
        this.implementationList = implementations;
        this.count = count ?? 0;
    }

    public setImplementation(implementation: ImplementationT) {
        this.implementation = implementation;
    }
}
