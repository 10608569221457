import { useEffect } from 'react';
import useMemberCardType from 'shared/data-hook/memberCardType/fetch/fetchOne/useMemberCardType';
import { useStore } from 'store';

const useFetchMemberCardType = (id: string) => {
    const { fetchMemberCardType, loading } = useMemberCardType();
    const { setMemberCardType } = useStore('updateMemberCardTypeModal');

    useEffect(() => {
        if (id) {
            fetchMemberCardType(id).then((memberCardType) => {
                setMemberCardType(memberCardType);
            });
        }
    }, [id]);

    return {
        loading,
    };
};

export default useFetchMemberCardType;
