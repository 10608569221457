import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    ACQUIRING_SETTING_QUERY,
    AcquiringSettingResponseT,
} from 'shared/graphql/query/acquiringSetting/AcquiringSettingQuery';
import { AcquiringSettingT, EMPTY_ACQUIRING_SETTING_RESULT } from 'types/AcquiringSetting';

import { AcquiringSettingDTO } from '../DTO/AcquiringSettingDTO';

import { AcquiringSettingDataT } from './AcquiringSettingData';

const useAcquiringSetting = (): AcquiringSettingDataT => {
    const [fetch, { loading, error }] = useLazyQuery<AcquiringSettingResponseT>(
        ACQUIRING_SETTING_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchAcquiringSetting = async (id: string): Promise<AcquiringSettingT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                AcquiringSettingDTO,
                EMPTY_ACQUIRING_SETTING_RESULT,
                (acquiringSetting) => acquiringSetting,
                data?.acquiringSetting,
            );
        } catch {
            return EMPTY_ACQUIRING_SETTING_RESULT;
        }
    };

    return {
        fetchAcquiringSetting,
        loading,
        error,
    };
};

export default useAcquiringSetting;
