import { SyntheticEvent, useEffect, useState } from 'react';
import {
    GridColDef,
    GridColumnGroup,
    GridColumns,
    GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium';
import useDeleteMultiplePrice from 'shared/hook/price/delete/useDeleteMultiplePrice';
import useScheduleSlotRateRecreateForPrice
    from 'shared/hook/scheduleSlotRate/create/useScheduleSlotRateRecreateForPrice';
import { useStore } from 'store';
import { CustomHeaderData, DataGridPropsT } from 'types/DataGrid';
import { PriceTypeT } from 'types/PriceType';

import { GROUP_HEADER_DATA, PRICE_HEADER_DATA } from '../domain/HeaderData';
import { preparePriceHeaderData } from '../domain/preparePriceHeaderData';
import { setGroupHeaderData as updateGroupHeaderData } from '../domain/setGroupHeaderData';
import { setHeaderData as updateHeaderData } from '../domain/setHeaderData';

type PropsT = {
    tab: string;
    handleTabChange: (newTab: string) => void;
    fetchSalesRounds: (priceTypeId?: string) => Promise<void>;
    priceSeasonId: string;
} & DataGridPropsT

type ReturnT = {
    columnVisibilityModel: GridColumnVisibilityModel;
    exportData: () => void;
    priceTypes: PriceTypeT[];
    count: number;
    handleRefresh: (ids: string[]) => void;
    deleteMultiplePrice: (ids: string[]) => Promise<void>;
    openCreatePriceModal: () => void;
    openFilPriceModal: () => void;
    setColumnVisibilityModel: (model: GridColumnVisibilityModel) => void;
    changeTab: (event: SyntheticEvent, value: any) => void;
    groupHeaderData: GridColumnGroup[];
    headerData: CustomHeaderData[];
}

const useTabContext = (props: PropsT): ReturnT => {
    const {
        tab, handleTabChange, fetchSalesRounds, priceSeasonId, sortModel, filterModel, page, pageSize,
    } = props;
    const { priceSalesRounds } = useStore('priceSalesRound');
    const { onOpen: openFilPriceModal } = useStore('updatePriceRateModal');
    const { onOpen: exportData } = useStore('exportPriceDataModal');
    const { onOpen: openCreatePriceModal } = useStore('createPriceModal');
    const {
        activePriceType,
        count,
        priceTypes,
        setActivePriceType,
        columnVisibilityModel,
        setColumnVisibilityModel,
    } = useStore('price');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { deleteMultiplePrice } = useDeleteMultiplePrice();
    const { recreateScheduleSlotRateForPrice } = useScheduleSlotRateRecreateForPrice();

    const { getFilterOption } = useStore('filterOptions');
    const courtTypeFilterOptions = getFilterOption('courtType');
    const coachCategoryFilterOptions = getFilterOption('coachCategory');
    const groupViewFilterOptions = getFilterOption('groupView');

    const [groupHeaderData, setGroupHeaderData] = useState<GridColumnGroup[]>(GROUP_HEADER_DATA);
    const [headerData, setHeaderData] = useState<CustomHeaderData[]>(PRICE_HEADER_DATA);

    const prepareHeaderData = (data: GridColumns): GridColumns => {
        let preparedHeaderData = [...data];

        preparedHeaderData = activePriceType?.isGroupAvailable ? preparedHeaderData : preparedHeaderData.filter((item: GridColDef) => item.field !== 'groupView');
        preparedHeaderData = activePriceType?.isCoachAvailable ? preparedHeaderData : preparedHeaderData.filter((item: GridColDef) => item.field !== 'coachCategory');

        return preparePriceHeaderData(preparedHeaderData, courtTypeFilterOptions, coachCategoryFilterOptions, groupViewFilterOptions);
    };

    const changeTab = (_: SyntheticEvent, newTab: string) => {
        handleTabChange(newTab);
    };

    const handleRefresh = (priceIds: string[]) => {
        recreateScheduleSlotRateForPrice(priceSeasonId, priceIds).then();
    };

    useEffect(() => {
        if (tab) {
            return;
        }
        handleTabChange(priceTypes[0]?.id ?? '');
    }, [priceTypes]);

    useEffect(() => {
        const priceType = priceTypes.find((item) => item.id === tab);

        if (priceType) {
            setActivePriceType(priceType);
        }
    }, [tab, priceTypes]);

    useEffect(() => {
        if (tab === '' || priceSeasonId === '') {
            return;
        }

        fetchSalesRounds(tab).then();
    }, [sortModel, filterModel, page, pageSize, tab, refetchTrigger, priceSeasonId]);

    useEffect(() => {
        setGroupHeaderData([...GROUP_HEADER_DATA]);
        setHeaderData([...PRICE_HEADER_DATA]);

        if (!priceSalesRounds.length) {
            return;
        }

        priceSalesRounds.forEach((priceSalesRound, index) => {
            updateGroupHeaderData(priceSalesRound, index, setGroupHeaderData);
            updateHeaderData(priceSalesRound, index, setHeaderData);
        });
    }, [priceSalesRounds]);

    return {
        columnVisibilityModel,
        exportData,
        count,
        priceTypes,
        handleRefresh,
        deleteMultiplePrice,
        openFilPriceModal,
        openCreatePriceModal,
        setColumnVisibilityModel,
        changeTab,
        groupHeaderData,
        headerData: prepareHeaderData(headerData),
    };
};

export default useTabContext;
