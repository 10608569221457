import { action, makeObservable, observable } from 'mobx';
import { AcquiringSettingT, EMPTY_ACQUIRING_SETTING_RESULT } from 'types/AcquiringSetting';

import BaseModalStore from './BaseModalStore';

export default class UpdateAcquiringSettingModal extends BaseModalStore {
    public acquiringSetting: AcquiringSettingT = EMPTY_ACQUIRING_SETTING_RESULT;

    public setAcquiringSetting(acquiringSetting: AcquiringSettingT) {
        this.acquiringSetting = acquiringSetting;
    }

    constructor() {
        super();

        makeObservable(this, {
            acquiringSetting: observable,
            setAcquiringSetting: action.bound,
        });
    }
}
