import { useMutation } from '@apollo/client';
import { PaymentDocumentFormFields } from 'page/PaymentDocument/components/formData';
import {
    PAYMENT_DOCUMENT_UPDATE,
    PaymentDocumentUpdateResponseDataT,
    PaymentDocumentUpdateVarT,
} from 'shared/graphql/mutation/paymentDocument/PaymentDocumentUpdate';

import { PaymentDocumentUpdateDataT } from './PaymentDocumentUpdateData';

const usePaymentDocumentUpdate = (): PaymentDocumentUpdateDataT => {
    const [paymentDocumentUpdate, { loading, error }] = useMutation<PaymentDocumentUpdateResponseDataT, PaymentDocumentUpdateVarT>(
        PAYMENT_DOCUMENT_UPDATE,
    );

    const updateAction = async (input: PaymentDocumentFormFields, paymentDocumentId: string) => {
        if (!paymentDocumentId) {
            throw new Error('Payment Document is not found');
        }

        try {
            const { data } = await paymentDocumentUpdate({
                variables: {
                    paymentDocumentPayload: {
                        id: paymentDocumentId,
                        number: input.number,
                        datetime: input.datetime,
                        organization_id: input.organization?.id,
                        comment: input.comment || null,
                        is_archive: input.isArchive,
                        payments: {
                            data: (input.payments ?? []).map((payment) => ({
                                id: payment.id,
                                datetime: payment.datetime || new Date(),
                                total: payment.total,
                                comment: payment.comment || null,
                                payment_in_fiat: payment.paymentInFiat || null,
                                payment_in_bonus: payment.paymentInBonus || null,
                                payment_method_id: payment.paymentMethod?.id || null,
                                payment_type_id: payment.paymentType?.id || null,
                            })),
                            on_conflict: {
                                constraint: 'payment_pk',
                                update_columns: [
                                    'comment',
                                    'datetime',
                                    'payment_in_bonus',
                                    'payment_in_fiat',
                                    'payment_method_id',
                                    'payment_type_id',
                                ],
                            },
                        },
                    },
                    onConflict: {
                        constraint: 'payments_document_pk',
                        update_columns: ['comment', 'is_archive'],
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw errorUpdate;
        }
    };

    return {
        paymentDocumentUpdate: updateAction,
        loading,
        error,
    };
};

export default usePaymentDocumentUpdate;
