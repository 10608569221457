import * as React from 'react';
import { useCallback } from 'react';
import { DataGridPremiumProps, GridColumns } from '@mui/x-data-grid-premium';
import { NestedTablePricesT } from 'types/NestedTablePrices';

import { DetailPanelContent } from './DetailPanelContent';

export const getDetailPanelContent = (nestedTableProps?: NestedTablePricesT, detailPanelColumns: GridColumns = []) => useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
>(({ row }) => {
    return <DetailPanelContent row={ row } columns={ detailPanelColumns } nestedTableProps={ nestedTableProps } />;
}, [detailPanelColumns]);
